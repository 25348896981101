import { Cookies } from "react-cookie";
import { REFRESH_TOKEN_API } from "../const";


const isTokenExpired = (token: string) => {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return (Date.now() >= expiry * 1000);
};


const cookies = new Cookies();

const tokenRefresh = async (refreshToken: string) => {
    try {
        const response = await fetch(`${REFRESH_TOKEN_API}?refresh_token=${refreshToken}`, {
            method: 'POST',
        });

        if (!response.ok) {
            throw new Error('Failed to refresh token');
        }

        const {token, refresh_token} = await response.json();
        cookies.set("token", token, {path: "/"});
        cookies.set("refresh_token", refresh_token, {path: "/"});
        return token;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};

export const fetchWithAuth = async (input: RequestInfo | URL, init?: RequestInit) => {
    // @ts-ignore
    let token = init?.headers?.authorization ?? cookies.get("token");
    if (isTokenExpired(token)) {
        const refreshToken = cookies.get("refresh_token");
        token = await tokenRefresh(refreshToken);
        // @ts-ignore
        init.headers.authorization = "Bearer " + token;
    }

    return await fetch(input, init);
};
