import { CloseBtn } from "components/common";
import s from "./UploadNotification.module.scss";
import cn from "classnames";

type Props = {
  title: string;
  description: string;
  onClose: () => void;
};

export const SuccessNotification = ({ title, description, onClose }: Props) => {
  return (
    <div className={cn(s.container, s.done)}>
      <div className={s.content}>
        <>
          <span className={s.title}>{title}</span>

          <p className={s.description}>{description}</p>
        </>
      </div>
      <CloseBtn onClose={onClose} />
    </div>
  );
};
