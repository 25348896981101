import { FilterType, FormatType, IColumnData, IFilters } from "interfaces";
import {
  formatDate,
  formatList,
  formatPercent,
  formatUSD,
  formatUSDFieldsInfo,
  formatVariance,
} from "./common";

export const formatData = (formatType: FormatType, value: any) => {
  switch (formatType) {
    case FormatType.amount:
      return formatUSDFieldsInfo(value);
    case FormatType.date:
      return formatDate(value);
    case FormatType.percent:
      return formatPercent(value);
    case FormatType.percentNotDecimal:
      return formatPercent(value, false);
    case FormatType.variance:
      return formatVariance(value || 0);
    default:
      return value;
  }
};

export const formatRowData = (row: any, columns: IColumnData[]) => {
  const formattedArr = columns.map(({ name, formatType, notEmpty }) => {
    switch (formatType) {
      case FormatType.amount:
        return [name, formatUSD(row[name], !notEmpty)];
      case FormatType.date:
        return [name, formatDate(row[name])];
      case FormatType.percent:
        return [name, formatPercent(row[name])];
      case FormatType.percentNotDecimal:
        return [name, formatPercent(row[name], false)];
      case FormatType.variance:
        return [name, formatVariance(row[name])];
      case FormatType.list:
        return [name, formatList(row[name])];
      default:
        return [name, row[name]];
    }
  });
  // @ts-ignore
  return Object.fromEntries(formattedArr);
};

export const generateDataByMonthsAndPools = (objs: any) => {
  const objMonths = objs?.reduce((acc: any, obj: any) => {
    const yearMonth = obj.valuation_date.slice(0, 7).replace("-", "");
    const poolId = obj.pool_id;
    const key = `${yearMonth}${poolId}`;

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
  const arrSorted = Object.entries(objMonths).sort(
    ([keyA], [keyB]) => parseInt(keyB, 10) - parseInt(keyA, 10),
  );

  return arrSorted as [string, { [key: string]: any }[]][];
};

export const getYearMonthPoolId = (year: number, month: number, poolId: number) => {
  const monthString = month.toString().length === 2 ? month : `0${month}`;
  return +`${year}${monthString}${poolId}`;
};

export const getInitialStateFilters = (columnsList: IColumnData[]) =>
  Object.fromEntries(
    columnsList
      .map(({ filterType, name }) => {
        switch (filterType) {
          case FilterType.select:
            return [name, { filterType, notEmpty: false, equal: "" }];
          case FilterType.date:
            return [
              name,
              { filterType, notEmpty: false, fromDate: null, toDate: null },
            ];
          case FilterType.amount:
          case FilterType.checkboxList:
            return [
              name,
              {
                filterType,
                notEmpty: false,
                checkboxList: [],
              },
            ];
          case FilterType.percent:
          case FilterType.percentNotDecimal:
            return [name, { filterType, notEmpty: false, from: "%", to: "%" }];
          default:
            return [];
        }
      })
      .filter((item) => item[0]),
  );

export const getNotEmptyFilters = (filters: IFilters) => {
  return Object.entries(filters).filter((item: any) => item[1].notEmpty);
};
