import cn from "classnames";
import s from "./PageContent.module.scss";
import { Header } from "../Header/Header";
import { useAppSelector } from "hooks/reduxHooks";

type Props = {
  title?: string;
  path?: string;
  search?: boolean;
  history?: boolean;
  secondScreenMode?: boolean;
  onGoBack?: () => void;
  children: JSX.Element;
};

export const PageContent = ({
  title,
  path = "",
  search = false,
  history = true,
  secondScreenMode,
  onGoBack,
  children,
}: Props) => {
  const { user } = useAppSelector((state) => state.user);

  const historyPermission = user?.access.find(
    ({ folder_name }) => folder_name === "history"
  )?.access;

  return (
    <>
      {title && (
        <Header
          title={title}
          path={path}
          search={search}
          history={history && Boolean(historyPermission)}
          secondScreenMode={secondScreenMode}
          onGoBack={onGoBack}
        />
      )}
      <div className={cn(s.container, "container")}>{children}</div>
    </>
  );
};
