import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_ACTUAL_RETURNS_REPORT_LIST } from "const";
import { encodeDataToUrl } from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const getList = createAsyncThunk(
  "kwm/actualReturnsReportList",
  async (
    _payload: {
      date_from: string;
      date_to: string;
      point_servicing_fees: string;
      point_disposition_fees: string;
      splitero_servicing_fees: string;
      splitero_disposition_fees: string;
      tilden_servicing_fees: string;
      tilden_disposition_fees: string;
    },
    thunkApi
  ) => {
    try {
      const cookies = new Cookies();
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(
        `${GET_ACTUAL_RETURNS_REPORT_LIST}?${queryParams}`,
        {
          method: "get",
          headers: {
            authorization: "Bearer " + cookies.get("token"),
          },
        }
      );
      const parsed = await response.json();
      const { contracts } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(contracts);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getList };
