import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { PermissionsSettings } from "components/modules/PermissionsSettings/PermissionsSettings";
import { TableControlsWithActionColumn } from "components/modules/TableControls/TableControlsWithActionColumn";
import { permissionsColumns } from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { ActionsColumn, Roles } from "interfaces";
import { useEffect, useState } from "react";
import { getUsers } from "store/actions/ManageUsersActions";
import { getPermissions } from "store/actions/PermissionsActions";

type Props = {};
export const Permissions = (props: Props) => {
  const { users } = useAppSelector((state) => state.manageUsers);

  const [currentUser, setCurrentUser] = useState("");

  const list = users
    ?.filter(({ role }) => role !== Roles.SuperAdmin)
    .map((user) => ({
      ...user,
      name: `${user.first_name} ${user.last_name}`,
    }));

  const usersOptions = list?.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));

  const { permissions, isLoading } = useAppSelector(
    (state) => state.permissions
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (currentUser) dispatch(getPermissions(currentUser));
  }, [currentUser]);

  const onBackTableMode = () => setCurrentUser("");

  const handleSetPermissions = (id: string) => {
    setCurrentUser(id);
  };

  return (
    <PageContent
      path={currentUser ? "Permissions" : ""}
      title={currentUser ? "Set Permissions" : "Permissions"}
      history={false}
      search={!Boolean(currentUser)}
      secondScreenMode={Boolean(currentUser)}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout
        tableMode={!Boolean(currentUser)}
        leftOnly
        widthLeft={100}
      >
        <>
          {currentUser ? (
            <PermissionsSettings
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              usersOptions={usersOptions || []}
              permissions={permissions}
              isLoading={isLoading}
            />
          ) : (
            <TableControlsWithActionColumn
              columnsList={permissionsColumns}
              list={list || []}
              searchColumns={["name"]}
              actionsColumn={[ActionsColumn.setPermissions]}
              idRowActionColumn="id"
              onSetPermissions={handleSetPermissions}
              isLoading={isLoading}
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
