import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { ACTIONS_HISTORY } from "const";
import { encodeDataToUrl } from "utils";
import moment from "moment";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const cookies = new Cookies();

type TPayload = {
  date_from?: string;
  date_to?: string;
  user_ids?: { id: string }[] | null;
  actions?: string[] | null;
  page?: string;
  size?: string;
  search?: string;
  filters: any;
};

const getHistory = createAsyncThunk(
  "kwm/history",
  async (_payload: TPayload, thunkApi) => {
    try {
      const { size, page, search, filters } = _payload;

      const obj = {
        page,
        search,
        date_from: filters?.date?.fromDate
          ? moment(filters?.date?.fromDate).format("YYYY-MM-DD")
          : null,
        date_to: filters?.date?.toDate
          ? moment(filters?.date?.toDate).format("YYYY-MM-DD")
          : null,
        user_ids: filters?.user?.checkboxList,
        actions: filters?.action_type?.checkboxList,
        size,
      };

      const params = encodeDataToUrl(obj);

      const response = await fetchWithAuth(`${ACTIONS_HISTORY}?${params}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();

      return thunkApi.fulfillWithValue({ ...parsed });
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getHistory };
