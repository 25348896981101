import { Button, Icon, ModalAside, Table } from "components/common";
import {
  ActionsColumn, FormatType,
  HistoryActions,
  IColumnData,
  IFilters,
  Sorting,
} from "interfaces";
import { useState, useMemo, useContext, useRef } from "react";
import { ControlPanel } from "../ControlPanel/ControlPanel";
import s from "./TableControls.module.scss";
import { useFilter } from "hooks/useFilter";
import { SearchContext } from "context/SearchContext";
import { formatRowData, getInitialStateFilters } from "utils";
import { useGetListInfinity } from "hooks/useGetListInfinity";
import { ActionCreatorWithoutPayload, AsyncThunk } from "@reduxjs/toolkit";
import { TableFiltersFromRequest } from "../TableFilters/TableFiltersFromRequest";
import {TableCellExpandableListRenderer, TableCellExpandableMultiListRenderer} from "./TableCellExpandableListRenderer";

type Props = {
  columnsList: IColumnData[];
  list: any[];
  total: number;
  getListAction: AsyncThunk<any, any, any>;
  clearListAction: ActionCreatorWithoutPayload<any>;
  defaultSort?: Sorting;
  defaultSortedColumn?: string;
  defaultSorted?: boolean;
  withFilters?: boolean;
  searchColumns?: string[];
  actionsColumn: ActionsColumn[];
  idRowActionColumn: string;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  onDownload?: (id: string) => void;
  tableAction?: JSX.Element;
  isLoading?: boolean;
};

export const TableControlsWithLazyLoading = ({
  columnsList,
  list,
  total,
  getListAction,
  clearListAction,
  defaultSort = Sorting.asc,
  defaultSortedColumn,
  defaultSorted,
  withFilters,
  searchColumns,
  actionsColumn,
  idRowActionColumn,
  onEdit,
  onDelete,
  onDownload,
  tableAction,
  isLoading,
}: Props) => {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const ref = { listContainerRef, listRef };

  const { search } = useContext(SearchContext);

  const [filters, setFilters] = useState<IFilters>(
    getInitialStateFilters(columnsList)
  );

  useGetListInfinity({
    total,
    getListAction,
    clearListAction,
    listContainer: listContainerRef?.current,
    listRef,
    search,
    filters,
    tableHeaderHeight: 46,
  });

  const [dataIsFiltered, setDataIsFiltered] = useState(false);
  const [filterIsChanged, setFilterIsChanged] = useState(false);

  const { isOpenFilter, toggleFilter, isStartFilter, setStartFilter } =
    useFilter();

  const actions = [
    {
      action: ActionsColumn.edit,
      label: "Edit",
      handleClick: onEdit ? (id: string) => onEdit(id) : () => {},
      icon: "edit",
    },
    {
      action: ActionsColumn.delete,
      label: "Delete",
      handleClick: onDelete ? (id: string) => onDelete(id) : () => {},
      icon: "trash",
    },
    {
      action: ActionsColumn.download,
      label: "Download",
      handleClick: onDownload ? (id: string) => onDownload(id) : () => {},
      icon: "download",
    },
  ];

  const components = {
    [FormatType.list]: (list: string[]) => <TableCellExpandableListRenderer list={list}/>,
    [FormatType.multiList]: (multiList: string[][]) => <TableCellExpandableMultiListRenderer multiList={multiList}/>,
  };

  const columns = useMemo(() => {
    const dataColumns = columnsList.map(
      ({ name, label, label2, sortType, className }) => ({
        Header: (
          <div className={s.headCellWithSort}>
            <div>
              <span className={s.headCellLabel}>{label}</span>
              <span className={s.headCellLabel}>{label2}</span>
            </div>

            {/* {sortType && (
              <SortBtn
                order={sortingOrder[name] || Sorting.asc}
                onClick={() => sortColumn(sortedData, name, sortType)}
                isActive={activeSorting === name}
              />
            )} */}
          </div>
        ),
        accessor: name,
        className,
      })
    );

    return [
      ...dataColumns,
      {
        Header: "Action",
        accessor: "action",
      },
    ];
    // }, [sortingOrder, sortedData]);
  }, []);

  const onFilter = () => {
    setStartFilter(true);
    setDataIsFiltered(true);
  };

  const renderAction = (icon: string, text: string, onClick: () => void) => (
    <span key={icon} onClick={onClick} className={s.actionInCell}>
      <Icon icon={icon} />
      <span>{text}</span>
    </span>
  );

  const renderTableData = () => {
    return list.map((row) => {
      const rowData = formatRowData(row, columnsList);
      const shouldRender = row?.action_type
        ? row.action_type === HistoryActions.download &&
          row.status === "Successful" &&
          row.fileId
        : row[idRowActionColumn];

      // @ts-ignore
      return {
        ...rowData,
        ...Object.fromEntries(columnsList
            .filter(column => column.formatType == FormatType.list)
            .map(column => {
              return [column.name, Array.isArray(rowData[column.name])
              && rowData[column.name].every((list: any) => Array.isArray(list))
                  ? components[FormatType.multiList](rowData[column.name])
                  : Array.isArray(rowData[column.name])
                      ? components[FormatType.list](rowData[column.name])
                      : rowData[column.name]];
            })),
        action: (
          <div className={s.actions}>
            {actions
              .filter((item) => actionsColumn.includes(item.action))
              .map(
                ({ icon, label, handleClick }) =>
                  shouldRender &&
                  renderAction(icon, label, () =>
                    handleClick(row[idRowActionColumn])
                  )
              )}
          </div>
        ),
      };
    });
  };

  return (
    <>
      <ControlPanel
        onOpenFilter={withFilters ? () => toggleFilter(true) : undefined}
        dataIsFiltered={dataIsFiltered}
        tableAction={tableAction}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        data={renderTableData()}
        //@ts-ignore
        ref={ref}
      />
      {withFilters && (
        <ModalAside
          isOpen={isOpenFilter}
          onClose={() => toggleFilter(false)}
          title="Filter"
          bottomBtn={
            <Button
              title="Filter"
              onClick={onFilter}
              wide="long"
              disabled={!filterIsChanged}
              uppercase
            />
          }
        >
          <TableFiltersFromRequest
            filters={filters}
            setFilters={setFilters}
            columnsList={columnsList}
            isStartFilter={isStartFilter}
            setStartFilter={setStartFilter}
            setDataIsFiltered={setDataIsFiltered}
            filterIsChanged={filterIsChanged}
            setFilterIsChanged={setFilterIsChanged}
          />
        </ModalAside>
      )}
    </>
  );
};
