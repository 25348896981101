import { Button, ModalAside, SortBtn, Table } from "components/common";
import { TableFilters } from "components/modules";
import { useSorting } from "hooks/useSorting";
import { IColumnData, Sorting } from "interfaces";
import { useState, useMemo, useEffect, useContext } from "react";
import { ControlPanel } from "../ControlPanel/ControlPanel";
import s from "./TableControls.module.scss";
import { useFilter } from "hooks/useFilter";
import { SearchContext } from "context/SearchContext";
import { formatRowData } from "utils";
import cn from "classnames";

type Props = {
  columnsList: IColumnData[];
  list: any[];
  isLoading?: boolean;
  defaultSort?: Sorting;
  defaultSortedColumn?: string;
  onDownloadFile?: () => void;
  formInPanel?: JSX.Element;
  onOpenAssetList?: () => void;
  manageColumns?: boolean;
  withFilters?: boolean;
  searchColumns?: string[];
  shortFilterBtn?: boolean;
  dynamicPinFirstCol?: boolean;
};

export const TableControls = ({
  columnsList,
  list,
  isLoading,
  defaultSort = Sorting.asc,
  defaultSortedColumn,
  onDownloadFile,
  formInPanel,
  onOpenAssetList,
  manageColumns,
  withFilters,
  searchColumns,
  shortFilterBtn = false,
  dynamicPinFirstCol,
}: Props) => {
  const [sortedData, setSortedData] = useState(list);
  const [filteredData, setFilteredData] = useState(sortedData);
  const [searchedData, setSearchedData] = useState(filteredData);

  const [dataIsFiltered, setDataIsFiltered] = useState(false);
  const [filterIsChanged, setFilterIsChanged] = useState(false);
  const [firstColPinned, toggleFirstColPinned] = useState(true);

  const { search } = useContext(SearchContext);

  const { sortColumn, sortingOrder, activeSorting, setActiveSorting } =
    useSorting(columnsList, setSortedData, defaultSort, defaultSortedColumn);

  const { isOpenFilter, toggleFilter, isStartFilter, setStartFilter } =
    useFilter();

  const renderHead = (
    { name, label, label2, sortType }: IColumnData,
    withPin = false
  ) => {
    return (
      <div
        className={cn(
          s.headCellWithSort,
          withPin && s.headCellWithPin,
          firstColPinned && s.pinned
        )}
      >
        {withPin && (
          <Button
            filled="invisible"
            icon="pin"
            onClick={() => toggleFirstColPinned((pinned) => !pinned)}
            className={s.pinBtn}
          />
        )}
        <div className={withPin ? s.withPin : ""}>
          <span className={s.headCellLabel}>{label}</span>
          <span className={s.headCellLabel}>{label2}</span>
        </div>

        {sortType && (
          <SortBtn
            order={sortingOrder[name] || Sorting.asc}
            onClick={() => sortColumn(sortedData, name, sortType)}
            isActive={activeSorting === name}
          />
        )}
      </div>
    );
  };

  const columns = useMemo(() => {
    if (dynamicPinFirstCol) {
      return columnsList.map(({ name, ...rest }, idx) => {
        if (idx) {
          return {
            Header: renderHead({ name, ...rest }),
            accessor: name,
          };
        } else {
          return {
            Header: renderHead({ name, ...rest }, true),
            accessor: name,
          };
        }
      });
    } else {
      return columnsList.map(({ name, ...rest }, idx) => ({
        Header: renderHead({ name, ...rest }),
        accessor: name,
      }));
    }
  }, [sortingOrder, sortedData, firstColPinned]);

  useEffect(() => {
    setSortedData(list);

    list.length && setActiveSorting(defaultSortedColumn || "");
  }, [list]);

  useEffect(() => {
    setFilteredData(sortedData);
  }, [sortedData]);

  useEffect(() => {
    if (search.length) {
      const newData = filteredData.filter((row) => {
        return searchColumns?.find((item) =>
          row[item].toString().toLowerCase().includes(search.toLowerCase())
        );
      });
      setSearchedData(newData);
    } else setSearchedData(filteredData);
  }, [search, filteredData]);

  const onFilter = () => {
    setStartFilter(true);
    setDataIsFiltered(true);
  };

  const renderTableData = () => {
    return searchedData.map((row) => {
      return formatRowData(row, columnsList);
    });
  };

  return (
    <>
      <ControlPanel
        onDownloadFile={onDownloadFile}
        formInPanel={formInPanel}
        onOpenAssetList={onOpenAssetList}
        onOpenFilter={withFilters ? () => toggleFilter(true) : undefined}
        shortFilterBtn={shortFilterBtn}
        dataIsFiltered={dataIsFiltered}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        isLoading={isLoading}
        data={renderTableData()}
        fixedFirstCol={firstColPinned}
      />
      {withFilters && (
        <ModalAside
          isOpen={isOpenFilter}
          onClose={() => toggleFilter(false)}
          title="Filter"
          bottomBtn={
            <Button
              title="Filter"
              onClick={onFilter}
              wide="long"
              disabled={!filterIsChanged}
              uppercase
            />
          }
        >
          <TableFilters
            columnsList={columnsList}
            sortedData={sortedData}
            setFilteredData={setFilteredData}
            isStartFilter={isStartFilter}
            setStartFilter={setStartFilter}
            setDataIsFiltered={setDataIsFiltered}
            setFilterIsChanged={setFilterIsChanged}
          />
        </ModalAside>
      )}
    </>
  );
};
