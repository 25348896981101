import { Input } from "components/common";
import { ChangeEvent } from "react";
import s from "./TableFilters.module.scss";
import {
  getValueChangeInputPercentage,
  getValueKeyDownInputPercentage,
} from "utils";
import { IPropsFilter } from "./TableFilters";

export const PercentFilter = ({
  name,
  label,
  filters,
  setFilters,
}: IPropsFilter) => {
  const setValue = (field: "from" | "to", value: string) => {
    setFilters({
      ...filters,
      [name]: {
        ...filters[name],
        notEmpty:
          Boolean(value) ||
          Boolean(
            field === "from"
              ? filters[name].to?.slice(0, -1)
              : filters[name].from?.slice(0, -1)
          ),
        [field]: value,
      },
    });
  };

  const handlePercentChange = (field: "from" | "to", value: string) => {
    const newValue = getValueChangeInputPercentage(value);
    filters[name][field] !== newValue && setValue(field, newValue);
  };

  const handlePercentKeyDown = (
    field: "from" | "to",
    value: string,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const newValue = getValueKeyDownInputPercentage(value, event);

    newValue && filters[name][field] !== newValue && setValue(field, newValue);
  };

  return (
    <div className={s.item}>
      <span className={s.label}>{label}</span>
      <div className={s.itemsRow}>
        <div className={s.subItem}>
          <span className={s.labelSmall}>{"From"}</span>
          <Input
            value={filters[name]?.from}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handlePercentChange("from", e.target.value)
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handlePercentKeyDown("from", filters[name]?.from || "", e);
            }}
          />
        </div>
        <div className={s.subItem}>
          <span className={s.labelSmall}>{"To"}</span>
          <Input
            value={filters[name]?.to}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handlePercentChange("to", e.target.value)
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handlePercentKeyDown("to", filters[name]?.to || "", e);
            }}
          />
        </div>
      </div>
    </div>
  );
};
