import { Button } from "components/common/Button/Button";
import s from "./Header.module.scss";
import cn from "classnames";
import { TableSearch } from "components/modules/TableSearch/TableSearch";
import { ModalAside } from "components/common";
import { useState } from "react";
import { HistoryAside } from "components/pages/History/HistoryAside";

type Props = {
  title: string;
  path: string;
  search: boolean;
  secondScreenMode?: boolean;
  onGoBack?: () => void;
  history: boolean;
};

export const Header = ({
  title,
  path,
  search,
  history,
  secondScreenMode,
  onGoBack,
}: Props) => {
  const [isOpenHistory, toggleHistory] = useState(false);

  return (
    <header className={cn(s.container, "container")}>
      <div className={s.heading}>
        {secondScreenMode && onGoBack && (
          <Button
            size="xs"
            icon="arrow-left"
            filled="invisible"
            onClick={onGoBack}
            className={s.backBtn}
          />
        )}
        {path && <span className={s.path}>{path}</span>}
        <h1 className="h2">{title}</h1>
      </div>
      {search && <TableSearch />}
      {history && (
        <Button
          icon="clock"
          size="small"
          title="History"
          onClick={() => toggleHistory((isOpenHistory) => !isOpenHistory)}
          uppercase
        />
      )}
      {history && (
        <ModalAside
          isOpen={isOpenHistory}
          onClose={() => toggleHistory(false)}
          title="History"
        >
          {isOpenHistory ? <HistoryAside /> : <></>}
        </ModalAside>
      )}
    </header>
  );
};
