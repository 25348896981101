import { createSlice } from "@reduxjs/toolkit";
import { PledgeCollateralType } from "store/types/PledgeCollateral";
import {
  cancelPledgeList,
  createPledgeCreditLine,
  getPledgeDebt,
  getPledgeList,
} from "../actions/PledgeCollateralActions";

const initialState = {
  isLoading: false,
  error: null,
  pledge_debt: [],
  pledge_list: [],
  pledge_list_investment_amount: 0,
  pledge_list_investment_count: 0,
  pledge_debt_investment_amount: 0,
  pledge_debt_investment_count: 0,
} as PledgeCollateralType;

export const PledgeCollateralSlice = createSlice({
  name: "pledgeCollateral",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: {
    [getPledgeList.fulfilled.type]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.pledge_list = action.payload.contracts;
      state.pledge_list_investment_amount = action.payload.investment_amount;
      state.pledge_list_investment_count = action.payload.investment_count;
    },
    [getPledgeList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPledgeList.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [cancelPledgeList.fulfilled.type]: (state, action) => {
      state.error = null;
      state.isLoading = false;
    },
    [cancelPledgeList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [cancelPledgeList.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [getPledgeDebt.fulfilled.type]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.pledge_debt = action.payload.contracts;
      state.pledge_debt_investment_amount = action.payload.investment_amount;
      state.pledge_debt_investment_count = action.payload.investment_count;
    },
    [getPledgeDebt.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPledgeDebt.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [createPledgeCreditLine.fulfilled.type]: (state, action) => {
      state.error = null;
      state.isLoading = false;
    },
    [createPledgeCreditLine.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createPledgeCreditLine.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError } = PledgeCollateralSlice.actions;

export default PledgeCollateralSlice.reducer;
