import { Button } from "components/common";
import s from "./History.module.scss";
import { useNavigate } from "react-router-dom";
import { HistoryRoute } from "const";
import { useContext, useEffect, useRef, useState } from "react";
import { FilterType, HistoryAsideTabs } from "interfaces";
import cn from "classnames";
import { useAppSelector } from "hooks/reduxHooks";
import { useGetListInfinity } from "hooks/useGetListInfinity";
import { getHistory } from "store/actions/HistoryActions";
import { clearHistory } from "store/reducers/HistoryActionsSlice";
import { getHistoryList } from "utils";
import { HistoryAsideItem } from "./HistoryAsideItem";
import { UpdateListContext } from "context/UpdateListContext";

export const HistoryAside = () => {
  const { user } = useAppSelector((state) => state.user);
  const { items, total } = useAppSelector((state) => state.actionsHistory);

  const listContainerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(HistoryAsideTabs.my);

  const { setUpdateList } = useContext(UpdateListContext);

  const filters = {
    user: {
      filterType: FilterType.checkboxList,
      notEmpty: activeTab === HistoryAsideTabs.my,
      checkboxList: user?.id ? [user?.id.toString()] : [],
    },
  };

  useEffect(() => {
    setUpdateList(true);
  }, [activeTab]);

  useGetListInfinity({
    total,
    getListAction: getHistory,
    clearListAction: clearHistory,
    listContainer: listContainerRef?.current,
    listRef,
    filters,
    tableHeaderHeight: 50,
  });

  return (
    <>
      <div className={s.header}>
        <div className={s.tabs}>
          <button
            className={cn(s.tab, activeTab === HistoryAsideTabs.my && s.active)}
            onClick={() => setActiveTab(HistoryAsideTabs.my)}
          >
            {HistoryAsideTabs.my}
          </button>
          <button
            className={cn(
              s.tab,
              activeTab === HistoryAsideTabs.all && s.active
            )}
            onClick={() => setActiveTab(HistoryAsideTabs.all)}
          >
            {HistoryAsideTabs.all}
          </button>
        </div>
        <Button
          title="View All"
          size="xs"
          filled="grayDark"
          onClick={() => navigate(`/${HistoryRoute}`)}
          icon="right"
        />
      </div>
      <div className={s.itemsContainer} ref={listContainerRef}>
        <div className={s.itemsList} ref={listRef}>
          {getHistoryList(items, true)
            .filter(({ file }) => file)
            .map((props) => (
              <HistoryAsideItem
                key={props.id}
                myHistory={activeTab === HistoryAsideTabs.my}
                {...props}
              />
            ))}
        </div>
      </div>
    </>
  );
};
