import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControls } from "components/modules";
import {
  AREA_OPTIONS, STATE_OPTIONS, DOWNLOAD_HPA_METRO_REPORT, ALL_YEARS_TO_OPTIONS, columnsHPAMetro,
} from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { usePrevious } from "hooks/usePrevious";
import { useEffect, useState } from "react";
import { getList } from "store/actions/HPAMetroActions";
import {
  downloadResourceFromURL,
  encodeDataToUrl,
  getYearsOptions,
} from "utils";
import { BasePaginator } from "../../modules/Paginator";

type Values = {
  area: string;
  state: string;
  yearFrom: string;
  yearTo: string;
  pageNumber: number;
  pageSize: number;
};

export const HPAMetro = () => {

  const defaultYearFrom = "all";
  const defaultYearTo = "all";
  const defaultPageSize = 100

  const { valuesSelect, handleSelect, updateInitialSelect } = useFormSelectArr({
    area: "metro",
    state: "all",
    yearFrom: defaultYearFrom,
    yearTo: defaultYearTo,
    page: 0,
    page_size: defaultPageSize
  });

  const { area, state, yearFrom, yearTo } =
    valuesSelect;

  const [tableMode, setTableMode] = useState(false);

  // @ts-ignore
  const { list: {items, total}, isLoading, error } = useAppSelector((state) => state.hpaMetro);

  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedPageSize, setSelectedPageSize] = useState(defaultPageSize);

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
    }
  }, [isLoading]);

  const fieldsInForm = {
    select1: {
      fieldName: "Area",
      options: AREA_OPTIONS,
      onSelect: (value: string) => handleSelect("area", value),
      value: area,
    },
    select2: {
      fieldName: "State",
      options: STATE_OPTIONS,
      onSelect: (value: string) => handleSelect("state", value),
      value: state,
    },
    select5: {
      fieldName: "Choose Date",
      options: [...ALL_YEARS_TO_OPTIONS, ...getYearsOptions(1975)],
      onSelect: (value: string) => handleSelect("yearFrom", value),
      value: yearFrom,
      label: "Year From",
    },
    select6: {
      options: [...ALL_YEARS_TO_OPTIONS, ...getYearsOptions(1975)],
      onSelect: (value: string) => handleSelect("yearTo", value),
      value: yearTo,
      label: "Year To",
    },
  };

  const fieldsinPanel = {
    select1: {
      fieldName: "Area",
      options: AREA_OPTIONS,
      onSelect: (value: string) => onSelect("area", value),
      value: area,
      oneInRow: true,
    },
    select2: {
      fieldName: "State",
      options: STATE_OPTIONS,
      onSelect: (value: string) => onSelect("state", value),
      value: state,
    },
    select3: {
      options: [...ALL_YEARS_TO_OPTIONS, ...getYearsOptions(1975)],
      onSelect: (value: string) => onSelect("yearFrom", value),
      value: yearFrom,
      label: "Year From",
    },
    select4: {
      options: [...ALL_YEARS_TO_OPTIONS, ...getYearsOptions(1975)],
      onSelect: (value: string) => onSelect("yearTo", value),
      value: yearTo,
      label: "Year To",
    },
  };

  const getTableList = ({
    area,
    state,
    yearFrom,
    yearTo,
    pageNumber,
    pageSize
  }: Values) => {
    if (!isLoading) {
      dispatch(
        getList({
          area: area,
          state: state,
          from_year: yearFrom,
          to_year: yearTo,
          page_size: pageSize,
          page: pageNumber,
        })
      );

      updateInitialSelect();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      area: area,
      state: state,
      from_year: yearFrom,
      to_year: yearTo,
    });

    downloadResourceFromURL(
      `HPA Metro List.xlsx`,
      `${DOWNLOAD_HPA_METRO_REPORT}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    setSelectedPage(0)
    const data = {
      ...valuesSelect,
      pageNumber: 0,
      pageSize: selectedPageSize,
    };
    getTableList(data);
  };

  const onSelect = (field: string, value: string) => {
    handleSelect(field, value);
    setSelectedPage(0)
    const data = {
      ...valuesSelect,
      pageNumber: 0,
      pageSize: selectedPageSize,
      [field]: value,
    };
    getTableList({ ...data });
  };

  const onSelectPage = (pageNumber: number) => {
    setSelectedPage(pageNumber)
    const data = {
      ...valuesSelect,
      pageNumber: pageNumber,
      pageSize: selectedPageSize,
    };
    getTableList({ ...data });
  }

  const onSelectPageSize = (pageSize: number) => {
    setSelectedPageSize(pageSize)
    setSelectedPage(0)
    const data = {
      ...valuesSelect,
      pageNumber: 0,
      pageSize: pageSize,
    };
    getTableList({ ...data });
  }

  const getColumns = (area: string) => {
    return columnsHPAMetro(area == "metro");
  };

  return (
    <PageContent
      title="HPA Metro"
      path="Valuation"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fieldsInForm}
            importButton={{
              label: isLoading ? "Processing..." : "Generate",
              onclick: handleClickImportBtn,
            }}
            tableMode={tableMode}
          />
          {tableMode && (
            <TableControls
              columnsList={getColumns(area) || []}
              list={items || []}
              isLoading={isLoading}
              onDownloadFile={onDownloadFile}
              dynamicPinFirstCol
              formInPanel={
                <FormBlock
                  fields={fieldsinPanel}
                  tableMode
                  inControlPanel
                  sizeFields={"85"}
                />
              }
              searchColumns={columnsHPAMetro(area == "metro").map(col => col.name)}
              withFilters
            />
          )}
          {
            tableMode && (<BasePaginator pageNumber={selectedPage} pageSize={selectedPageSize} total={total || 0}
                                         disabled={isLoading}
                                         onSelectPage={onSelectPage} onSelectPageSize={onSelectPageSize}/>)
          }
        </>
      </PageFormLayout>
    </PageContent>
  );
};
