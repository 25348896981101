import s from "./Layout.module.scss";

import cn from "classnames";
// import { Navigate } from "react-router-dom";
// import { AuthRoute } from "const";
import { Sidebar } from "../Sidebar/Sidebar";
import { useState } from "react";
import { SearchContext } from "context/SearchContext";
import { Navigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { AuthRoute } from "const";
import { UpdateListContext } from "context/UpdateListContext";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const [isNarrow, setSidebarState] = useState(false);
  const [search, setSearch] = useState("");

  const [updateList, setUpdateList] = useState(false);

  const cookies = new Cookies();
  const isAuth = cookies.get("token");

  if (!isAuth) {
    return <Navigate to={AuthRoute.LOGIN} />;
  }

  return (
    <UpdateListContext.Provider value={{ updateList, setUpdateList }}>
      <SearchContext.Provider value={{ search, setSearch }}>
        <div className={cn(s.layout)}>
          <Sidebar
            isNarrow={isNarrow}
            toggleSidebar={() => setSidebarState(!isNarrow)}
          />
          <main className={s.main}>{children}</main>
        </div>
      </SearchContext.Provider>
    </UpdateListContext.Provider>
  );
};
