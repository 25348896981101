import { CustomDatePicker, Input } from "components/common";
import s from "./FormUpdateData.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/reduxHooks";
import { FormUpdateDataButtons } from "./FormUpdateDataButtons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  formatAmountToNumber,
  getInitialAmount,
  getValueChangeInputAmount,
} from "utils";
import moment from "moment";
import { FieldWrapper } from "components/common/FormElements/FieldWrapper";
import { UpdateContractDataType } from "store/types/UpdateContractType";
import { updateContractData } from "store/actions/UpdateContractActions";
import { resetData } from "store/reducers/UpdateContractSlice";

type Props = {
  data: UpdateContractDataType;
  isLoading: boolean;
  isUpdated: boolean;
};

interface FormValues {
  contract_id: string;
  effective_date: Date | null;
  closing_date: Date | null;
  option_amount: string;
  acquisition_fee: string;
  assignment_cost: string;
  tax_title_cost: string;
  purchase_price: string;
}

export const FormUpdateContract = ({ data, isLoading, isUpdated }: Props) => {
  const [fetchData, setFetchData] = useState<any>(null);

  const dispatch = useAppDispatch();

  const withSubmitModal = true;
  const idForm = "updateContract";

  const getPurchasePrice = () => {
    return (
      (data.option_amount || 0) +
      (data.acquisition_fee || 0) +
      (data.assignment_cost || 0) +
      (data.tax_title_cost || 0)
    );
  };

  const getInitialData = (data: UpdateContractDataType) => {
    return {
      contract_id: data.contract_id,
      effective_date: data.effective_date
        ? moment(data.effective_date).toDate()
        : null,
      closing_date: data.closing_date
        ? moment(data.closing_date).toDate()
        : null,
      option_amount: getInitialAmount(data.option_amount),
      acquisition_fee: getInitialAmount(data.acquisition_fee),
      assignment_cost: getInitialAmount(data.assignment_cost),
      tax_title_cost: getInitialAmount(data.tax_title_cost),
      purchase_price: getInitialAmount(getPurchasePrice()),
    };
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    watch,
  } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: getInitialData(data),
  });

  const watch1 = watch("option_amount");
  const watch2 = watch("acquisition_fee");
  const watch3 = watch("assignment_cost");
  const watch4 = watch("tax_title_cost");

  useEffect(() => {
    setValue(
      "purchase_price",
      getInitialAmount(
        (formatAmountToNumber(watch1) || 0) +
          (formatAmountToNumber(watch2) || 0) +
          (formatAmountToNumber(watch3) || 0) +
          (formatAmountToNumber(watch4) || 0)
      )
    );
  }, [watch1, watch2, watch3, watch4]);

  useEffect(() => {
    if (data.contract_id) reset(getInitialData(data));
  }, [data]);

  const onCancel = () => {
    dispatch(resetData());
  };

  const onChangeInputAmount = (name: keyof FormValues, value: string) => {
    const newVal = getValueChangeInputAmount(value);
    setValue(name, newVal);
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const {
      effective_date,
      closing_date,
      option_amount,
      acquisition_fee,
      assignment_cost,
      tax_title_cost,
      purchase_price,
      ...rest
    } = formData;

    const fetchData = {
      ...rest,
      effective_date: effective_date
        ? moment(effective_date).format("MM/DD/YYYY")
        : "",
      closing_date: closing_date
        ? moment(closing_date).format("MM/DD/YYYY")
        : "",
      option_amount: formatAmountToNumber(option_amount),
      acquisition_fee: formatAmountToNumber(acquisition_fee),
      assignment_cost: formatAmountToNumber(assignment_cost),
      tax_title_cost: formatAmountToNumber(tax_title_cost),
      purchase_price: formatAmountToNumber(purchase_price),
    };

    withSubmitModal
      ? setFetchData(fetchData)
      : dispatch(updateContractData(fetchData));
  };

  return (
    <form
      id={idForm}
      onSubmit={handleSubmit(onSubmit)}
      className={s.form}
      noValidate
    >
      <div className={s.item}>
        <span className={s.label}>Contract ID:</span>
        <Controller
          control={control}
          name="contract_id"
          render={({ field }) => <Input {...field} disabled />}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Effective Date:</span>
        <Controller
          control={control}
          name="effective_date"
          render={({ field }) => (
            <FieldWrapper error={errors.effective_date}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Closing Date:</span>
        <Controller
          control={control}
          name="closing_date"
          render={({ field }) => (
            <FieldWrapper error={errors.closing_date}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Option Amount:</span>
        <Controller
          control={control}
          name="option_amount"
          render={({ field }) => (
            <FieldWrapper error={errors.option_amount}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Acquisition Fee:</span>
        <Controller
          control={control}
          name="acquisition_fee"
          render={({ field }) => (
            <FieldWrapper error={errors.acquisition_fee}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Assignment Cost:</span>
        <Controller
          control={control}
          name="assignment_cost"
          render={({ field }) => (
            <FieldWrapper error={errors.assignment_cost}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Tax & Title Cost:</span>
        <Controller
          control={control}
          name="tax_title_cost"
          render={({ field }) => (
            <FieldWrapper error={errors.tax_title_cost}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Purchase Price:</span>
        <Controller
          control={control}
          name="purchase_price"
          render={({ field }) => (
            <FieldWrapper error={errors.purchase_price}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <FormUpdateDataButtons
        disabled={!isDirty}
        withSubmitModal={withSubmitModal}
        withCancelModal
        onCancel={onCancel}
        idForm={idForm}
        fetchData={fetchData}
        fetchDataAction={updateContractData}
        isUpdated={isUpdated}
        isLoading={isLoading}
      />
    </form>
  );
};
