import {
  Button,
  Checkbox,
  Modal,
  ModalAside,
  Select,
  SortBtn,
  Table,
} from "components/common";
import { PledgeInfoRow, TableFilters } from "components/modules";
import { useSorting } from "hooks/useSorting";
import { IColumnData, IOption, Sorting } from "interfaces";
import { useState, useMemo, useEffect, useContext, useRef } from "react";
import { ControlPanel } from "../ControlPanel/ControlPanel";
import s from "./TableControls.module.scss";
import { useFilter } from "hooks/useFilter";
import { SearchContext } from "context/SearchContext";
import { formatRowData } from "utils";
import { useSelectRows } from "hooks/useSelectRows";
import { usePrevious } from "hooks/usePrevious";
import { PledgeConfirmModal } from "../PledgeConfirmModal/PledgeConfirmModal";

type Props = {
  columnsList: IColumnData[];
  list: any[];
  isLoading?: boolean;
  infoRow?: boolean;
  investment_amount?: number;
  investment_count?: number;
  defaultSort?: Sorting;
  defaultSortedColumn?: string;
  onDownloadFile?: () => void;
  formInPanel?: JSX.Element;
  onOpenAssetList?: () => void;
  onFiltersRegroup?: (v: boolean) => void;
  manageColumns?: boolean;
  withFilters?: boolean;
  searchColumns?: string[];
  shortFilterBtn?: boolean;
  idRowSelector?: string;
  typeCheckbox?: "square" | "circle";
  allSelected?: boolean;
  selectedActionSelectOptions?: IOption[];
  selectedActionSelectLabel?: string;
  selectedActionSelectValue?: string;
  selectedActionSelectDisabled?: boolean;
  selectedActionSelectChange?: (val: string) => void;
  selectedActionBtnTitle?: string;
  confirmModalText?: string;
  confirmModalSubmitText?: string;
  confirmModalSubtitle?: string;
  submitSelectedDataAction?: (
    selectedRows: string[],
    dateValue: string,
  ) => void;
};

export const TableControlsWithSelectRows = ({
  columnsList,
  list,
  isLoading,
  infoRow,
  investment_amount,
  investment_count,
  defaultSort = Sorting.asc,
  defaultSortedColumn,
  onDownloadFile,
  formInPanel,
  onOpenAssetList,
  onFiltersRegroup,
  manageColumns,
  withFilters,
  searchColumns,
  shortFilterBtn = false,
  idRowSelector,
  typeCheckbox,
  allSelected,
  selectedActionSelectOptions,
  selectedActionSelectLabel,
  selectedActionSelectValue,
  selectedActionSelectDisabled,
  selectedActionSelectChange,
  selectedActionBtnTitle,
  confirmModalText,
  confirmModalSubmitText,
  confirmModalSubtitle,
  submitSelectedDataAction,
}: Props) => {

  const [sortedData, setSortedData] = useState(list);
  const [filteredData, setFilteredData] = useState(sortedData);
  const [filteredSecondData, setFilteredSecondData] = useState(filteredData);

  const [dataIsFiltered, setDataIsFiltered] = useState(false);
  const [filterIsChanged, setFilterIsChanged] = useState(false);

  const [isUpdateCheckboxes, setUpdateCheckboxes] = useState(false);

  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);

  const [dirtyDate, setDirtyDate] = useState(false);

  const { search } = useContext(SearchContext);

  const { sortColumn, sortingOrder, activeSorting, setActiveSorting } =
    useSorting(columnsList, setSortedData, defaultSort, defaultSortedColumn);

  const { isOpenFilter, toggleFilter, isStartFilter, setStartFilter } =
    useFilter();

  const dateValueRef = useRef<string | null>(null);

  const {
    selectRow,
    selectedRows,
    setSelectedRows,
    selectedAmount,
    selectedCount,
    reset,
  } = useSelectRows(
    list,
    investment_amount || 0,
    investment_count || 0,
    allSelected,
  );

  const setSelectRowRef = useRef(selectRow);
  useEffect(() => {
    setSelectRowRef.current = selectRow;
  }, [selectRow]);

  useEffect(() => {
    reset();
  }, [list]);

  const makeSearchedData = (columnsList: IColumnData[], data: any[], selectedRows: string | any[], idRowSelector: string | number | undefined) => {
    return data.map((row) => {
      const rowData = formatRowData(row, columnsList);
      return idRowSelector
          ? {
            selector: (
                <Checkbox
                    checked={selectedRows.includes(row[idRowSelector])}
                    onChange={() => setSelectRowRef.current(row[idRowSelector])}
                    type={typeCheckbox}
                />
            ),
            ...rowData,
          }
          : rowData;
    });
  }

  const [searchedData, setSearchedData] = useState(makeSearchedData(columnsList, filteredData, selectedRows, idRowSelector));

  const makeColumns = (columnsList: IColumnData[] | { name: any; label: any; label2: any; sortType: any; }[], sortingOrder: { [x: string]: any; }, sortedData: any[], selectRow: { (val: string): void; (arg0: string): void; }) => {
    const dataColumns = columnsList.map(
        ({ name, label, label2, sortType }) => ({
          Header: (
              <div className={s.headCellWithSort}>
                <div>
                  <span className={s.headCellLabel}>{label}</span>
                  <span className={s.headCellLabel}>{label2}</span>
                </div>

                {sortType && (
                    <SortBtn
                        order={sortingOrder[name] || Sorting.asc}
                        onClick={() => sortColumn(sortedData, name, sortType)}
                        isActive={activeSorting === name}
                    />
                )}
              </div>
          ),
          accessor: name,
        }),
    );

    return idRowSelector
        ? [
          {
            Header: (
                <Checkbox
                    checked={selectedRows.includes("all")}
                    onChange={() => selectRow("all")}
                    type={typeCheckbox}
                />
            ),
            accessor: "selector",
          },
          ...dataColumns,
        ]
        : dataColumns;

  }

  const [columns, setColumns] = useState(makeColumns(columnsList, sortingOrder, filteredSecondData, selectRow));

  useEffect(() => {
    setSortedData(list);

    list.length && setActiveSorting(defaultSortedColumn || "");
    allSelected && setUpdateCheckboxes(true);
  }, [list]);

  useEffect(() => {
    setFilteredData(sortedData);
  }, [sortedData]);

  useEffect(() => {
    if (search.length) {
      const newData = filteredData.filter((row) => {
        return searchColumns?.find((item) =>
          row[item].toString().toLowerCase().includes(search.toLowerCase()),
        );
      });
      setFilteredSecondData(newData);
      setSearchedData(makeSearchedData(columnsList, newData, selectedRows, idRowSelector));
    } else {
      setFilteredSecondData(filteredData);
      setSearchedData(makeSearchedData(columnsList, filteredData, selectedRows, idRowSelector));
    }
  }, [search, filteredData]);

  useEffect(() => {
    setColumns(makeColumns(columnsList, sortingOrder, filteredSecondData, selectRow));
    const searchedData = makeSearchedData(columnsList, filteredSecondData, selectedRows, idRowSelector);
    setSearchedData(searchedData);
  }, [columnsList, filteredSecondData, selectedRows, idRowSelector]);

  useEffect(() => {
    if (isUpdateCheckboxes && idRowSelector) {
      setSelectedRows([
        "all",
        ...sortedData.map((item) => item[idRowSelector]),
      ]);
      setUpdateCheckboxes(false);
    }
  }, [isUpdateCheckboxes]);

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      confirmUpdateModal && setConfirmUpdateModal(false);
    }
  }, [isLoading]);

  const onFilter = () => {
    setStartFilter(true);
    setDataIsFiltered(true);
  };

  const onSubmitSelected = async () => {
    const selectedArr = allSelected
      ? sortedData
          .filter((item) => !selectedRows.find((i) => i === item.investment_id))
          .map(({ investment_id }) => investment_id)
      : selectedRows;
    submitSelectedDataAction &&
      (await submitSelectedDataAction(
        selectedArr,
        dateValueRef?.current || "",
      ));
    !selectedArr && setSelectedRows([]);
  };

  const renderSelectedRowsAction = () => {
    return (
      <>
        {selectedActionSelectOptions && (
          <div className={s.selectAction}>
            {selectedActionSelectLabel && (
              <span className={s.selectActionLabel}>
                {selectedActionSelectLabel}
              </span>
            )}
            <Select
              options={selectedActionSelectOptions || []}
              value={selectedActionSelectValue}
              onSelect={(value: string) =>
                selectedActionSelectChange
                  ? selectedActionSelectChange(value)
                  : () => {}
              }
              size="xs"
              disabled={selectedActionSelectDisabled || !selectedRows.length}
            />
          </div>
        )}
        <Button
          size="xs"
          title={selectedActionBtnTitle}
          onClick={
            confirmModalText
              ? () => setConfirmUpdateModal(true)
              : onSubmitSelected
          }
          disabled={
            (allSelected
              ? sortedData.length <= selectedRows.length
              : !selectedRows.length) ||
            isLoading ||
            isUpdateCheckboxes
          }
          uppercase={true}
        />
      </>
    );
  };

  return (
    <>
      {infoRow && (
        <PledgeInfoRow
          val1={investment_count || 0}
          val2={investment_amount || 0}
          val3={selectedCount}
          val4={selectedAmount}
        />
      )}
      <ControlPanel
        onDownloadFile={onDownloadFile}
        formInPanel={formInPanel}
        onOpenAssetList={onOpenAssetList}
        onOpenFilter={withFilters ? () => toggleFilter(true) : undefined}
        onFiltersRegroup={onFiltersRegroup}
        shortFilterBtn={shortFilterBtn}
        dataIsFiltered={dataIsFiltered}
        tableAction={
          selectedActionBtnTitle ? renderSelectedRowsAction() : undefined
        }
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        data={searchedData}
        fixedFirstCol={Boolean(idRowSelector)}
        isLoading={isLoading}
      />
      {withFilters && (
        <ModalAside
          isOpen={isOpenFilter}
          onClose={() => toggleFilter(false)}
          title="Filter"
          bottomBtn={
            <Button
              title="Filter"
              onClick={onFilter}
              wide="long"
              disabled={!filterIsChanged}
              uppercase={true}
            />
          }
        >
          <TableFilters
            columnsList={columnsList}
            sortedData={sortedData}
            setFilteredData={setFilteredData}
            isStartFilter={isStartFilter}
            setStartFilter={setStartFilter}
            setDataIsFiltered={setDataIsFiltered}
            setFilterIsChanged={setFilterIsChanged}
          />
        </ModalAside>
      )}
      {confirmModalText && (
        <Modal
          isOpen={confirmUpdateModal}
          onClose={() => setConfirmUpdateModal(false)}
          title={confirmModalText}
          submitText={confirmModalSubmitText || "Submit"}
          onSubmit={onSubmitSelected}
          isLoading={isLoading}
          loaderText="Processing..."
          submitDisabled={!dirtyDate}
        >
          <PledgeConfirmModal
            subtitle={confirmModalSubtitle || ""}
            setDirtyDate={setDirtyDate}
            ref={dateValueRef}
          />
        </Modal>
      )}
    </>
  );
};
