import { CustomDatePicker, Input } from "components/common";
import s from "./FormUpdateData.module.scss";
import { useEffect, useState } from "react";
import { getSearchValues } from "store/actions/PayoffDemandActions";
import { useAppDispatch } from "hooks/reduxHooks";
import { FormUpdateDataButtons } from "./FormUpdateDataButtons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { getInitialAmount, getValueChangeInputAmount } from "utils";
import moment from "moment";
import { FieldWrapper } from "components/common/FormElements/FieldWrapper";
import { DeleteContractDataType } from "store/types/DeleteContractType";
import { deleteContract } from "store/actions/DeleteContractActions";
import { resetData } from "store/reducers/DeleteContractSlice";

type Props = {
  data: DeleteContractDataType;
  isLoading: boolean;
  isUpdated: boolean;
};

interface FormValues {
  contract_id: string;
  investment_amount: string;
  effective_date: Date | null;
  funded_date: Date | null;
  closing_date: Date | null;
  option_amount: string;
  acquisition_fee: string;
  assignment_cost: string;
  tax_title_cost: string;
  purchase_price: string;
}

export const FormDeleteContract = ({ data, isLoading, isUpdated }: Props) => {
  const [fetchData, setFetchData] = useState<any>(null);

  const dispatch = useAppDispatch();

  const withSubmitModal = true;
  const idForm = "deleteContract";

  const getInitialData = (data: DeleteContractDataType) => {
    return {
      contract_id: data.contract_id,
      investment_amount: getInitialAmount(data.investment_amount),
      effective_date: data.effective_date
        ? moment(data.effective_date).toDate()
        : null,
      funded_date: data.funded_date ? moment(data.funded_date).toDate() : null,
      closing_date: data.closing_date
        ? moment(data.closing_date).toDate()
        : null,
      option_amount: getInitialAmount(data.option_amount),
      acquisition_fee: getInitialAmount(data.acquisition_fee),
      assignment_cost: getInitialAmount(data.assignment_cost),
      tax_title_cost: getInitialAmount(data.tax_title_cost),
      purchase_price: getInitialAmount(data.purchase_price),
    };
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: getInitialData(data),
  });

  useEffect(() => {
    if (data.contract_id) reset(getInitialData(data));
  }, [data]);

  const onCancel = () => {
    dispatch(resetData());
  };

  const onChangeInputAmount = (name: keyof FormValues, value: string) => {
    const newVal = getValueChangeInputAmount(value);
    setValue(name, newVal);
  };

  const onSubmit: SubmitHandler<FormValues> = ({ contract_id }) => {
    const handleSubmit = async () => {
      await dispatch(deleteContract({ contract_id }));
      getSearchValues({});
    };

    withSubmitModal ? setFetchData({ contract_id }) : handleSubmit();
  };

  return (
    <form
      id={idForm}
      onSubmit={handleSubmit(onSubmit)}
      className={s.form}
      noValidate
    >
      <div className={s.item}>
        <span className={s.label}>Contract ID:</span>
        <Controller
          control={control}
          name="contract_id"
          render={({ field }) => <Input {...field} disabled />}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Investment Amount:</span>
        <Controller
          control={control}
          name="investment_amount"
          render={({ field }) => (
            <Input
              {...field}
              onChangeCustom={onChangeInputAmount}
              leftSymbol="$"
              amountFormat
              disabled
            />
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Effective Date:</span>
        <Controller
          control={control}
          name="effective_date"
          render={({ field }) => (
            <FieldWrapper error={errors.effective_date}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Funded Date:</span>
        <Controller
          control={control}
          name="funded_date"
          render={({ field }) => (
            <FieldWrapper error={errors.funded_date}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Closing Date:</span>
        <Controller
          control={control}
          name="closing_date"
          render={({ field }) => (
            <FieldWrapper error={errors.closing_date}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Option Amount:</span>
        <Controller
          control={control}
          name="option_amount"
          render={({ field }) => (
            <FieldWrapper error={errors.option_amount}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Acquisition Fee:</span>
        <Controller
          control={control}
          name="acquisition_fee"
          render={({ field }) => (
            <FieldWrapper error={errors.acquisition_fee}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Assignment Cost:</span>
        <Controller
          control={control}
          name="assignment_cost"
          render={({ field }) => (
            <FieldWrapper error={errors.assignment_cost}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Tax Title Cost:</span>
        <Controller
          control={control}
          name="tax_title_cost"
          render={({ field }) => (
            <FieldWrapper error={errors.tax_title_cost}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Purchase Price:</span>
        <Controller
          control={control}
          name="purchase_price"
          render={({ field }) => (
            <FieldWrapper error={errors.purchase_price}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <FormUpdateDataButtons
        withSubmitModal={withSubmitModal}
        onCancel={onCancel}
        idForm={idForm}
        fetchData={fetchData}
        fetchDataAction={deleteContract}
        updateDataAction={() => getSearchValues({})}
        isUpdated={isUpdated}
        isLoading={isLoading}
        submitBtnText="Delete"
        submitBtnModalText="Delete Contract"
        confirmSubmitTitle="Are you sure you want to delete this contract?"
        confirmSubmitSubtitle="You can't undo this action"
      />
    </form>
  );
};
