import { FilterType, IColumnData, IFilters } from "interfaces";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { usePrevious } from "hooks/usePrevious";
import isEqual from "lodash/isEqual";
import { Button } from "components/common";
import s from "./TableFilters.module.scss";
import { DateFilter, CheckboxFilter } from ".";
import { UpdateListContext } from "context/UpdateListContext";
import {
  getNotEmptyFilters,
  getInitialStateFilters,
  getUsersOptions,
} from "utils";
import { useAppSelector } from "hooks/reduxHooks";

type Props = {
  filters: IFilters;
  setFilters: Dispatch<SetStateAction<IFilters>>;
  columnsList: IColumnData[];
  isStartFilter: boolean;
  setStartFilter: Dispatch<SetStateAction<boolean>>;
  setDataIsFiltered: Dispatch<SetStateAction<boolean>>;
  filterIsChanged: boolean;
  setFilterIsChanged: Dispatch<SetStateAction<boolean>>;
};

export const TableFiltersFromRequest = ({
  filters,
  setFilters,
  columnsList,
  isStartFilter,
  setStartFilter,
  setDataIsFiltered,
  filterIsChanged,
  setFilterIsChanged,
}: Props) => {
  const { users } = useAppSelector((state) => state.manageUsers);

  const usersOptions = getUsersOptions(users || []);

  const [filterIsSelected, setFilterIsSelected] = useState(false);

  const { setUpdateList } = useContext(UpdateListContext);

  const prevFilters = usePrevious(filters);

  const clearFilters = () => {
    setFilters(getInitialStateFilters(columnsList));
  };

  const updateDataWithClearFilters = () => {
    !filterIsChanged && setUpdateList(true);
    setFilterIsSelected(false);
    setDataIsFiltered(false);
    setFilterIsChanged(false);
  };

  const onClear = () => {
    clearFilters();
    updateDataWithClearFilters();
  };

  const filter = () => {
    setUpdateList(true);
    setStartFilter(false);
    setFilterIsChanged(false);
  };

  useEffect(() => {
    if (!prevFilters && filters) return;

    if (!isEqual(prevFilters, filters)) {
      if (getNotEmptyFilters(filters).length) {
        setFilterIsSelected(true);
        setFilterIsChanged(true);
      } else updateDataWithClearFilters();
    } else setFilterIsChanged(false);
  }, [filters]);

  useEffect(() => {
    if (isStartFilter) {
      filter();
    }
  }, [isStartFilter]);

  return (
    <>
      {filterIsSelected && (
        <div className={s.sticky}>
          <Button
            title="Clear All Filters"
            onClick={onClear}
            size="xs"
            icon="close"
            className={s.gray}
            uppercase
          />
        </div>
      )}
      {columnsList.map(
        ({ filterType, name, label, filterSelectOptions, getOptions }) => {
          const optionsCheckboxList =
            filterSelectOptions || (getOptions === "users" && usersOptions);

          switch (filterType) {
            case FilterType.date:
              return (
                <Fragment key={name}>
                  <DateFilter
                    name={name}
                    label={label}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </Fragment>
              );
            case FilterType.checkboxList:
              return (
                <Fragment key={name}>
                  <CheckboxFilter
                    name={name}
                    label={label}
                    filters={filters}
                    setFilters={setFilters}
                    options={optionsCheckboxList || []}
                  />
                </Fragment>
              );
            default:
              return null;
          }
        }
      )}
    </>
  );
};
