import { useState } from "react";
import { checkDirty } from "utils";

export const useFormSelectArr = (defaultValues: any) => {
  const [valuesSelect, setValue] = useState(defaultValues);

  const [initialValues, setInitialValues] = useState(valuesSelect);

  const [valuesSelectDirty, setValuesDirty] = useState(false);

  const handleSelect = (field: string, value: string) => {
    const isDirty = checkDirty(initialValues, valuesSelect, field, value);
    isDirty !== undefined && setValuesDirty(isDirty);

    setValue((valuesSelect: any) => ({
      ...valuesSelect,
      [field]: value,
    }));
  };

  const updateInitialSelect = () => {
    setInitialValues(valuesSelect);
    setValuesDirty(false);
  };

  const resetSelect = () => {
    setValue(initialValues);
    setValuesDirty(false);
  };

  const resetOneSelect = (field: string) => {
    setValue((valuesSelect: any) => ({
      ...valuesSelect,
      [field]: defaultValues[field],
    }));
  };

  return {
    valuesSelect,
    handleSelect,
    valuesSelectDirty,
    updateInitialSelect,
    resetSelect,
    resetOneSelect,
    setValue,
  };
};
