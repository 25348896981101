import { createSlice } from "@reduxjs/toolkit";
import { DashboardsActionsType } from "store/types/DashboardsActions";
import { getTableauToken } from "store/actions/DashboardsActions";

const initialState = {
  isLoading: false,
  error: null,
  token: null,
} as DashboardsActionsType;

export const DashboardsActionsSlice = createSlice({
  name: "actionsDashboards",
  initialState,
  reducers: {},
  extraReducers: {
    [getTableauToken.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.token = action.payload.token;
    },
    [getTableauToken.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getTableauToken.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export default DashboardsActionsSlice.reducer;
