import { IOption } from "interfaces";
import { Checkbox } from "../FormElements/Checkbox/Checkbox";
import s from "./CheckboxList.module.scss";

interface IProps {
  list: IOption[];
  handleChange: (value: string) => void;
  checkedList: string[];
}

export const CheckboxList = ({ list, handleChange, checkedList }: IProps) => {
  return (
    <ul className={s.container}>
      {list.map(({ key, label, value }) => (
        <li key={key} className={s.item}>
          <Checkbox
            label={label}
            onChange={() => handleChange(value)}
            checked={checkedList?.includes(value)}
          />
        </li>
      ))}
    </ul>
  );
};
