import { createSlice } from "@reduxjs/toolkit";
import { ManageUsersTypes } from "store/types/ManageUsers";
import {
  createUser,
  deleteUser,
  editUser,
  getCurrentUser,
  getUsers,
} from "../actions/ManageUsersActions";

const initialState = {
  isLoading: false,
  error: null,
  users: null,
  currentUser: null,
} as ManageUsersTypes;

export const ManageUsersSlice = createSlice({
  name: "manageUsers",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
  },
  extraReducers: {
    [getUsers.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
      state.error = null;
    },
    [getUsers.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUsers.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [getCurrentUser.fulfilled.type]: (state, action) => {
      state.currentUser = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    [getCurrentUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getCurrentUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [createUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [deleteUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [editUser.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    [editUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [editUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, clearCurrentUser } = ManageUsersSlice.actions;

export default ManageUsersSlice.reducer;
