import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { DELETE_CONTRACT_DATA, GET_DELETE_CONTRACT_DATA } from "const";
import { encodeDataToUrl } from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";
const cookies = new Cookies();

export const getDeleteContractData = createAsyncThunk(
  "contractDelete",
  async (
    _payload: {
      contract_id: string;
    },
    thunkApi
  ) => {
    try {
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(
        `${GET_DELETE_CONTRACT_DATA}?${queryParams}`,
        {
          method: "get",
          headers: {
            authorization: "Bearer " + cookies.get("token"),
          },
        }
      );
      const parsed = await response.json();
      const { data } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(data);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteContract = createAsyncThunk(
  "contract/delete",
  async (
    _payload: {
      contract_id: string;
    },
    thunkApi
  ) => {
    try {
      const queryParams = encodeDataToUrl(_payload);

      await fetchWithAuth(`${DELETE_CONTRACT_DATA}?${queryParams}`, {
        method: "delete",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
          "Content-Type": "application/json",
        },
      });

      return thunkApi.fulfillWithValue({});
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
