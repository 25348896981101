import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Dashboard.module.scss";
import { pointBuyBoxTabs } from "const";

export const DashboardPointBuyBox = () => {
  return (
    <PageContent title="Point Buy Box Dashboard" path="Dashboards">
      <div className={s.container}>
        <iframe
          title="dashboards"
          src={pointBuyBoxTabs[0].link}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </PageContent>
  );
};
