import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_HPA_CONTRACT_LIST } from "const";
import { encodeDataToUrl } from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const getList = createAsyncThunk(
  "hpaContractList",
  async (
    _payload: {
      model: string;
      contract_id: string;
      report_type: string;
      from_year: string;
      to_year: string;
      page_size: number;
      page: number;
    },
    thunkApi
  ) => {
    try {
      const cookies = new Cookies();
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(`${GET_HPA_CONTRACT_LIST}?${queryParams}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });

      const parsed = await response.json();

      if (response.status !== 200) {
        throw new Error(parsed.detail);
      }
      return thunkApi.fulfillWithValue(parsed);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getList };
