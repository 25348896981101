import { useState } from "react";

export const useFormSelect = (defaultValue: string) => {
  const [value, setValue] = useState(defaultValue);

  const handleSelect = (val: string) => {
    setValue(val);
  };

  const resetSelect = () => {
    setValue(defaultValue);
  };

  return { value, handleSelect, resetSelect };
};
