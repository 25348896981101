import { Burger } from "components/modules";
import s from "./Sidebar.module.scss";
import cn from "classnames";
import Logo from "assets/images/logo.svg";
import { NavigationMenu } from "../NavigationMenu/NavigationMenu";

type Props = {
  isNarrow: boolean;
  toggleSidebar: () => void;
};

export const Sidebar = ({ isNarrow, toggleSidebar }: Props) => {
  return (
    <aside className={cn(s.container, isNarrow && s.narrow)}>
      <div className={s.header}>
        <Burger isNarrow={isNarrow} toggleSidebar={toggleSidebar} />
        <div className={cn(s.logoWrapper, s.wideOnly)}>
          <img src={Logo} className={s.logo} alt="Kingsbridge logo" />
          <strong className={s.logoText}>HEI EDGE</strong>
        </div>
      </div>
      <div className={s.nav}>
        <NavigationMenu isNarrow={isNarrow} />
      </div>
    </aside>
  );
};
