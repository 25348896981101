import { createSlice } from "@reduxjs/toolkit";
import { getContractsIdsList } from "store/actions/ContractsActions";
import { ContractIdsType } from "../types/ContractIdsType";

const initialState = {
  isLoading: false,
  error: null,
  contract_ids: []
} as ContractIdsType;

export const ContractIdsSlice = createSlice({
  name: "contractIds",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetList: (state) => {
      state.contract_ids = [];
    },
  },
  extraReducers: {
    [getContractsIdsList.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.contract_ids = action.payload;
      state.error = null;
    },
    [getContractsIdsList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getContractsIdsList.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.contract_ids = [];
      state.isLoading = false;
    },
  },
});

export const { setError, resetList } = ContractIdsSlice.actions;

export default ContractIdsSlice.reducer;
