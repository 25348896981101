import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, UploadNotification } from "components/modules";
import { GENERATE_MONTHLY_ROLLS, S3_DOWNLOAD_FILE } from "const";
import { useFormSelectDate } from "hooks/useFormSelectDate";
import { useGetQuery } from "hooks/useGetQuery";
import { useLocalStorage } from "hooks/useLocalStorage";
import { usePostQuery } from "hooks/usePostQuery";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  downloadFile,
  encodeDataToUrl,
  getLoading,
  getUploadStatus,
  getUploadStatusStorage,
} from "utils";

type Props = {};
export const MonthlyRolls = (props: Props) => {
  const pageName = "monthly_rolls";

  const {
    value: startDate,
    handleDateChange,
    resetDate,
  } = useFormSelectDate(null);

  const [contractIds, setContractIds] = useState("")

  const [showUploadNotification, setShowUploadNotification] = useState(
    localStorage.getItem(pageName) || false
  );

  const getInitialFileData = () => ({
    file_name: localStorage.getItem(pageName)
      ? JSON.parse(localStorage.getItem(pageName) || "")?.fileName
      : "",
    file_id: localStorage.getItem(pageName)
      ? JSON.parse(localStorage.getItem(pageName) || "")?.fileId
      : "",
  });

  const [generatedFile, setGeneratedFile] = useState(getInitialFileData());

  const { changeStorage } = useLocalStorage(pageName, () => {
    setGeneratedFile(getInitialFileData());
  });

  const { post, loading, responseStatus, error, responseData } = usePostQuery(
    GENERATE_MONTHLY_ROLLS,
    changeStorage
  );

  const { get: getS3FileUrl } = useGetQuery(S3_DOWNLOAD_FILE);

  useEffect(() => {
    if (responseData) {
      const { file_name, file_id } = responseData;
      setGeneratedFile({ file_id, file_name });
    }
  }, [responseData]);

  const resetValues = () => {
    resetDate();
  };

  const generateReportFile = async () => {
    try {
      const data = {
        valuation_date: moment(startDate, "YYYY-MM-DD")
            .endOf("month")
            .format("YYYY-MM-DD"),
        contract_ids: contractIds
      }

      setShowUploadNotification(true);
      const result = await post(data, "", pageName, generatedFile.file_name);

      if (result?.status) {
        resetValues();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async () => {
    try {
      if (generatedFile.file_id && generatedFile?.file_name) {
        const { file_id, file_name } = generatedFile;

        const query = encodeDataToUrl({ _id: file_id });

        const response = await getS3FileUrl(query);

        await downloadFile(file_name, response?.url);
      }
    } catch (error) {
      console.log("[ASSET LIST][DOWNLOAD ASSET]: ", error);
    }
  };

  const status = getUploadStatus(loading, responseStatus);

  const renderRightContent = () => {
    return (
      <UploadNotification
        status={getUploadStatusStorage(pageName, status)}
        fileName={generatedFile.file_name}
        closeNotification={() => setShowUploadNotification(false)}
        pageName={pageName}
        pageTitle="Monthly Rolls"
        handleDownload={handleDownload}
        disabledDownload={!generatedFile.file_id || !generatedFile?.file_name}
      />
    );
  };

  return (
    <PageContent title="Monthly Roll Forward Report" path="Accounting">
      <PageFormLayout
        rightContent={showUploadNotification ? renderRightContent() : null}
      >
        <>
          <FormBlock
            fields={{
              monthCalendar: {
                oneInRow: true,
                startDate,
                setStartDate: (value: Date | undefined | null) =>
                  handleDateChange(value),
              },
              /*textArea: {
                fieldName: 'IDs of contracts',
                oneInRow: true,
                placeholder: "You can put here contracts ids separated by comma if you need to build a report for specific contracts. You may leave it empty.",
                cols: 10,
                rows: 10,
                value: contractIds,
                onChange: (e) => setContractIds(e.target.value)
              }*/
            }}
            importButton={{
              label: "GENERATE REPORT",
              disabled: !startDate,
              loading: getLoading(loading, pageName),
              loadingText: "GENERATING...",
              onclick: generateReportFile,
            }}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
