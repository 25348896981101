import cn from "classnames";
import s from "./Burger.module.scss";

type Props = {
  isNarrow: boolean;
  toggleSidebar: () => void;
};

export const Burger = ({ isNarrow, toggleSidebar }: Props) => {
  return (
    <button
      className={cn(s.btn, isNarrow && s.narrow)}
      onClick={() => toggleSidebar()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path className={s.b1} />
        <path className={s.b2} />
        <path className={s.b3} />
      </svg>
    </button>
  );
};
