import { RefObject, useEffect, useState } from "react";

import useResizeObserver from "@react-hook/resize-observer";

export const useGetElementHeight = (elem: RefObject<HTMLElement>) => {
  const [elemHeight, setHeight] = useState<number>(0);

  const defineValue = () => {
    const val = elem.current ? elem.current?.offsetHeight : 0;
    setHeight(val);
  };

  useEffect(() => {
    defineValue();
  }, []);

  useResizeObserver(elem, defineValue);

  return [elemHeight];
};
