import { Button, Checkbox, Icon, Modal, ModalAside } from "components/common";
import { TableFilters } from "components/modules";
import { IColumnData, ISelectedRowInMonths } from "interfaces";
import { useState, useMemo, useEffect } from "react";
import { ControlPanel } from "../ControlPanel/ControlPanel";
import s from "./TableControls.module.scss";
import { useFilter } from "hooks/useFilter";
import { generateDataByMonthsAndPools, formatRowData } from "utils";
import { TableWithMonths } from "components/common/Table/TableWithMonths";
import moment from "moment";
import { useSelectRowInMonths } from "hooks/useSelectRowInMonths";
import cn from "classnames";
import { usePrevious } from "hooks/usePrevious";
import {ORIGINATOR_OPTIONS_FOR_MODELS} from "../../../const";

type Props = {
  columnsList: IColumnData[];
  list: any[];
  isLoading?: boolean;
  getTableList: () => void;
  idRowSelector: string;
  idRowFile: string;
  dateField: string;
  poolIdField: string;
  onDownloadFile: (id: number) => void;
  onOpenAssetList?: () => void;
  onSelectPoolModel?: (val: string) => void;
  poolModelValue?: string;
  withFilters?: boolean;
  selectedActionBtnTitle?: string;
  confirmModalText?: string;
  confirmModalSubmitText?: string;
  submitSelectedDataAction: (data: any) => Promise<Response>;
  submitSelectedDataLoading: boolean;
  generateModalTexts: (
    modalType: string,
    selectedRows: ISelectedRowInMonths[],
  ) => string;
};

export const TableControlsWithMonths = ({
  columnsList,
  list,
  isLoading,
  getTableList,
  idRowSelector,
  idRowFile,
  dateField,
  poolIdField,
  onDownloadFile,
  onOpenAssetList,
  onSelectPoolModel,
  poolModelValue,
  withFilters,
  selectedActionBtnTitle,
  confirmModalText,
  confirmModalSubmitText,
  submitSelectedDataAction,
  submitSelectedDataLoading,
  generateModalTexts,
}: Props) => {
  const [filteredDataByMonthsAndPool, setFilteredData] = useState(
    generateDataByMonthsAndPools(list),
  );

  const [dataIsFiltered, setDataIsFiltered] = useState(false);

  const [filterIsChanged, setFilterIsChanged] = useState(false);

  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);

  const [resultUpdateModal, setResultUpdateModal] = useState(false);

  const { isOpenFilter, toggleFilter, isStartFilter, setStartFilter } =
    useFilter();

  const {
    selectedRows,
    addInitialSelectedRows,
    clearAllSelectedRows,
    onSelectRow,
    changedRows,
  } = useSelectRowInMonths(idRowSelector);

  const columns = useMemo(() => {
    const dataColumns = columnsList.map(({ name, label, label2 }) => ({
      Header: (
        <div className={cn(s.headCell, s.center)}>
          <div>
            <span className={s.headCellLabel}>{label}</span>
            {label2 && <span className={s.headCellLabel}>{label2}</span>}
          </div>
        </div>
      ),
      accessor: name,
    }));

    return [
      {
        Header: "",
        accessor: "selector",
        width: "40px",
      },
      ...dataColumns,
      {
        Header: "File Path Link",
        accessor: "file_path_link",
      },
    ];
  }, [list]);

  useEffect(() => {
    setFilteredData(list);
    clearAllSelectedRows();

    if (list.length) {
      setFilteredData(generateDataByMonthsAndPools(list));
      addInitialSelectedRows(list);
    }
  }, [list]);

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      if (confirmUpdateModal) {
        setConfirmUpdateModal(false);
        setResultUpdateModal(true);
      }
    }
  }, [isLoading]);

  const onFilter = () => {
    setStartFilter(true);
    setDataIsFiltered(true);
  };

  const getHeaderLineText = (valuationDateString: string) => {
    return `${moment(valuationDateString).format("MMMM")} ${moment(
      valuationDateString,
    ).format("YYYY")}`;
  };

  const formatRowDataInMonth = (
    row: any,
    choosenAsset: number | null,
    yearMonthIndex: string,
  ) => {
    if (row) {
      const isRowSelected = choosenAsset === row[idRowSelector];

      const rowData = formatRowData(row, columnsList);

      return {
        selector: (
          <div className={s.cellCheckbox}>
            <Checkbox
              checked={isRowSelected}
              onChange={() =>
                onSelectRow(
                  row[idRowSelector],
                  choosenAsset,
                  isRowSelected,
                  +yearMonthIndex,
                )
              }
              type="circle"
              className={
                isRowSelected &&
                changedRows.find(({ id }) => id === choosenAsset)
                  ? "new"
                  : ""
              }
            />
          </div>
        ),
        ...rowData,
        file_path_link: (
          <span
            onClick={() => onDownloadFile(row[idRowFile])}
            className={s.actionInCell}
          >
            <Icon icon="download" />
            <span>Download</span>
          </span>
        ),
      };
    }
  };

  const getModelPoolName = (poolId: number) => {
    const found = ORIGINATOR_OPTIONS_FOR_MODELS.find(item => item.poolId == poolId);
    return found ? found.label : "";
  };

  const renderTableData = () => {
    return filteredDataByMonthsAndPool.length
      ? filteredDataByMonthsAndPool?.map((item) => {
          const yearMonthIndex = item[0];
          const chosenAsset =
            selectedRows.find(({ yearMonth }) => yearMonth === +yearMonthIndex)
              ?.id || null;

          return {
            group: item[1]
              .map((row: any) =>
                formatRowDataInMonth(row, chosenAsset, yearMonthIndex),
              )
              .sort(
                (rowA: any, rowB: any) =>
                  rowB.valuation_date.split("-")[2].split(" ")[0] -
                  rowA.valuation_date.split("-")[2].split(" ")[0],
              ),
            headerLineText: `${getHeaderLineText(item[1][0][dateField])} - ${getModelPoolName(item[1][0][poolIdField])}`,
          };
        })
      : [];
  };

  const renderSelectedRowsAction = () => {
    return (
      <>
        <Button
          size="xs"
          title={selectedActionBtnTitle}
          onClick={() => setConfirmUpdateModal(true)}
          disabled={!changedRows.length}
          uppercase={true}
        />
      </>
    );
  };

  const onSubmitSelected = async () => {
    const selectedRowsData = changedRows.map(({ id }) => {
      return list.find((row) => row[idRowSelector] === id);
    });

    if (selectedRowsData?.length) {
      selectedRowsData.forEach(async (data) => {
        const response = await submitSelectedDataAction(data);

        if (response?.status === 200) {
          getTableList();
        }
      });
    }
  };

  const onCancelConfirmUpdate = () => {
    addInitialSelectedRows(list);
  };

  return (
    <>
      <ControlPanel
        onOpenAssetList={onOpenAssetList}
        onOpenFilter={withFilters ? () => toggleFilter(true) : undefined}
        onSelectPoolModel={onSelectPoolModel}
        poolModelValue={poolModelValue}
        dataIsFiltered={dataIsFiltered}
        tableAction={
          selectedActionBtnTitle ? renderSelectedRowsAction() : undefined
        }
        isLoading={isLoading}
      />
      <TableWithMonths
        columns={columns}
        data={renderTableData()}
        fixedFirstCol={true}
      />
      {withFilters && (
        <ModalAside
          isOpen={isOpenFilter}
          onClose={() => toggleFilter(false)}
          title="Filter"
          bottomBtn={
            <Button
              title="Filter"
              onClick={onFilter}
              wide="long"
              disabled={!filterIsChanged}
              uppercase={true}
            />
          }
        >
          <TableFilters
            columnsList={columnsList}
            sortedData={list}
            setFilteredData={setFilteredData}
            isStartFilter={isStartFilter}
            setStartFilter={setStartFilter}
            setDataIsFiltered={setDataIsFiltered}
            setFilterIsChanged={setFilterIsChanged}
            dataByMonths={true}
          />
        </ModalAside>
      )}
      {confirmUpdateModal && (
        <Modal
          isOpen={confirmUpdateModal}
          onClose={() => setConfirmUpdateModal(false)}
          title={generateModalTexts("confirmation", changedRows)}
          submitText={confirmModalSubmitText || "Submit"}
          onSubmit={onSubmitSelected}
          onCancel={onCancelConfirmUpdate}
          isLoading={isLoading}
          loaderText="Processing..."
        />
      )}
      {resultUpdateModal && (
        <Modal
          isOpen={resultUpdateModal}
          onClose={() => setResultUpdateModal(false)}
          title={generateModalTexts("success", selectedRows)}
          withCancelBtn={false}
          submitText={"Ok"}
          onSubmit={() => setResultUpdateModal(false)}
        />
      )}
    </>
  );
};
