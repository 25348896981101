import { useEffect } from "react";

const useClickOutside = (
  refElem: any,
  isOpen: boolean,
  callback: () => void,
  refOverlay?: any
) => {
  const handleClick = (e: any) => {
    if (
      refOverlay
        ? refOverlay?.current?.contains(e.target) &&
          !refElem?.current?.contains(e.target)
        : !refElem?.current?.contains(e.target)
    ) {
      callback();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isOpen]); // eslint-disable-line
};

export default useClickOutside;
