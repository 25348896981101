import { Button, Input } from "components/common";
import s from "./Auth.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { nonEmptyField, passwordsMatch } from "utils";
import { AuthRoute, FORGOT_PASSWORD_CONFIRMATION_API } from "const";
import { usePostQuery } from "../../../hooks/usePostQuery";
import { useAppSelector } from "../../../hooks/reduxHooks";

type StateTypes = {
    newPassword: {
        value: string;
        visible: boolean;
    };
    reNewPassword: {
        value: string;
        visible: boolean;
    };
};

export const ForgotPasswordConfirmation = () => {
    const { isLoading, error } = useAppSelector((state) => state.user);
    const { accessCode } = useParams();

    const defaultChangePasswordState = {
        newPassword: {
            value: "",
            visible: false,
        },
        reNewPassword: {
            value: "",
            visible: false,
        },
    };

    const defaultValidationErrors = {
        newPassword: "",
        reNewPassword: "",
    };

    const [changePasswordValues, setChangePasswordValues] = useState<StateTypes>(
        defaultChangePasswordState
    );
    const { newPassword, reNewPassword } = changePasswordValues;

    const [validationErrors, setValidationErrors] = useState(
        defaultValidationErrors
    );

    const navigate = useNavigate();

    const { loading, postFormData, responseStatus, responseData } = usePostQuery(
        FORGOT_PASSWORD_CONFIRMATION_API
    );

    const setPasswordVisible = (field: keyof StateTypes) => {
        setChangePasswordValues((prev) => ({
            ...prev,
            [field]: {
                value: prev[field].value,
                visible: !prev[field].visible,
            },
        }));
    };

    const handleChangePassword = (
        value: string,
        field: keyof StateTypes,
        error: string
    ) => {
        setChangePasswordValues((prev: StateTypes) => ({
            ...changePasswordValues,
            [field]: {
                value: value,
            },
        }));
        setValidationErrors({
            ...validationErrors,
            [field]: error,
        });
    };

    useEffect(() => {
        if (responseStatus === 200) {
            navigate(`${AuthRoute.LOGIN}`, { replace: true })
        }
    }, [responseStatus]);

    const onSubmit = async (e: React.MouseEvent) => {
        e.preventDefault();
        const isNewPasswordValid = nonEmptyField(newPassword.value);
        const doPasswordsMatch = passwordsMatch(
            newPassword.value,
            reNewPassword.value
        );
        if (!isNewPasswordValid && !doPasswordsMatch) {
            const requestData = new FormData();
            // @ts-ignore
            requestData.append("access_code", accessCode);
            requestData.append("password", newPassword?.value);
            postFormData(requestData);
        }
    };

    return <div className={s.container}>
        <div className={s.heading}>
            <p className={s.h2}>Please, enter the new password</p>
        </div>
        <form className={s.form} noValidate>
            <Input
                value={newPassword.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangePassword(
                        e.target.value,
                        "newPassword",
                        nonEmptyField(e.target.value)
                    )
                }
                className={s.input}
                placeholder="Enter password"
                type={newPassword.visible ? "text" : "password"}
                onClickIcon={() => setPasswordVisible("newPassword")}
                rightIcon={newPassword.visible ? "eye-closed" : "eye"}
                status={validationErrors.newPassword ? "error" : "normal"}
                statusText={validationErrors.newPassword}
            />
            <Input
                value={reNewPassword.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangePassword(
                        e.target.value,
                        "reNewPassword",
                        passwordsMatch(newPassword.value, e.target.value)
                    )
                }
                className={s.input}
                placeholder="Enter password"
                type={reNewPassword.visible ? "text" : "password"}
                onClickIcon={() => setPasswordVisible("reNewPassword")}
                rightIcon={reNewPassword.visible ? "eye-closed" : "eye"}
                status={validationErrors.reNewPassword ? "error" : "normal"}
                statusText={validationErrors.reNewPassword}
                iconWarning={false}
            />
            <Button
                size="large"
                wide="long"
                title="SUBMIT"
                onClick={(e: React.MouseEvent) => onSubmit(e)}
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                loader="Processing..."
                uppercase
            />
        </form>
    </div>;
};
