import { Dispatch, RefObject, SetStateAction } from "react";
import { IOption } from "./form";

export enum Sorting {
  asc = "asc",
  dsc = "dsc",
}

export enum SortType {
  alphabet = "alphabet",
  num = "num",
}

export enum FilterType {
  percent = "percent",
  percentNotDecimal = "percentNotDecimal",
  date = "date",
  amount = "amount",
  select = "select",
  checkboxList = "checkboxList",
}

export enum FormatType {
  percent = "percent",
  percentNotDecimal = "percentNotDecimal",
  date = "date",
  amount = "amount",
  variance = "variance",
  list = "list",
  multiList = "multiList",
}

export interface IColumnData {
  label: string;
  label2?: string;
  name: string;
  sortType?: SortType | null;
  filterType?: FilterType | null;
  filterSelectOptions?: IOption[];
  getOptions?: string;
  formatType?: FormatType;
  className?: string;
  notEmpty?: boolean;
}

export enum ActionsColumn {
  edit = "edit",
  delete = "delete",
  download = "download",
  setPermissions = "setPermissions",
}

export interface ISelectedRowInMonths {
  yearMonth: number;
  id: number;
}

export interface IChangeListParams {
  total: number;
  getListAction: any;
  clearListAction: any;
  listContainer: HTMLDivElement | null;
  listRef: RefObject<HTMLElement>;
  limitList?: number;
  tableHeaderHeight?: number;
  sort?: Sorting;
  search?: string;
  filters?: IFilters;
}

export interface IPropsFilter {
  name: string;
  label: string;
  filters: any;
  setFilters: Dispatch<SetStateAction<any[]>>;
}

export interface IFilters {
  [name: string]: {
    filterType: FilterType;
    notEmpty: boolean;
    from?: string;
    to?: string;
    fromDate?: Date;
    toDate?: Date;
    equal?: string;
    checkboxList?: string[];
  };
}
