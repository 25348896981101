import { PageContent } from "components/layout/PageContent/PageContent";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useFormSelectDateArr } from "hooks/useFormSelectDateArr";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import {
  columnsOfficialRunSelectionAssetList,
  columnsOfficialRunSelectionValuationList,
  LIST_TYPE_OPTIONS,
  S3_DOWNLOAD_FILE,
  SUBMIT_AS_OFFICIAL,
} from "const";
import { useEffect, useState } from "react";
import { getList } from "store/actions/OfficialRunAssetList";
import { downloadFile, downloadResourceFromURL, downloadS3File, encodeDataToUrl } from "utils";
import { usePostQuery } from "hooks/usePostQuery";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControlsWithMonths } from "components/modules";
import { ISelectedRowInMonths } from "interfaces";
import { usePrevious } from "hooks/usePrevious";
import { allKasfIdsKey } from "../../../const/constants";
import { useGetQuery } from "hooks/useGetQuery";
import { useCookies } from "react-cookie";

export const OfficialRunSelection = () => {
  const {
    valuesSelect,
    handleSelect,
    valuesSelectDirty,
    updateInitialSelect,
    resetSelect,
  } = useFormSelectArr({
    listType: "Asset List",
  });

  const [cookies] = useCookies(['token'])

  const {
    valuesDate,
    handleDateChange,
    valuesDateDirty,
    updateInitialDate,
    resetDate,
  } = useFormSelectDateArr({
    startDate: new Date(
      moment().subtract(1, "months").startOf("month").toString()
    ),
    endDate: new Date(moment().subtract(1, "months").endOf("month").toString()),
  });

  const { listType } = valuesSelect;

  const { startDate, endDate } = valuesDate;

  const [columnsList, setColumnsList] = useState(
    columnsOfficialRunSelectionAssetList
  );

  const [selectedPoolModel, setSelectedPoolModel] = useState(allKasfIdsKey);

  const [tableMode, setTableMode] = useState(false);

  const [isOpenAssetList, toggleAssetList] = useState(false);

  const { list, isLoading } = useAppSelector(
    (state) => state.officialRunAssetList
  );

  const dispatch = useAppDispatch();

  const { post: submit, loading: submitAsOfficialLoading } =
    usePostQuery(SUBMIT_AS_OFFICIAL);

  const { get: getS3FileUrl } = useGetQuery(S3_DOWNLOAD_FILE);

  const idRowSelector = "model_run_id";

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
      isOpenAssetList && toggleAssetList(false);
    }
  }, [isLoading]);

  const fields = {
    select1: {
      options: LIST_TYPE_OPTIONS,
      onSelect: (value: string) => handleSelect("listType", value),
      value: listType,
      fieldName: "Select List",
      oneInRow: true,
    },
    startDate: {
      startDate,
      setStartDate: (value: Date | undefined | null) =>
        handleDateChange("startDate", value),
      fieldName: "Official List",
      label: "Date From",
    },
    endDate: {
      endDate,
      setEndDate: (value: Date | undefined | null) =>
        handleDateChange("endDate", value),
      label: "Date To",
    },
  };

  const getTableList = (modelPoolValue: string = allKasfIdsKey) => {
    if (!isLoading) {
      const listTypeOption = LIST_TYPE_OPTIONS.find(
        ({ value }) => value === listType
      );

      dispatch(
        getList({
          _type: listTypeOption?.key || "",
          model: modelPoolValue,
          date_from: moment(startDate).format("YYYY-MM-DD"),
          date_to: moment(endDate).format("YYYY-MM-DD"),
        })
      );

      setColumnsList(
        listType === "Asset List"
          ? columnsOfficialRunSelectionAssetList
          : columnsOfficialRunSelectionValuationList
      );

      updateInitialSelect();
      updateInitialDate();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = async (id: number) => {
    try {
      const queryText = encodeDataToUrl({ _id: id });
      // tslint:disable-next-line:prefer-template
      const response = await getS3FileUrl(queryText);
      const urlObject = new URL(response?.url);
      const filename = urlObject.pathname.split("/").pop();
      await downloadFile(filename || 'run.xlsx', response?.url, false);
    } catch (error) {
      console.log("[ORS][DOWNLOAD ASSET]: ", error);
    }
  };

  const handleClickImportBtn = () => {
    setSelectedPoolModel(allKasfIdsKey);
    getTableList();
  };

  const onModelPoolSelected = (val: string) => {
    setSelectedPoolModel(val);
    getTableList(val);
  };

  const submitSelectedDataAction = async (data: any) => {
    const listTypeOption = LIST_TYPE_OPTIONS.find(
      ({ value }) => value === listType
    );

    return await submit({
      model_run_id: data.model_run_id,
      originator: data.pool_id,
      year: moment(data.valuation_date).format("YYYY"),
      month: moment(data.valuation_date).format("MM"),
      type: listTypeOption?.key,
    });
  };

  const generateModalTexts = (
    modalType: string,
    selectedRows: ISelectedRowInMonths[]
  ) => {
    const getMonth = () => {
      const resultMonths = selectedRows.map(({ id }) =>
        moment(
          list.find((row) => row[idRowSelector] === id)?.valuation_date
        ).format("MMMM YYYY")
      );

      return resultMonths.join(" , ");
    };

    const getListId = () => {
      const resultMonths = selectedRows.map(({ id }) => id);
      return resultMonths.join(" , ");
    };

    switch (modalType) {
      case "confirmation":
        return `Are you sure you want to submit run id # ${getListId()} - as the official ${listType} for the month of ${getMonth()} ?`;
      case "success":
        return `Run id # ${getListId()} - is submited as the official ${listType} for the month of ${getMonth()}`;
      default:
        return "";
    }
  };

  const onCloseAssetList = () => {
    toggleAssetList(false);
    resetSelect();
    resetDate();
  };

  return (
    <PageContent
      title="Official Run Selection"
      path="Valuation"
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fields}
            importButton={{
              label: isLoading ? "Processing..." : "Get List",
              onclick: handleClickImportBtn,
              disabled:
                isLoading ||
                (tableMode && !valuesSelectDirty && !valuesDateDirty),
            }}
            tableMode={tableMode}
            isOpenAssetList={isOpenAssetList}
            onCloseAssetList={onCloseAssetList}
          />
          {tableMode && (
            <TableControlsWithMonths
              columnsList={columnsList}
              list={list}
              getTableList={getTableList}
              onDownloadFile={onDownloadFile}
              onOpenAssetList={() => toggleAssetList(true)}
              onSelectPoolModel={(value: any) => onModelPoolSelected(value)}
              poolModelValue={selectedPoolModel}
              withFilters={true}
              idRowSelector={idRowSelector}
              idRowFile="s3_file_id"
              dateField="valuation_date"
              poolIdField="pool_id"
              selectedActionBtnTitle="Submit as Official"
              confirmModalText="Are you sure you want to submit run id"
              confirmModalSubmitText="Submit"
              submitSelectedDataAction={submitSelectedDataAction}
              submitSelectedDataLoading={submitAsOfficialLoading}
              generateModalTexts={generateModalTexts}
              isLoading={isLoading}
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
