import { PageContent } from "components/layout/PageContent/PageContent";
import { FormBlock, UploadNotification } from "components/modules";
import {
  GENERATE_ASSET_LIST,
  ORIGINATOR_OPTIONS_FOR_MODELS,
  S3_DOWNLOAD_FILE,
} from "const";
import { useGetQuery } from "hooks/useGetQuery";
import { usePostQuery } from "hooks/usePostQuery";
import moment from "moment";
import { useState, useEffect } from "react";
import {
  downloadFile,
  getUploadStatus,
  getUploadStatusStorage,
  encodeDataToUrl,
  getLoading,
} from "utils";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useFormSelect } from "hooks/useFormSelect";
import { useFormSelectDate } from "hooks/useFormSelectDate";

export const AssetList = () => {
  const pageName = "asset_list";

  const { value: originator, handleSelect, resetSelect } = useFormSelect("All");

  const {
    value: startDate,
    handleDateChange,
    resetDate,
  } = useFormSelectDate(moment().subtract(1, "months").endOf("month").toDate());

  const [showUploadNotification, setShowUploadNotification] = useState(
    localStorage.getItem(pageName) || false
  );

  const getInitialFileData = () => ({
    file_name: localStorage.getItem(pageName)
      ? JSON.parse(localStorage.getItem(pageName) || "")?.fileName
      : "",
    file_id: localStorage.getItem(pageName)
      ? JSON.parse(localStorage.getItem(pageName) || "")?.fileId
      : "",
  });

  const [generatedFile, setGeneratedFile] = useState(getInitialFileData());

  const { changeStorage } = useLocalStorage(pageName, () => {
    setGeneratedFile(getInitialFileData());
  });

  const { post, loading, responseStatus, error, responseData } = usePostQuery(
    GENERATE_ASSET_LIST,
    changeStorage
  );

  const { get: getS3FileUrl } = useGetQuery(S3_DOWNLOAD_FILE);

  useEffect(() => {
    if (responseData) {
      const { file_name, file_id } = responseData;
      setGeneratedFile({ file_id, file_name });
    }
  }, [responseData]);

  const resetValues = () => {
    resetSelect();
    resetDate();
  };

  const generateReportFile = async () => {
    try {
      const query = encodeDataToUrl({
        model: originator,
        date: moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      });

      setShowUploadNotification(true);
      const result = await post("", query, pageName, generatedFile.file_name);

      if (result?.status) {
        resetValues();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async () => {
    try {
      if (generatedFile.file_id && generatedFile?.file_name) {
        const { file_id, file_name } = generatedFile;

        const query = encodeDataToUrl({ _id: file_id });

        const response = await getS3FileUrl(query);

        await downloadFile(file_name, response?.url);
      }
    } catch (error) {
      console.log("[ASSET LIST][DOWNLOAD ASSET]: ", error);
    }
  };

  const status = getUploadStatus(loading, responseStatus);

  const renderRightContent = () => {
    return (
      <UploadNotification
        status={getUploadStatusStorage(pageName, status)}
        fileName={generatedFile.file_name}
        closeNotification={() => setShowUploadNotification(false)}
        pageName={pageName}
        pageTitle="Asset List"
        handleDownload={handleDownload}
        disabledDownload={!generatedFile.file_id || !generatedFile?.file_name}
      />
    );
  };

  return (
    <PageContent title="Asset List" path="Valuation">
      <PageFormLayout
        rightContent={showUploadNotification ? renderRightContent() : null}
      >
        <>
          <FormBlock
            fields={{
              select1: {
                options: ORIGINATOR_OPTIONS_FOR_MODELS,
                onSelect: handleSelect,
                value: originator,
                fieldName: "Select Originator",
              },
              startDate: {
                startDate,
                setStartDate: (value: Date | undefined | null) =>
                  handleDateChange(value),
                fieldName: "Valuation Date",
              },
            }}
            importButton={{
              label: "GENERATE ASSET LIST",
              disabled: !originator || !startDate,
              loading: getLoading(loading, pageName),
              loadingText: "GENERATING...",
              onclick: generateReportFile,
            }}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
