import cn from "classnames";
import s from "./PageFormLayout.module.scss";

type Props = {
  children: JSX.Element;
  rightContent?: JSX.Element | null;
  tableMode?: boolean;
  widthLeft?: number;
  leftOnly?: boolean;
};

export const PageFormLayout = ({
  children,
  rightContent,
  tableMode,
  widthLeft = 50,
  leftOnly,
}: Props) => {
  return (
    <>
      {tableMode ? (
        <>{children}</>
      ) : (
        <div className={s.wrapper}>
          <div
            className={cn(s.left, leftOnly && s.only)}
            style={{ width: `${widthLeft}%` }}
          >
            {children}
          </div>
          <div className={s.right} style={{ width: `${100 - widthLeft}%` }}>
            {rightContent && rightContent}
          </div>
        </div>
      )}
    </>
  );
};
