import { createSlice } from "@reduxjs/toolkit";
import { getList } from "store/actions/HPAMetroActions";
import { HpaContractType } from "../types/HpaContractType";

const initialState = {
    isLoading: false,
    error: null,
    list: {},
} as HpaContractType;

export const HPAMetroSlice = createSlice({
    name: "hpaMetro",
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        resetList: (state) => {
            state.list = {};
        },
    },
    extraReducers: {
        [getList.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.list = action.payload;
            state.error = null;
        },
        [getList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getList.rejected.type]: (state, action) => {
            state.error = action.payload;
            state.list = {};
            state.isLoading = false;
        },
    },
});

export const { setError, resetList } = HPAMetroSlice.actions;

export default HPAMetroSlice.reducer;
