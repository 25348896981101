import { createSlice } from "@reduxjs/toolkit";
import { PermissionsType } from "store/types/PermissionsType";
import {
  getPermissions,
  updatePermissions,
} from "store/actions/PermissionsActions";

const initialState = {
  isLoading: false,
  error: null,
  permissions: {
    id: "",
    access: [],
  },
} as PermissionsType;

export const PermissionsSlice = createSlice({
  name: "Permissions",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetPermissions: (state) => {
      state.permissions = {
        id: "",
        access: [],
      };
    },
  },
  extraReducers: {
    [getPermissions.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.permissions = action.payload;
      state.error = null;
    },
    [getPermissions.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPermissions.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [updatePermissions.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    [updatePermissions.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updatePermissions.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetPermissions } = PermissionsSlice.actions;

export default PermissionsSlice.reducer;
