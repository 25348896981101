import { Roles } from "./users";

export enum MenuActions {
  toggle = "toggle",
  logout = "logout",
}

export interface ISubmenuItem {
  label: string;
  link: string;
}

export interface IMenuItem {
  id: number;
  label: string;
  link: string;
  icon: string;
  action: MenuActions | null;
  child: ISubmenuItem[] | null;
  permissions?: Roles[];
}

export interface ISection {
  id: number;
  nav: IMenuItem[];
}

export interface ITabListItem {
  id: number;
  label: string;
  link: string;
}
