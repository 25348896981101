import { Button, Select } from "components/common";
import s from "./ControlPanel.module.scss";
import cn from "classnames";
import Loader from "components/common/Loader/Loader";
import { useState } from "react";
import { ORIGINATOR_OPTIONS_FOR_MODELS_WITH_ALL } from "const";

type Props = {
  onDownloadFile?: () => void;
  onOpenAssetList?: () => void;
  onOpenManageColumns?: () => void;
  onOpenFilter?: () => void;
  onFiltersRegroup?: (v: boolean) => void;
  shortFilterBtn?: boolean;
  onSelectPoolModel?: (val: string) => void;
  poolModelValue?: string;
  tableAction?: JSX.Element;
  dataIsFiltered: boolean;
  formInPanel?: JSX.Element;
  isLoading?: boolean;
};

export const ControlPanel = ({
  onDownloadFile,
  onOpenAssetList,
  onOpenManageColumns,
  onOpenFilter,
  onFiltersRegroup,
  shortFilterBtn = false,
  onSelectPoolModel,
  poolModelValue,
  tableAction,
  dataIsFiltered,
  formInPanel,
  isLoading,
}: Props) => {
  const [isFiltersInOneRow, setFiltersInOneRow] = useState(false);

  const updateFiltersGroup = (v: boolean) => {
    if (onFiltersRegroup) {
      onFiltersRegroup(v);
    }
    setFiltersInOneRow(v);
  };

  return (
    <div className={s.container}>
      {isLoading && <Loader color={"dark"} />}
      {onDownloadFile && (
        <div className={s.left}>
          <Button
            size="xs"
            title="Download"
            icon="download"
            onClick={onDownloadFile}
            disabled={isLoading}
          />
        </div>
      )}

      <div className={cn(s.right, Boolean(formInPanel) && s.filters)}>
        {formInPanel && (
          <div className={s.form}>
            {onFiltersRegroup && (
              <Button
                size="xs"
                icon={isFiltersInOneRow ? "minus" : "plus"}
                filled="white"
                className={s.openFormBtn}
                onClick={() => updateFiltersGroup(!isFiltersInOneRow)}
              />
            )}
            {formInPanel}
          </div>
        )}
        {onOpenAssetList && (
          <Button
            filled="grayDark"
            size="xs"
            title="Configure"
            icon="settings"
            onClick={onOpenAssetList}
          />
        )}
        {onOpenManageColumns && (
          <Button
            filled="grayDark"
            size="xs"
            title="Manage Columns"
            icon="tool"
            onClick={onOpenManageColumns}
          />
        )}
        {onOpenFilter && (
          <div className={s.filterBtn}>
            <Button
              filled="grayDark"
              size="xs"
              title={shortFilterBtn ? "" : "Filter"}
              icon="filter"
              onClick={onOpenFilter}
            />
            {dataIsFiltered && <span className={cn("marker", s.marker)} />}
          </div>
        )}
        {onSelectPoolModel && (
          <Select
            options={ORIGINATOR_OPTIONS_FOR_MODELS_WITH_ALL}
            value={poolModelValue}
            onSelect={onSelectPoolModel}
            placeholder="Select Originator&emsp;&emsp;&emsp;"
            size={"xs"}
            filled="grayDark"
            minWidth="min-250"
          />
        )}
        {tableAction && tableAction}
      </div>
    </div>
  );
};
