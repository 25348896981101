import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Pledge.module.scss";
import { useAppSelector, useAppDispatch } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import {
  DOWNLOAD_SMM_RESOLITION_RATES,
  ORIGINATOR_OPTIONS_BASE,
  PLEDGE_LINE_OPTIONS,
  SEASONING_MONTHS,
  columnsSmmResolutionRates,
} from "const";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { getList } from "store/actions/SmmResolutionRatesList";
import { downloadResourceFromURL, encodeDataToUrl } from "utils";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControls } from "components/modules";
import { usePrevious } from "hooks/usePrevious";

export const SmmResolutionRates = () => {
  const { valuesSelect, handleSelect, valuesSelectDirty, updateInitialSelect } =
    useFormSelectArr({
      listType: "All",
      originator: "All",
      seasoning: null,
    });

  const { listType, originator, seasoning } = valuesSelect;

  const [tableMode, setTableMode] = useState(false);

  const [isOpenAssetList, toggleAssetList] = useState(false);

  const { list, isLoading } = useAppSelector(
    (state) => state.smmResolutionRatesList
  );

  const dispatch = useAppDispatch();

  const fields = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => handleSelect("originator", value),
      value: originator,
      fieldName: "Select Originator",
    },
    select2: {
      options: PLEDGE_LINE_OPTIONS,
      onSelect: (value: string) => handleSelect("listType", value),
      value: listType,
      fieldName: "Select a Pledge Line",
    },
    select3: {
      options: SEASONING_MONTHS,
      onSelect: (value: string) => handleSelect("seasoning", value),
      value: seasoning,
      fieldName: "Seasoning Period in Months",
    },
  };

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
      isOpenAssetList && toggleAssetList(false);
    }
  }, [isLoading]);

  const getTableList = () => {
    if (!isLoading) {
      dispatch(
        getList({
          pledge_line: listType,
          model: originator.toLocaleLowerCase(),
          period: seasoning,
        })
      );

      updateInitialSelect();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      model: originator.toLocaleLowerCase(),
      period: seasoning,
    });

    downloadResourceFromURL(
      `Smm & Resolution Rates.xlsx`,
      `${DOWNLOAD_SMM_RESOLITION_RATES}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    getTableList();
  };

  return (
    <PageContent
      title="SMM and Resolution Rates"
      path="Pledge Collateral"
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fields}
            importButton={{
              label: isLoading ? "Processing..." : "Generate Data",
              onclick: handleClickImportBtn,
              disabled: tableMode
                ? isLoading || !valuesSelectDirty
                : isLoading || !seasoning,
            }}
            tableMode={tableMode}
            isOpenAssetList={isOpenAssetList}
            onCloseAssetList={() => toggleAssetList(false)}
          />
          {tableMode && (
            <TableControls
              columnsList={columnsSmmResolutionRates}
              list={list}
              defaultSortedColumn="month_end_date"
              onDownloadFile={onDownloadFile}
              onOpenAssetList={() => toggleAssetList(true)}
              withFilters
              isLoading={isLoading}
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
