import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlockSearchByContract } from "components/modules/FormBlock/FormBlockSearchByContract";
import { FormPreliminaryExit } from "components/modules/FormUpdateData/FormPreliminaryExit";
import { SuccessNotification } from "components/modules/UploadNotification/SuccessNotification";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useGoBackInUpdateForm } from "hooks/useGoBackInUpdateForm";
import { useEffect } from "react";
import { getPreliminaryExitData } from "store/actions/PreliminaryExitActions";
import { resetData, resetUpdated } from "store/reducers/PreliminaryExitSlice";
import { ListSkeleton } from "../../modules";

export const PreliminaryExit = () => {
  const { data, all_contract_status, isLoading, isUpdated } = useAppSelector(
    (state) => state.preliminaryExit
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  const { secondScreenMode, onGoBack } = useGoBackInUpdateForm(
    data.contract_id,
    resetData
  );

  const renderRightContent = () => {
    return (
      <SuccessNotification
        title="The Database"
        description="had been successfully updated!"
        onClose={() => dispatch(resetUpdated())}
      />
    );
  };

  return (
    <PageContent
      title="Preliminary Exit"
      path="Record Management"
      secondScreenMode={secondScreenMode}
      onGoBack={onGoBack}
    >
      <PageFormLayout rightContent={isUpdated ? renderRightContent() : null}>
        <>
          <FormBlockSearchByContract
            getDataAction={getPreliminaryExitData}
            contracts="preliminary_contracts"
            resetUpdated={resetUpdated}
            isLoading={isLoading}
          />
          {!isLoading ? (
              secondScreenMode && data.contract_id && (
                  <FormPreliminaryExit
                      data={data}
                      statuses={all_contract_status}
                      isLoading={isLoading}
                      isUpdated={isUpdated}
                  />
              )
          ) : (
              <ListSkeleton count={13}></ListSkeleton>
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
