import * as UserActions from './UserActions'
import * as ManageUsersActions from './ManageUsersActions'
import * as OfficialRunAssetList from './OfficialRunAssetList'
import * as HistoryActions from './HistoryActions'
import * as PledgeCollateralActions from './PledgeCollateralActions'
import * as ActualReturnsReportList from './ActualReturnsReportList'

export default {
    ...UserActions,
    ...ManageUsersActions,
    ...OfficialRunAssetList,
    ...HistoryActions,
    ...PledgeCollateralActions,
    ...ActualReturnsReportList,
}
