import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControls } from "components/modules";
import {
  DATE_FORMAT,
  DOWNLOAD_PAYOFFS_REPORT,
  ORIGINATOR_OPTIONS_BASE,
  STATUS_CONTRACT_OPTIONS,
  columnsPayoffsReport,
} from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useFormSelectDateArr } from "hooks/useFormSelectDateArr";
import { usePrevious } from "hooks/usePrevious";
import moment from "moment";
import { useEffect, useState } from "react";
import { getList } from "store/actions/PayoffsReportActions";
import { downloadResourceFromURL, encodeDataToUrl } from "utils";

export const PayoffsReport = () => {
  const { valuesSelect, handleSelect, updateInitialSelect } = useFormSelectArr({
    originator: "All",
    filingType: "All",
  });

  const { valuesDate, handleDateChange, updateInitialDate } =
    useFormSelectDateArr({
      startDate: new Date(moment("01-01-2024").format(DATE_FORMAT)),
      endDate: null,
    });

  const { originator, filingType } = valuesSelect;

  const { startDate, endDate } = valuesDate;

  const [tableMode, setTableMode] = useState(false);

  const { list, isLoading } = useAppSelector((state) => state.payoffReport);

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
    }
  }, [isLoading]);

  const fieldsInForm = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => handleSelect("originator", value),
      value: originator,
      fieldName: "Select Originator",
    },
    select2: {
      fieldName: "Filter by Payoff Status",
      options: STATUS_CONTRACT_OPTIONS,
      onSelect: (value: string) => handleSelect("filingType", value),
      value: filingType,
    },
    startDate: {
      startDate,
      setStartDate: (value: Date | undefined | null) =>
        handleDateChange("startDate", value),
      fieldName: "Choose Payoff Date",
      label: "From",
    },
    endDate: {
      endDate,
      setEndDate: (value: Date | undefined | null) =>
        handleDateChange("endDate", value),
      label: "To",
    },
  };

  const fieldsinPanel = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => onChangeSelectInPanel("originator", value),
      value: originator,
      fieldName: "Select Originator",
    },
    select2: {
      fieldName: "Filter by Payoff Status",
      options: STATUS_CONTRACT_OPTIONS,
      onSelect: (value: string) => onChangeSelectInPanel("filingType", value),
      value: filingType,
    },
    startDate: {
      startDate,
      setStartDate: (value: Date | undefined | null) =>
        onChangeDateInPanel("startDate", value),
      label: "Payoff Date From",
    },
    endDate: {
      endDate,
      setEndDate: (value: Date | undefined | null) =>
        onChangeDateInPanel("endDate", value),
      label: "Payoff Date To",
    },
  };

  const getTableList = ({
    originator,
    filingType,
    startDate,
    endDate,
  }: {
    originator: string;
    filingType: string;
    startDate: string;
    endDate: string;
  }) => {
    if (!isLoading) {
      dispatch(
        getList({
          model: originator.toLocaleLowerCase(),
          status: filingType.toLocaleLowerCase(),
          date_from: moment(startDate).format("YYYY-MM-DD"),
          date_to: moment(endDate).format("YYYY-MM-DD"),
        })
      );

      updateInitialSelect();
      updateInitialDate();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      model: originator.toLocaleLowerCase(),
      status: filingType.toLocaleLowerCase(),
      date_from: moment(startDate).format("YYYY-MM-DD"),
      date_to: moment(endDate).format("YYYY-MM-DD"),
    });

    downloadResourceFromURL(
      `Payoffs Report List.xlsx`,
      `${DOWNLOAD_PAYOFFS_REPORT}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    getTableList({ originator, filingType, startDate, endDate });
  };

  const onChangeSelectInPanel = (field: string, value: string) => {
    handleSelect(field, value);
    const data = {
      ...valuesSelect,
      ...valuesDate,
      [field]: value,
    };
    getTableList({ ...data });
  };

  const onChangeDateInPanel = (
    field: string,
    value: Date | undefined | null
  ) => {
    handleDateChange(field, value);
    const data = {
      ...valuesSelect,
      ...valuesDate,
      [field]: value,
    };
    getTableList({ ...data });
  };

  return (
    <PageContent
      title="Payoffs Report"
      path="Accounting"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fieldsInForm}
            importButton={{
              label: isLoading ? "Processing..." : "Generate Report",
              onclick: handleClickImportBtn,
              disabled: isLoading || !startDate || !endDate,
            }}
            tableMode={tableMode}
          />
          {tableMode && (
            <TableControls
              columnsList={columnsPayoffsReport}
              list={list}
              isLoading={isLoading}
              onDownloadFile={onDownloadFile}
              dynamicPinFirstCol
              formInPanel={
                <FormBlock
                  fields={fieldsinPanel}
                  tableMode
                  inControlPanel
                  sizeFields={"85"}
                />
              }
              searchColumns={["contract_id"]}
              withFilters
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
