import { createSlice } from "@reduxjs/toolkit";
import { HistoryActionsType } from "store/types/HistoryActions";
import { getHistory } from "../actions/HistoryActions";

const initialState = {
  isLoading: false,
  error: null,
  items: [],
  page: 0,
  pages: 0,
  size: 0,
  total: 0,
} as HistoryActionsType;

export const HistoryActionsSlice = createSlice({
  name: "actionsHistory",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearHistory: (state) => {
      state.items = [];
    },
  },
  extraReducers: {
    [getHistory.fulfilled.type]: (state, action) => {
      const prevList = [...state.items];
      state.isLoading = false;
      state.error = null;
      if (prevList[0]?.id === action.payload.items[0]?.id) {
        state.items = action.payload.items;
      } else state.items = [...prevList, ...action.payload.items];
      state.page = action.payload.page;
      state.pages = action.payload.pages;
      state.size = action.payload.size;
      state.total = action.payload.total;
    },
    [getHistory.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getHistory.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, clearHistory } = HistoryActionsSlice.actions;

export default HistoryActionsSlice.reducer;
