import { useState } from "react";
import { PledgeCollateralItem } from "store/types/PledgeCollateral";

export const useSelectRows = (
  list: PledgeCollateralItem[],
  selectedAmountAll: number,
  selectedCountAll: number,
  isReversed = false
) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);

  const changeAmount = (val: string, action: "add" | "remove") => {
    setSelectedAmount((total) => {
      const value =
        (list &&
          list.find(({ investment_id }) => investment_id === val)
            ?.investment_amount) ||
        0;

      return action === "add" ? total + value : total - value;
    });
  };

  const addAll = () => {
    setSelectedAmount(selectedAmountAll);
    setSelectedCount(selectedCountAll);
  };

  const removeAll = () => {
    setSelectedAmount(0);
    setSelectedCount(0);
  };

  const addOne = (val: string) => {
    changeAmount(val, "add");
    setSelectedCount((total) => {
      return total + 1;
    });
  };

  const removeOne = (val: string) => {
    changeAmount(val, "remove");
    setSelectedCount((total) => {
      return total - 1;
    });
  };

  const selectRow = (val: string) => {
    if (!selectedRows.includes("all") && val === "all") {
      setSelectedRows([val, ...list.map((it) => it.investment_id)]);
      isReversed ? removeAll() : addAll();
      return;
    }

    if (selectedRows.includes("all") && val === "all") {
      setSelectedRows([]);
      isReversed ? addAll() : removeAll();
      return;
    }

    if (selectedRows.includes(val)) {
      if (selectedRows.includes("all")) {
        setSelectedRows(
          selectedRows.filter((id) => id !== val && id !== "all")
        );
      } else {
        setSelectedRows(selectedRows.filter((id) => id !== val));
      }
      isReversed ? addOne(val) : removeOne(val);
      return;
    }

    if (list.length - selectedRows.length === 1 && val !== "all") {
      setSelectedRows([...selectedRows, val, "all"]);
      isReversed ? removeAll() : addAll();
      return;
    }

    setSelectedRows([...selectedRows, val]);
    isReversed ? removeOne(val) : addOne(val);
  };

  const reset = () => {
    setSelectedRows([]);
    setSelectedAmount(0);
    setSelectedCount(0);
  };

  return {
    selectRow,
    selectedRows,
    setSelectedRows,
    selectedAmount,
    selectedCount,
    reset,
  };
};
