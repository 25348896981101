import { configureStore, combineReducers } from "@reduxjs/toolkit";

import manageUsersReducer from "./reducers/ManageUsersSlice";
import userReducer from "./reducers/UserSlice";
import applicationSettings from "./reducers/ApplicationSettings";
import officialRunAssetList from "./reducers/OfficialRunAssetList";
import historyReducer from "./reducers/HistoryActionsSlice";
import pledgeCollateral from "./reducers/PledgeCollateral";
import actualReturnsReportList from "./reducers/ActualReturnsReportList";
import exitsVarianceList from "./reducers/ExitsVarianceList";
import imparedList from "./reducers/ImpairedList";
import smmResolutionRatesList from "./reducers/SmmResolutionRatesList";
import DashboardsActionsSlice from "./reducers/DashboardsActionsSlice";
import payoffDemand from "./reducers/PayoffDemandSlice";
import PayoffsReportSlice from "./reducers/PayoffsReportSlice";
import DeleteContractSlice from "./reducers/DeleteContractSlice";
import UpdateContractSlice from "./reducers/UpdateContractSlice";
import PreliminaryExitSlice from "./reducers/PreliminaryExitSlice";
import LookUpContractSlice from "./reducers/LookUpContractSlice";
import HPAContractSlice from "./reducers/HPAContractSlice";
import HPAMetroSlice from "./reducers/HPAMetroSlice";
import ContractsSlice from "./reducers/ContractsSlice";
import ForwardCashFlowsSlice from "./reducers/ForwardCashFlowsSlice";
import PermissionsSlice from "./reducers/PermissionsSlice";
import GrossMonthlyReturnSlice from "./reducers/GrossMonthlyReturnsSlice";

const appReducer = combineReducers({
  user: userReducer,
  manageUsers: manageUsersReducer,
  permissions: PermissionsSlice,
  settings: applicationSettings,
  officialRunAssetList,
  actionsHistory: historyReducer,
  pledgeCollateral,
  actualReturnsReportList,
  exitsVarianceList,
  imparedList,
  smmResolutionRatesList,
  actionsDashboards: DashboardsActionsSlice,
  payoffDemand,
  deleteContract: DeleteContractSlice,
  lookUpContract: LookUpContractSlice,
  updateContract: UpdateContractSlice,
  preliminaryExit: PreliminaryExitSlice,
  payoffReport: PayoffsReportSlice,
  hpaContract: HPAContractSlice,
  hpaMetro: HPAMetroSlice,
  contractIds: ContractsSlice,
  forwardCashFlows: ForwardCashFlowsSlice,
  grossMonthlyReturns: GrossMonthlyReturnSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "user/logout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
