import { Button, Icon, Input } from "components/common";
import s from "./Auth.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/reduxHooks";
import { emailValidator } from "utils";
import { AuthRoute, FORGOT_PASSWORD_API } from "const";
import { usePostQuery } from "../../../hooks/usePostQuery";

export const ForgotPassword = () => {
  const { isLoading, error } = useAppSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [errors, setErrors] = useState("");

  const [validationErrors, setValidationErrors] = useState("");

  const navigate = useNavigate();

  const handleFormChange = (value: string) => {
    if (validationErrors) setValidationErrors("");
    setEmail(value);
  };

  const { loading, postFormData, responseStatus, responseData } =
    usePostQuery(FORGOT_PASSWORD_API);

  useEffect(() => {
    if (responseStatus === 200) {
      setSent(true);
    }
  }, [responseStatus]);

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    const isEmailValid = emailValidator(email);
    if (!isEmailValid) {
      const requestData = new FormData();
      requestData.append("email", email);
      try {
        await postFormData(requestData);
        setErrors("");
      } catch (error) {
        setErrors(`${error}`);
      }
    } else {
      setValidationErrors(isEmailValid);
    }
  };

  return !sent ? (
    <div className={s.container}>
      <div className={s.heading}>
        <p className={s.h2}>Forgot Your Password?</p>
        <p className={s.h4}>Enter your email to restore your password</p>
      </div>
      <form className={s.form} noValidate>
        <Input
          placeholder="Email"
          value={email}
          type="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFormChange(e.target.value)
          }
          status={error || validationErrors ? "error" : "normal"}
          statusText={validationErrors}
          className={s.input}
          leftIcon="mail"
        />
        <Button
          size="large"
          wide="long"
          title="SUBMIT"
          onClick={(e: React.MouseEvent) => onSubmit(e)}
          type="submit"
          disabled={!email.length || isLoading}
          isLoading={isLoading}
          loader="Processing..."
          uppercase
        />
      </form>
      {errors && <div className={s.errors}>{errors}</div>}
      <Link className={s.backLink} to={AuthRoute.LOGIN}>
        <Icon icon="arrow-left" className={s.iconBack} />
        Back to Log In
      </Link>
    </div>
  ) : (
    <div className={s.container}>
      <div className={s.heading}>
        <p className={s.h2}>
          An email for restoring your password has been sent to your email
        </p>
        <p className={s.h4}>Please, check your mailbox</p>
      </div>
      <form className={s.form} noValidate>
        <Button
          size="large"
          wide="long"
          title="OK, THANKS"
          filled={"empty"}
          onClick={() => navigate(`${AuthRoute.LOGIN}`, { replace: true })}
          disabled={!sent}
          uppercase
        />
      </form>
      <div className={s.sendAgainContainer}>
        <span>Haven't received an email?</span>{" "}
        <a className={s.sendAgainLink} onClick={() => setSent(false)}>
          Send again
        </a>
      </div>
    </div>
  );
};
