import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CANCEL_PLEDGE_LIST,
  CREATE_PLEDGE,
  GET_DEBT_LIST,
  PLEDGE_LIST,
} from "const";
import {
  encodeDataToUrl,
  getQueryText,
  throwErrorIfRequestNotSucceed,
} from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const cookies = new Cookies();

const getPledgeList = createAsyncThunk(
  "pledge/list",
  async (_payload: { model: string; lender: string; fico: string; lotv: string; risk: string; multiple: string; lien: string;  }, thunkApi) => {
    try {
      const { model, lender, fico, lotv, risk, multiple, lien } = _payload;
      const queryText = getQueryText([
        { name: "model", value: model },
        { name: "lender", value: lender },
        { name: "fico", value: fico },
        { name: "lotv", value: lotv },
        { name: "risk", value: risk },
        { name: "multiple", value: multiple },
        { name: "lien", value: lien },
      ]);

      const response = await fetchWithAuth(`${PLEDGE_LIST}?${queryText}`, {
        method: "post",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();

      throwErrorIfRequestNotSucceed(response);

      return thunkApi.fulfillWithValue(parsed);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

const getPledgeDebt = createAsyncThunk(
  "pledge/debt",
  async (_payload: { lender: string; model: string; period: string; fico: string; lotv: string; risk: string; multiple: string; lien: string; }, thunkApi) => {
    try {
      const { lender, model, period, fico, lotv, risk, multiple, lien } = _payload;
      const queryText = getQueryText([
        { name: "lender", value: lender },
        { name: "model", value: model },
        { name: "period", value: period },
        { name: "fico", value: fico },
        { name: "lotv", value: lotv },
        { name: "risk", value: risk },
        { name: "multiple", value: multiple },
        { name: "lien", value: lien },
      ]);

      const response = await fetchWithAuth(`${GET_DEBT_LIST}?${queryText}`, {
        method: "post",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();

      throwErrorIfRequestNotSucceed(response);

      return thunkApi.fulfillWithValue(parsed);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

const createPledgeCreditLine = createAsyncThunk(
  "/pledge/debt/create",
  async (
    _payload: { list_ids: string; lender: string; pledged_date: string },
    thunkApi,
  ) => {
    try {
      const { list_ids } = _payload;
      const query = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(`${CREATE_PLEDGE}?${query}`, {
        method: "put",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });

      await response.json();

      // throwErrorIfRequestNotSucceed
      return thunkApi.fulfillWithValue(list_ids);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

const cancelPledgeList = createAsyncThunk(
  "/pledge/list/cancel",
  async (_payload: { list_ids: string; unpledge_date: string }, thunkApi) => {
    try {
      const { list_ids } = _payload;
      const query = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(`${CANCEL_PLEDGE_LIST}?${query}`, {
        method: "put",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });

      await response.json();

      // throwErrorIfRequestNotSucceed
      return thunkApi.fulfillWithValue(list_ids);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

export {
  getPledgeList,
  cancelPledgeList,
  getPledgeDebt,
  createPledgeCreditLine,
};
