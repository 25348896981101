import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_FORWARD_CASHFLOWS_LIST } from "const";
import { MonthRangedRateValues } from "../../components/pages/KasfValuation/ForwardCashFlows/AssumptionRange";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const getList = createAsyncThunk(
  "forwardCashflows",
  async (
    payload: {
      date_from: string;
      date_to: string;
      valuation_date: string;
      pool: string;
      originator: string;
      product_type: string;
      contract_term: string;
      contract_status: string;
      purchase_assumptions_acquisition_fee_type: string;
      purchase_assumptions_recording_fee_type: string;
      servicing_assumptions_servicing_fee_type: string;
      servicing_assumptions_disposition_fee_type: string;
      purchase_assumptions_acquisition_fee_value: string;
      purchase_assumptions_recording_fee_value: string;
      servicing_assumptions_servicing_fee_value: string;
      servicing_assumptions_disposition_fee_value: string;
      scenario_assumptions_default: string;
      scenario_assumptions_severity: string;
      deal_assumptions_cpr_range: MonthRangedRateValues;
      deal_assumptions_hpa_range: MonthRangedRateValues;
      page_size: number;
      page: number;
    },
    thunkApi
  ) => {
    payload.purchase_assumptions_acquisition_fee_value =
      payload.purchase_assumptions_acquisition_fee_value.replace("%", "");
    payload.purchase_assumptions_recording_fee_value =
      payload.purchase_assumptions_recording_fee_value.replace("%", "");
    payload.servicing_assumptions_servicing_fee_value =
      payload.servicing_assumptions_servicing_fee_value.replace("%", "");
    payload.servicing_assumptions_disposition_fee_value =
      payload.servicing_assumptions_disposition_fee_value.replace("%", "");
    payload.scenario_assumptions_default =
      payload.scenario_assumptions_default.replace("%", "");
    payload.scenario_assumptions_severity =
      payload.scenario_assumptions_severity.replace("%", "");
    payload.deal_assumptions_cpr_range.range1.rate =
      payload.deal_assumptions_cpr_range.range1.rate.replace("%", "");
    payload.deal_assumptions_cpr_range.range2.rate =
      payload.deal_assumptions_cpr_range.range2.rate.replace("%", "");
    payload.deal_assumptions_cpr_range.range3.rate =
      payload.deal_assumptions_cpr_range.range3.rate.replace("%", "");
    payload.deal_assumptions_cpr_range.range4.rate =
      payload.deal_assumptions_cpr_range.range4.rate.replace("%", "");
    payload.deal_assumptions_cpr_range.range5.rate =
      payload.deal_assumptions_cpr_range.range5.rate.replace("%", "");
    payload.deal_assumptions_hpa_range.range1.rate =
      payload.deal_assumptions_hpa_range.range1.rate.replace("%", "");
    payload.deal_assumptions_hpa_range.range2.rate =
      payload.deal_assumptions_hpa_range.range2.rate.replace("%", "");
    payload.deal_assumptions_hpa_range.range3.rate =
      payload.deal_assumptions_hpa_range.range3.rate.replace("%", "");
    payload.deal_assumptions_hpa_range.range4.rate =
      payload.deal_assumptions_hpa_range.range4.rate.replace("%", "");
    payload.deal_assumptions_hpa_range.range5.rate =
      payload.deal_assumptions_hpa_range.range5.rate.replace("%", "");

    try {
      const cookies = new Cookies();
      const response = await fetchWithAuth(
        `${GET_FORWARD_CASHFLOWS_LIST}?page=${payload.page}&page_size=${payload.page_size}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify(payload),
        }
      );

      const parsed = await response.json();

      if (response.status !== 200) {
        throw new Error(parsed.detail);
      }
      return thunkApi.fulfillWithValue(parsed);
    } catch (err) {
      // @ts-ignore
      return thunkApi.rejectWithValue(err?.message);
    }
  }
);

export { getList };
