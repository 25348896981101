import { HistoryActions, HistoryStatus } from "interfaces";

export const historyActions = [
  {
    action: HistoryActions.download,
    types: [
      "asset_list",
      "internal_valuation",
      "comparison_report",
      "valuation_comparison_report",
      "pledge_list",
      "pledge_credit_line",
      "roll_forwards",
    ],
  },
  {
    action: HistoryActions.upload,
    types: [
      "oon",
      "detail",
      "tape",
      "preliminary_exists",
      "3rd_party_valuation_report",
      "monthly_collections_report",
      "origination_fees_sweep",
      "fhfa_hpi_metro",
      "fhfa_hpi_non_metro",
      "fhfa_state",
    ],
  },
  {
    action: HistoryActions.submit,
    types: ["official_run_selection", "internal_valuation_regeneration"],
  },
];

export const actionTypesHistory = [
  { key: "1", label: HistoryActions.download, value: "Downloaded" },
  { key: "2", label: HistoryActions.upload, value: "Uploaded" },
  { key: "3", label: HistoryActions.submit, value: "Submited" },
];

export const statusHistory = [
  {
    key: "1",
    label: HistoryStatus.successful,
    value: HistoryStatus.successful,
  },
  {
    key: "2",
    label: HistoryStatus.failed,
    value: HistoryStatus.failed,
  },
];
