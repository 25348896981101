export const NOT_EMPTY_PATTERN = /[^\s]/;

export const requiredObj = {
  value: true,
  message: "Field should not be empty",
};

const validateAmount = (value: string) => {
  if (Number(value) === 0) {
    return "Amount should not be 0";
  }
  return true;
};

const validatePercent = (value: string) => {
  const val = value.replace("%", "");

  if (+val === 0) {
    return "Field should not be empty";
  }
  return true;
};

export const NOT_EMPTY_VALIDATION = {
  required: requiredObj,
  pattern: {
    value: NOT_EMPTY_PATTERN,
    message: "Field should not be empty",
  },
};

export const REQUIRED_AMOUNT = {
  required: requiredObj,
  validate: validateAmount,
};

export const REQUIRED_PERCENT = {
  required: requiredObj,
  validate: validatePercent,
};
