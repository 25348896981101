import { createSlice } from "@reduxjs/toolkit";
import {
  getPreliminaryExitData,
  updatePreliminaryExitData,
} from "store/actions/PreliminaryExitActions";
import { PreliminaryExitType } from "store/types/PreliminaryExitType";

const initialData = {
  contract_id: "",
  investment_amount: null,
  contract_status: "",
  date_funded: "",
  exit_date: "",
  gross_repayment: null,
  gross_irr: null,
  final_home_value: null,
};

const initialState = {
  isLoading: false,
  error: null,
  data: initialData,
  all_contract_status: [],
  isUpdated: false,
} as PreliminaryExitType;

export const PreliminaryExitSlice = createSlice({
  name: "preliminaryExit",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetData: (state) => {
      state.data = initialData;
    },
    resetUpdated: (state) => {
      state.isUpdated = false;
    },
  },
  extraReducers: {
    [getPreliminaryExitData.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.all_contract_status = action.payload.all_contract_status;
      state.error = null;
    },
    [getPreliminaryExitData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPreliminaryExitData.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [updatePreliminaryExitData.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.isUpdated = true;
      state.data = initialData;
      state.error = null;
    },
    [updatePreliminaryExitData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updatePreliminaryExitData.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetData, resetUpdated } =
  PreliminaryExitSlice.actions;

export default PreliminaryExitSlice.reducer;
