import { createSlice } from "@reduxjs/toolkit";
import {
  getDeleteContractData,
  deleteContract,
} from "store/actions/DeleteContractActions";
import { DeleteContractType } from "store/types/DeleteContractType";

const initialData = {
  contract_id: "",
  investment_amount: null,
  effective_date: "",
  funded_date: "",
  closing_date: "",
  option_amount: null,
  acquisition_fee: null,
  assignment_cost: null,
  tax_title_cost: null,
  purchase_price: null,
};

const initialState = {
  isLoading: false,
  error: null,
  data: initialData,
  isUpdated: false,
} as DeleteContractType;

export const DeleteContractSlice = createSlice({
  name: "deleteContract",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetData: (state) => {
      state.data = initialData;
    },
    resetUpdated: (state) => {
      state.isUpdated = false;
    },
  },
  extraReducers: {
    [getDeleteContractData.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    [getDeleteContractData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getDeleteContractData.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [deleteContract.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.isUpdated = true;
      state.data = initialData;
      state.error = null;
    },
    [deleteContract.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteContract.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetData, resetUpdated } =
  DeleteContractSlice.actions;

export default DeleteContractSlice.reducer;
