import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    historySectionVisible: true
}

export const ApplicationSettings = createSlice({
    name: 'applicationSettings',
    initialState,
    reducers: {
        historySectionVisibleChange: (state, action) => {
            state.historySectionVisible = action.payload.value
        }
    }
})

export const { historySectionVisibleChange } = ApplicationSettings.actions;

export default ApplicationSettings.reducer;
