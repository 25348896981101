import React from "react";
import s from "./FileBox.module.scss";
import { CloseBtn } from "components/common";

interface FileBoxProps {
  fileName: string;
  onRemove: (fileName: string) => void;
}

export const FileBox: React.FC<FileBoxProps> = ({ fileName, onRemove }) => {
  return (
    <div className={s.container}>
      <span className={s.title}>Uploaded file:</span>
      <span className={s.file}>{fileName}</span>
      <CloseBtn onClose={() => onRemove(fileName)} />
    </div>
  );
};
