import s from "./History.module.scss";
import cn from "classnames";
import { Button } from "components/common";
import { HistoryActions, HistoryStatus } from "interfaces";
import {downloadFile, encodeDataToUrl, formatList, isJson, toJson} from "utils";
import { useGetQuery } from "../../../hooks/useGetQuery";
import { S3_DOWNLOAD_FILE } from "../../../const";
import {
    TableCellExpandableListRenderer,
    TableCellExpandableMultiListRenderer
} from "../../modules/TableControls/TableCellExpandableListRenderer";

type Props = {
  myHistory?: boolean;
  user: string;
  date: string;
  time: string;
  status: string;
  type: string;
  action_type?: HistoryActions;
  file: string;
  fileId: number;
};

export const HistoryAsideItem = ({
  myHistory,
  user,
  date,
  time,
  status,
  type,
  action_type,
  file,
  fileId,
}: Props) => {
  const { get: getS3FileUrl } = useGetQuery(S3_DOWNLOAD_FILE);
    const handleDownload = async (file_id: string) => {

        const query = encodeDataToUrl({ _id: file_id });

        const response = await getS3FileUrl(query);

        downloadFile(file ?? file_id, response?.url);
    };

  const fileMayBeHistoryDescriptionList = formatList(file);
  const definitelyHistoryDescriptionList = fileMayBeHistoryDescriptionList != file;

  return (
    <div className={s.item}>
      <div className={s.itemHeader}>
        <span className={s.itemLeft}>
          {!myHistory && <span className={s.itemUser}>{user}</span>}
          <span className={s.itemDate}>{date}</span>
        </span>
        <span>{time}</span>
      </div>
      <div
        className={cn(
          s.itemStatusInfo,
          status === HistoryStatus.failed && s.alert
        )}
      >
        <span>{type}</span> <span>{status}:</span>
      </div>
      <div className={s.itemBottom}>
          {
              definitelyHistoryDescriptionList
                && Array.isArray(fileMayBeHistoryDescriptionList) && fileMayBeHistoryDescriptionList.every((list: any) => Array.isArray(list))
                  ? <TableCellExpandableMultiListRenderer multiList={fileMayBeHistoryDescriptionList as string[][]}/>
                  : definitelyHistoryDescriptionList && Array.isArray(fileMayBeHistoryDescriptionList)
                      ? <TableCellExpandableListRenderer list={fileMayBeHistoryDescriptionList as string[]}/>
                      : <span className={s.itemFile}>{file}</span>
          }
        {action_type === HistoryActions.download && (
          <Button
            size="xs"
            filled="gray"
            icon="download"
            disabled={status === HistoryStatus.failed || !fileId}
            onClick={() => handleDownload(fileId.toString())}
            className={s.downloadBtn}
          />
        )}
      </div>
    </div>
  );
};
