import { Input, Modal } from "components/common";
import { AuthRoute, CHANGE_USER_PASSWORD_API } from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { usePostQuery } from "hooks/usePostQuery";
import { ChangeEvent, useEffect, useState } from "react";
import { nonEmptyField, passwordsMatch } from "utils";
import s from "./Settings.module.scss";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../store/reducers/UserSlice";
import { Cookies } from "react-cookie";

type Props = { isOpen: boolean; onClose: () => void; afterSuccess: () => void };

type StateTypes = {
  currentPassword: {
    value: string;
    visible: boolean;
  };
  newPassword: {
    value: string;
    visible: boolean;
  };
  reNewPassword: {
    value: string;
    visible: boolean;
  };
};

export const ModalChangePassword = ({
  isOpen,
  onClose,
  afterSuccess,
}: Props) => {
  const defaultChangePasswordState = {
    currentPassword: {
      value: "",
      visible: false,
    },
    newPassword: {
      value: "",
      visible: false,
    },
    reNewPassword: {
      value: "",
      visible: false,
    },
  };

  const defaultValidationErrors = {
    currentPassword: "",
    newPassword: "",
    reNewPassword: "",
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);
  const { loading, postFormData, responseStatus } = usePostQuery(
    CHANGE_USER_PASSWORD_API
  );
  const [changePasswordValues, setChangePasswordValues] = useState<StateTypes>(
    defaultChangePasswordState
  );
  const [validationErrors, setValidationErrors] = useState(
    defaultValidationErrors
  );

  const { currentPassword, newPassword, reNewPassword } = changePasswordValues;

  useEffect(() => {
    if (responseStatus === 200) {
      handlePasswordModalClose();
      afterSuccess();
    }
  }, [responseStatus]);

  const handleChangePassword = (
    value: string,
    field: keyof StateTypes,
    error: string
  ) => {
    setChangePasswordValues((prev: StateTypes) => ({
      ...changePasswordValues,
      [field]: {
        value: value,
        visible: prev[field].visible,
      },
    }));
    setValidationErrors({
      ...validationErrors,
      [field]: error,
    });
  };

  const handlePasswordModalClose = () => {
    setChangePasswordValues(defaultChangePasswordState);
    onClose();
  };

  const setPasswordVisible = (field: keyof StateTypes) => {
    setChangePasswordValues((prev) => ({
      ...prev,
      [field]: {
        value: prev[field].value,
        visible: !prev[field].visible,
      },
    }));
  };

  const handleSubmit = async () => {
    const isCurrentPasswordValid = nonEmptyField(currentPassword.value);
    const isNewPasswordValid = nonEmptyField(newPassword.value);
    const doPasswordsMatch = passwordsMatch(
      newPassword.value,
      reNewPassword.value
    );
    if (!isCurrentPasswordValid && !isNewPasswordValid && !doPasswordsMatch) {
      const requestData = new FormData();
      user?.email && requestData.append("email", user?.email);
      requestData.append("password", newPassword?.value);
      requestData.append("current_password", currentPassword?.value);
      postFormData(requestData)
          .then(() => {
            dispatch(logout());
            const cookie = new Cookies();
            cookie.remove("token", { path: "/" });
            cookie.remove("refresh_token", { path: "/" });
            cookie.remove("email", { path: "/" });
            navigate(`${AuthRoute.RESET_PASSWORD_SUCCESS}`, { replace: true })
          })
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handlePasswordModalClose}
      title="Change Password"
      submitText="Submit"
      onSubmit={handleSubmit}
      isLoading={loading}
      loaderText="Submiting..."
    >
      <div className={s.changePassword}>
        <div className={s.item}>
          <Input
            value={currentPassword.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangePassword(
                e.target.value,
                "currentPassword",
                nonEmptyField(e.target.value)
              )
            }
            label="Your Current Password"
            placeholder="Enter password"
            type={currentPassword.visible ? "text" : "password"}
            onClickIcon={() => setPasswordVisible("currentPassword")}
            rightIcon={currentPassword.visible ? "eye-closed" : "eye"}
            status={validationErrors.currentPassword ? "error" : "normal"}
            statusText={validationErrors.currentPassword}
          />
        </div>
        <div className={s.item}>
          <Input
            value={newPassword.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangePassword(
                e.target.value,
                "newPassword",
                nonEmptyField(e.target.value)
              )
            }
            label="New Password"
            placeholder="Enter password"
            type={"password"}
            status={validationErrors.newPassword ? "error" : "normal"}
            statusText={validationErrors.newPassword}
          />
        </div>
        <div className={s.item}>
          <Input
            value={reNewPassword.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangePassword(
                e.target.value,
                "reNewPassword",
                passwordsMatch(newPassword.value, e.target.value)
              )
            }
            label="Confirm New Password"
            placeholder="Enter password"
            type={reNewPassword.visible ? "text" : "password"}
            onClickIcon={() => setPasswordVisible("reNewPassword")}
            rightIcon={reNewPassword.visible ? "eye-closed" : "eye"}
            status={validationErrors.reNewPassword ? "error" : "normal"}
            statusText={validationErrors.reNewPassword}
            iconWarning={false}
          />
        </div>
      </div>
    </Modal>
  );
};
