import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {GET_CONTRACT_ID_LIST } from "const";
import { encodeDataToUrl } from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const getContractsIdsList = createAsyncThunk(
  "contractIdsList",
  async (
    _payload: {
      model: string;
    },
    thunkApi
  ) => {
    try {
      const cookies = new Cookies();
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(`${GET_CONTRACT_ID_LIST}?${queryParams}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });

      const parsed = await response.json();

      if (response.status !== 200) {
        throw new Error(parsed.detail);
      }
      return thunkApi.fulfillWithValue(parsed);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getContractsIdsList };
