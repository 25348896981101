import isEqual from "lodash/isEqual";
import moment from "moment";
import { useState } from "react";

export const useFormSelectDateArr = (defaultValues: any) => {
  const [valuesDate, setDate] = useState(defaultValues);

  const [initialValues, setInitialValues] = useState(valuesDate);

  const [valuesDateDirty, setValuesDirty] = useState(false);

  const handleDateChange = (field: string, value?: Date | null) => {
    if (
      moment(initialValues[field]).format("YYYY-MM-DD") !==
      moment(value).format("YYYY-MM-DD")
    ) {
      setValuesDirty(true);
    } else if (
      isEqual(
        Object.entries(initialValues).filter((item) => item[0] !== field),
        Object.entries(valuesDate).filter((item) => item[0] !== field)
      )
    ) {
      setValuesDirty(false);
    }

    setDate((valuesDate: any) => ({
      ...valuesDate,
      [field]: value || new Date(),
    }));
  };

  const updateInitialDate = () => {
    setInitialValues(valuesDate);
    setValuesDirty(false);
  };

  const resetDate = () => {
    setDate(initialValues);
    setValuesDirty(false);
  };

  return {
    valuesDate,
    handleDateChange,
    valuesDateDirty,
    updateInitialDate,
    resetDate,
  };
};
