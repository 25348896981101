import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
} from "react";
import cn from "classnames";
import s from "./Input.module.scss";
import { Icon } from "components/common/Icon/Icon";

interface IProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  status?: "normal" | "error" | "warning";
  statusText?: string;
  leftIcon?: string;
  rightIcon?: string;
  onClickIcon?: () => void;
  label?: string;
  iconWarning?: boolean;
  leftSymbol?: string;
  amountFormat?: boolean;
  sizeInput?: "normal" | "xs";
  onChangeCustom?: (name: any, value: string) => void;
}

export const Input = forwardRef<any, IProps>(
  (
    {
      type = "text",
      status,
      statusText,
      leftIcon,
      rightIcon,
      onClickIcon,
      className,
      label,
      iconWarning = true,
      leftSymbol,
      amountFormat = false,
      sizeInput,
      onChange,
      onChangeCustom,
      name,
      ...rest
    },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
      onChangeCustom && onChangeCustom(name, e.target.value);
    };

    return (
      <>
        {label && <span className={s.labelSmall}>{label}</span>}
        <div className={cn(s.wrapper, className && [className])}>
          {leftSymbol && (
            <span className={cn(s.leftSymbol, sizeInput === "xs" && s.xs)}>
              {leftSymbol}
            </span>
          )}
          <input
            {...rest}
            ref={ref}
            onChange={handleChange}
            type={type}
            className={cn(
              s.input,
              leftIcon && s.withLeftIcon,
              rightIcon && s.withRightIcon,
              leftSymbol && s.withLeftSymbol,
              amountFormat && s.amount,
              sizeInput === "xs" && s.xs,
              status && status === "error" && s.error
            )}
          />
          {leftIcon && <Icon icon={leftIcon} className={s.leftIcon} />}
          {iconWarning && status === "error" && (
            <Icon icon="warning" className={s.rightIcon} />
          )}
          {rightIcon && (status !== "error" || !iconWarning) && (
            <Icon
              icon={rightIcon}
              className={cn(s.rightIcon, onClickIcon && s.withClickIcon)}
              onClick={onClickIcon}
            />
          )}
          {status === "error" && statusText && (
            <span className={s.errorText}>{statusText}</span>
          )}
        </div>
      </>
    );
  }
);
