import { ISelectedRowInMonths } from "interfaces";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { getYearMonthPoolId } from "utils";

export const useSelectRowInMonths = (idRowSelector: string) => {
  const [selectedRows, setSelectedRows] = useState<ISelectedRowInMonths[]>([]);
  const [selectedRowsInitial, setSelectedRowsInitial] = useState<
    ISelectedRowInMonths[]
  >([]);

  const [changedRows, setChanged] = useState<ISelectedRowInMonths[]>([]);

  const addInitialSelectedRows = (list: any[]) => {
    const initial = list
      .map(({ selected_asset_list }) => ({
        yearMonth: selected_asset_list
          ? getYearMonthPoolId(selected_asset_list?.year, selected_asset_list?.month, selected_asset_list?.originator)
          : 0,
        id: selected_asset_list && selected_asset_list[idRowSelector],
      }))
      .filter(({ id }) => id);

    setSelectedRowsInitial(initial);
    setSelectedRows(initial);
    setChanged([]);
  };

  const clearAllSelectedRows = () => {
    setSelectedRows([]);
    setSelectedRowsInitial([]);
  };

  const selectRow = (yearMonthIndex: number, id: number) => {
    setSelectedRows((selectedRows) => {
      return [...selectedRows, { yearMonth: yearMonthIndex, id }];
    });
  };

  const changeRow = (yearMonthIndex: number, id: number) => {
    setSelectedRows((selectedRows) => {
      return [
        ...selectedRows.filter(({ yearMonth }) => yearMonth !== yearMonthIndex),
        { yearMonth: yearMonthIndex, id },
      ];
    });
  };

  const onSelectRow = (
    id: number,
    choosenAsset: number | null,
    isRowSelected: boolean,
    yearMonthIndex: number,
  ) => {
    if (choosenAsset && !isRowSelected) {
      changeRow(yearMonthIndex, id);
    } else if (!choosenAsset) {
      return selectRow(yearMonthIndex, id);
    } else if (choosenAsset && isRowSelected) { return; }
  };

  useEffect(() => {
    const arr = selectedRows;
    const sortedSelectedRows = arr.sort(
      (a: ISelectedRowInMonths, b: ISelectedRowInMonths) =>
        a.yearMonth - b.yearMonth,
    );

    if (!isEqual(sortedSelectedRows, selectedRowsInitial)) {
      setChanged(
        sortedSelectedRows.filter(
          ({ id }) => !selectedRowsInitial.find((item) => item.id === id),
        ),
      );
    } else { setChanged([]); }
  }, [selectedRows]);

  return {
    selectedRows,
    addInitialSelectedRows,
    clearAllSelectedRows,
    onSelectRow,
    changedRows,
  };
};
