import { useState } from "react";
import { Button } from "../../common";
import s from "./TableCellExpandableListRenderer.module.scss";
import cn from "classnames";

type TableCellExpandableListRendererProps = {
    list: string[];
};

export const TableCellExpandableListRenderer = ({ list }: TableCellExpandableListRendererProps) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className={s.cellListContainer}>
            <div className={cn(s.cellList, expanded ? "" : s.collapsed)}>
                {list.map(item => <div key={item} className={s.cellListItem}>{item}</div>)}
            </div>
            <Button
                size="xs"
                icon={expanded ? "minus" : "plus"}
                filled="gray"
                onClick={() => {
                    setExpanded(!expanded);
                }}
            />
        </div>
    );
};

type TableCellExpandableMultiListRendererProps = {
    multiList: string[][];
};
export const TableCellExpandableMultiListRenderer = ({ multiList }: TableCellExpandableMultiListRendererProps) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className={s.cellListContainer}>
            {multiList.map(list => <div className={cn(s.cellList, expanded ? "" : s.collapsed)}>
                {list.map(item => <div key={item} className={s.cellListItem} dangerouslySetInnerHTML={{ __html: item }}></div>)}
            </div>)}
            <Button
                size="xs"
                icon={expanded ? "minus" : "plus"}
                filled="gray"
                onClick={() => {
                    setExpanded(!expanded);
                }}
            />
        </div>
    );
};
