import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./NotFound.module.scss";

export const NotFound = () => {
  return (
    <PageContent>
      <>404</>
    </PageContent>
  );
};
