import { useState } from "react";
import { checkDirty, getValueChangeInputAmount } from "utils";

export const useFormInputAmountArr = (defaultValue: any) => {
  const [valuesInputAmount, setValue] = useState(defaultValue);

  const [initialValues, setInitialValues] = useState(valuesInputAmount);

  const [valuesInputAmountDirty, setValuesDirty] = useState(false);

  const handleChangeInputAmount = (field: string, value: string) => {
    const isDirty = checkDirty(initialValues, valuesInputAmount, field, value);
    isDirty !== undefined && setValuesDirty(isDirty);

    setValue((valuesInputAmount: any) => ({
      ...valuesInputAmount,
      [field]: getValueChangeInputAmount(value),
    }));
  };

  const updateInitialInputAmount = () => {
    setInitialValues(valuesInputAmount);
    setValuesDirty(false);
  };

  const resetInputAmount = () => {
    setValue(initialValues);
    setValuesDirty(false);
  };

  return {
    valuesInputAmount,
    handleChangeInputAmount,
    valuesInputAmountDirty,
    updateInitialInputAmount,
    resetInputAmount,
  };
};
