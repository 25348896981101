import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_TABLEAU_TOKEN } from "const";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const cookies = new Cookies();

const getTableauToken = createAsyncThunk(
  "dashboards",
  async (_payload: {}, thunkApi) => {
    try {
      const response = await fetchWithAuth(`${GET_TABLEAU_TOKEN}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();

      return thunkApi.fulfillWithValue({ ...parsed });
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getTableauToken };
