export enum HistoryActions {
  download = "Download",
  upload = "Upload",
  submit = "Submit",
}

export enum HistoryStatus {
  successful = "Successful",
  failed = "Failed",
}

export enum HistoryAsideTabs {
  my = "My History",
  all = "All History",
}
