import React from "react";
import s from "./FormBlock.module.scss";

import { FormBlockProps, FormBlockSelect } from "./types";
import { FileBox } from "components/modules";
import {
  Button,
  CustomDatePicker,
  CustomMonthPicker,
  Input,
  Select,
  UploadFile,
} from "components/common";
import cn from "classnames";
import { ModalAside } from "components/common/ModalAside/ModalAside";
import { SelectReact } from "components/common/FormElements/SelectReact/SelectReact";

type Placement = "main" | "aside" | "controlPanel";

export const FormBlock: React.FC<FormBlockProps> = ({
  fields,
  importButton,
  tableMode,
  isOpenAssetList,
  onCloseAssetList,
  inControlPanel = false,
  isFiltersMultirow = false,
  labelInOneRow,
  sizeFields,
}) => {
  const {
    select1,
    select2,
    select3,
    select4,
    select5,
    select6,
    select7,
    select8,
    file,
    selectFiles,
    pointInputFirst1,
    pointInputSecond1,
    pointInputFirst2,
    pointInputSecond2,
    pointInputFirst3,
    pointInputSecond3,
    percent,
    amount,
    startDate,
    endDate,
    valuationDate,
    monthCalendar,
    textArea,
  } = fields;

  const renderSelect = (select: FormBlockSelect) => (
    <div
      className={cn(
        s.item,
        select.oneInRow && s.itemOnlyOne,
        labelInOneRow && s.withLabel,
        select.hidden && s.hidden
      )}
    >
      {select.fieldName && (
        <span className={cn(s.label, s.dates)}>
          {select.fieldName || "Select List"}
        </span>
      )}
      {select.label && <span className={s.labelSmall}>{select.label}</span>}

      {select?.isSearchable ? (
        <SelectReact
          name={select.name || ""}
          options={select.optionsString || []}
          onSelect={select.onSelect}
          value={select.value || ""}
          disabled={select.disabled}
          isSearchable
          isClearable
        />
      ) : (
        <Select
          options={select.options || []}
          onSelect={select.onSelect}
          placeholder="Select"
          value={select.value}
          size={inControlPanel ? "xs" : "normal"}
          disabled={select.disabled}
        />
      )}
    </div>
  );

  const renderFormBlock = (placement: Placement) => (
    <>
      {select1 && renderSelect(select1)}
      {select2 && renderSelect(select2)}
      {select3 && renderSelect(select3)}
      {select4 && renderSelect(select4)}
      {select5 && renderSelect(select5)}
      {select6 && renderSelect(select6)}
      {select7 && renderSelect(select7)}
      {select8 && renderSelect(select8)}

      {file && (
        <div className={cn(s.item, file.oneInRow && s.itemOnlyOne)}>
          <span className={s.label}>Select File</span>
          <UploadFile
            file={file.file}
            setFile={(item: File) => item && file.setFile(item)}
          />
        </div>
      )}

      {selectFiles &&
        selectFiles.map(({ label, fileList, setFileList, onRemove }) => {
          return (
            <React.Fragment key={label}>
              <div className={cn(s.item, s.itemOnlyOne)}>
                <span className={s.label}>{label}</span>
                <UploadFile
                  file={fileList}
                  setFile={(file: File) => setFileList(file)}
                />
              </div>
              {fileList ? (
                <div className={s.itemFile}>
                  <FileBox
                    key={fileList.name + fileList.size}
                    fileName={fileList.name}
                    onRemove={onRemove}
                  />
                </div>
              ) : null}
            </React.Fragment>
          );
        })}

      {file?.file ? (
        <div className={s.itemFile}>
          <FileBox
            key={file?.file.name + file?.file.size}
            fileName={file?.file.name}
            onRemove={file.onRemove}
          />
        </div>
      ) : null}

      {pointInputFirst1 && pointInputSecond1 && (
        <div className={cn(s.item, pointInputFirst1.oneInRow && s.itemOnlyOne)}>
          <span className={s.label}>{pointInputFirst1.fieldName}</span>
          <div className={s.subItems}>
            <div className={s.subItem}>
              <span className={s.labelSmall}>{pointInputFirst1.label}</span>
              <Input
                value={pointInputFirst1.value}
                onChange={pointInputFirst1.onChange}
                onKeyDown={pointInputFirst1.onKeyDown}
              />
            </div>
            <div className={s.subItem}>
              <span className={s.labelSmall}>{pointInputSecond1.label}</span>
              <Input
                value={pointInputSecond1.value}
                onChange={pointInputSecond1.onChange}
                onKeyDown={pointInputSecond1.onKeyDown}
              />
            </div>
          </div>
        </div>
      )}

      {pointInputFirst2 && pointInputSecond2 && (
        <div className={cn(s.item, pointInputFirst2.oneInRow && s.itemOnlyOne)}>
          <span className={s.label}>{pointInputFirst2.fieldName}</span>
          <div className={s.subItems}>
            <div className={s.subItem}>
              <span className={s.labelSmall}>{pointInputFirst2.label}</span>
              <Input
                value={pointInputFirst2.value}
                onChange={pointInputFirst2.onChange}
                onKeyDown={pointInputFirst2.onKeyDown}
              />
            </div>
            <div className={s.subItem}>
              <span className={s.labelSmall}>{pointInputSecond2.label}</span>
              <Input
                value={pointInputSecond2.value}
                onChange={pointInputSecond2.onChange}
                onKeyDown={pointInputSecond2.onKeyDown}
              />
            </div>
          </div>
        </div>
      )}

      {pointInputFirst3 && pointInputSecond3 && (
        <div className={cn(s.item, pointInputFirst3.oneInRow && s.itemOnlyOne)}>
          <span className={s.label}>{pointInputFirst3.fieldName}</span>
          <div className={s.subItems}>
            <div className={s.subItem}>
              <span className={s.labelSmall}>{pointInputFirst3.label}</span>
              <Input
                value={pointInputFirst3.value}
                onChange={pointInputFirst3.onChange}
                onKeyDown={pointInputFirst3.onKeyDown}
              />
            </div>
            <div className={s.subItem}>
              <span className={s.labelSmall}>{pointInputSecond3.label}</span>
              <Input
                value={pointInputSecond3.value}
                onChange={pointInputSecond3.onChange}
                onKeyDown={pointInputSecond3.onKeyDown}
              />
            </div>
          </div>
        </div>
      )}

      {percent && (
        <div className={cn(s.item, percent.oneInRow && s.itemOnlyOne)}>
          <span className={s.label}>{percent.fieldName}</span>
          <Input
            value={percent.value}
            onChange={percent.onChange}
            onKeyDown={percent.onKeyDown}
            sizeInput={inControlPanel ? "xs" : "normal"}
          />
        </div>
      )}

      {amount && (
        <div className={cn(s.item, amount.oneInRow && s.itemOnlyOne)}>
          <span className={s.label}>{amount.fieldName}</span>
          <Input
            value={amount.value}
            onChange={amount.onChange}
            leftSymbol="$"
            sizeInput={inControlPanel ? "xs" : "normal"}
            amountFormat={true}
          />
        </div>
      )}

      {startDate && (
        <div className={s.item}>
          <span className={cn(s.label, s.dates)}>{startDate.fieldName}</span>
          {startDate.label && (
            <span className={s.labelSmall}>{startDate.label}</span>
          )}
          <CustomDatePicker
            startDate={startDate.startDate}
            setStartDate={startDate.setStartDate}
            placement={placement === "main" ? "right" : "bottom"}
            sizeInput={inControlPanel ? "xs" : "normal"}
          />
        </div>
      )}

      {endDate && (
        <div className={s.item}>
          {endDate.label && (
            <span className={s.labelSmall}>{endDate.label}</span>
          )}
          <CustomDatePicker
            startDate={endDate.endDate}
            setStartDate={endDate.setEndDate}
            placement={placement === "main" ? "right" : "bottom"}
            sizeInput={inControlPanel ? "xs" : "normal"}
          />
        </div>
      )}

      {valuationDate && (
        <div className={s.item}>
          <span className={cn(s.label, s.dates)}>{valuationDate.fieldName}</span>
          {valuationDate.label && (
            <span className={s.labelSmall}>{valuationDate.label}</span>
          )}
          <CustomDatePicker
            startDate={valuationDate.valuationDate}
            setStartDate={valuationDate.setValuationDate}
            placement={placement === "main" ? "right" : "bottom"}
            sizeInput={inControlPanel ? "xs" : "normal"}
          />
        </div>
      )}

      {monthCalendar && (
        <div className={cn(s.item, monthCalendar.oneInRow && s.itemOnlyOne)}>
          <span className={s.label}>
            {monthCalendar.fieldName || "Select Month"}
          </span>
          <CustomMonthPicker
            startDate={monthCalendar.startDate}
            setStartDate={monthCalendar.setStartDate}
            placement={placement === "main" ? "right" : "bottom"}
          />
        </div>
      )}

      {textArea && (
          <div className={cn(s.item, textArea.oneInRow && s.itemOnlyOne)}>
            <span className={s.label}>{textArea.fieldName}</span>
            <textarea
                className={s.textareaItem}
                value={textArea.value}
                placeholder={textArea.placeholder}
                cols={textArea.cols}
                rows={textArea.rows}
                onChange={textArea.onChange}
            />
          </div>
      )}
    </>
  );

  const getImportBtnTitle = () => {
    const title = importButton?.label
      ? importButton?.label
      : importButton?.icon
      ? ""
      : "IMPORT";

    return title.toUpperCase();
  };

  const renderBottomBtn = (placement?: Placement) => (
    <>
      {importButton && (
        <div
          className={cn(
            placement === "main" ? s.item : "",
            labelInOneRow && s.labelInOneRowButton
          )}
        >
          <Button
            title={getImportBtnTitle()}
            onClick={() => !importButton.disabled && importButton.onclick?.()}
            disabled={importButton.disabled || importButton.loading}
            isLoading={importButton.loading}
            loader={importButton.loadingText}
            wide={placement === "controlPanel" ? "normal" : "long"}
            style={{ width: importButton.width || "" }}
            icon={importButton.icon || undefined}
            uppercase={true}
            size={placement === "controlPanel" ? "xs" : "normal"}
          />
        </div>
      )}
    </>
  );

  return (
    <>
      {inControlPanel ? (
        <div
          className={cn(
            s.wrapperCPanel,
            isFiltersMultirow ? s.wrapperCPanelMultiRow : s.wrapperCPanelOneRow,
            sizeFields && s[`size${sizeFields}`]
          )}
        >
          <div className={s.fieldsList}>
            {renderFormBlock("controlPanel")}
            {renderBottomBtn("controlPanel")}
          </div>
        </div>
      ) : tableMode ? (
        <ModalAside
          isOpen={isOpenAssetList || false}
          onClose={onCloseAssetList || (() => {})}
          title="Settings"
          bottomBtn={renderBottomBtn("aside")}
        >
          <div className={s.wrapperAside}>{renderFormBlock("aside")}</div>
        </ModalAside>
      ) : (
        <div className={s.wrapper}>
          {renderFormBlock("main")}
          {renderBottomBtn("main")}
        </div>
      )}
    </>
  );
};
