import React from "react";

import cn from "classnames";

import s from "./Form.module.scss";
import { Icon } from "../Icon/Icon";
import { displayError } from "utils";

type Props = {
  children: JSX.Element;
  label?: string;
  className?: string;
  error?: any;
  withErrorIcon?: boolean;
};

export const FieldWrapper = ({
  children,
  label,
  className,
  error,
  withErrorIcon = true,
}: Props) => {
  return (
    <div
      className={cn(
        s.field,
        className && className,
        error?.hasOwnProperty("type") && cn(s.error, "error"),
        "field"
      )}
    >
      {label && <span className={s.label}>{label}</span>}
      {children}
      {error && <div className={s.errorText}>{displayError(error)}</div>}
      {withErrorIcon && error && (
        <Icon icon="attention" className={s.attention} />
      )}
    </div>
  );
};
