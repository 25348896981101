import { navigation } from "const/navigation";
import s from "./NavMenu.module.scss";
import { Icon } from "components/common/Icon/Icon";
import { MenuActions, Roles } from "interfaces";
import { AccordionItem } from "components/common/Accordion/AccordionItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import { Submenu } from "./Submenu";
import { useState } from "react";
import { Modal } from "components/common";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { logout } from "store/reducers/UserSlice";
import { Cookies } from "react-cookie";
import { AuthRoute } from "const";

type Props = {
  isNarrow: boolean;
};

export const NavigationMenu = ({ isNarrow }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);

  const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);

  const onClickLogout = () => {
    setIsOpenModalLogout(true);
  };

  const onLogout = () => {
    dispatch(logout());
    const cookie = new Cookies();
    cookie.remove("token", { path: "/" });
    cookie.remove("refresh_token", { path: "/" });
    cookie.remove("email", { path: "/" });
    navigate(AuthRoute.LOGIN);
  };

  const renderMenuItemAccordion = (
    isActive: boolean,
    label: string,
    icon?: string
  ) => {
    return (
      <>
        {icon && <Icon icon={icon} className={s.icon} />}
        <span className={cn(s.linkText, isActive && s.active)}>{label}</span>
      </>
    );
  };

  const renderMenuItemWithLink = (
    label: string,
    link: string,
    icon?: string,
    permissions?: Roles[]
  ) => {
    const enabled = permissions?.length
      ? user?.role && permissions.includes(user?.role)
      : user?.access?.find(({ functional_name }) => functional_name === label)
          ?.access;

    if (!enabled) return null;

    return (
      <Link
        className={cn(s.link, pathname.includes(`/${link}`) && s.active)}
        to={link}
      >
        {icon && <Icon icon={icon} className={s.icon} />}
        <span className={cn(s.linkText)}>{label}</span>
      </Link>
    );
  };

  const renderMenuItemLogout = (label: string, icon?: string) => {
    return (
      <div className={s.link} onClick={() => onClickLogout()}>
        {icon && <Icon icon={icon} className={s.icon} />}
        <span className={s.linkText}>{label}</span>
      </div>
    );
  };

  return (
    <>
      {user?.access ? (
        <nav>
          <ul className={cn(s.sections, isNarrow && s.narrow)}>
            {navigation.map((section) => {
              return (
                <li key={section.id} className={s.section}>
                  <ul className={s.sectionMenu}>
                    {section.nav.map(
                      ({
                        id,
                        label,
                        link,
                        icon,
                        action,
                        child,
                        permissions,
                      }) => {
                        const childEnabled = child?.filter(({ label }) => {
                          return user?.access?.find(
                            ({ functional_name }) => functional_name === label
                          )?.access;
                        });
                        return (
                          <li key={id} className={s.menuItem}>
                            {action === MenuActions.toggle &&
                            childEnabled?.length ? (
                              <AccordionItem
                                title={renderMenuItemAccordion(
                                  Boolean(
                                    childEnabled?.find(
                                      ({ link }) => link === pathname
                                    )
                                  ),
                                  label,
                                  icon
                                )}
                                content={
                                  <Submenu
                                    child={childEnabled}
                                    pathname={pathname}
                                  />
                                }
                                titleClass="navlink"
                                expandClass="navExpandIcon"
                                activeClass={
                                  childEnabled.find(
                                    ({ link }) => link === pathname
                                  )
                                    ? "navActive"
                                    : null
                                }
                                contentClass="navContent"
                              />
                            ) : action === MenuActions.logout ? (
                              renderMenuItemLogout(label, icon)
                            ) : link ? (
                              renderMenuItemWithLink(
                                label,
                                link,
                                icon,
                                permissions
                              )
                            ) : null}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </li>
              );
            })}
          </ul>
        </nav>
      ) : null}
      {isOpenModalLogout && (
        <Modal
          isOpen={isOpenModalLogout}
          onClose={() => setIsOpenModalLogout(false)}
          title="Are you sure you want to log out?"
          subtitle="You will need a password to login again"
          submitText="Yes, Log out"
          cancelText="No, Stay"
          onSubmit={onLogout}
        />
      )}
    </>
  );
};
