import { IColumnData, SortType, Sorting } from "interfaces";
import { useState } from "react";

export const useSorting = (
  columnsList: IColumnData[],
  setSortedData: (val: any[]) => void,
  defaultSort: Sorting,
  defaultSortedColumn?: string
) => {
  const initialSortingOrder = Object.fromEntries(
    columnsList.map(({ name }) => [name, defaultSort])
  );

  const [sortingOrder, setSortingOrder] = useState(initialSortingOrder);

  const [activeSorting, setActiveSorting] = useState(defaultSortedColumn || "");

  // useEffect(() => {
  //   if (
  //     isFirstSort &&
  //     sortedData.length &&
  //     defaultSortedColumn &&
  //     !defaultSorted
  //   ) {

  //     sortColumn(
  //       defaultSortedColumn,
  //       columnsList.find(({ name }) => name === defaultSortedColumn)
  //         ?.sortType || SortType.alphabet
  //     );
  //     setFirstSorted && setFirstSorted(false);
  //   }
  // }, [sortedData]);

  const sortColumn = (sortedData: any[], fieldName: string, type: SortType) => {
    const sort = (sorted = false) => {
      const newOrder = sorted
        ? [...sortedData].reverse()
        : [...sortedData].sort((a, b) => {
            const asc =
              type === SortType.num
                ? a[fieldName] - b[fieldName]
                : `${a[fieldName]}` > `${b[fieldName]}`
                ? 1
                : -1;

            const dsc =
              type === SortType.num
                ? b[fieldName] - a[fieldName]
                : `${b[fieldName]}` > `${a[fieldName]}`
                ? 1
                : -1;

            return sortingOrder[fieldName] === Sorting.asc ? asc : dsc;
          });

      setSortedData(newOrder);

      const newSortingOrder = sorted
        ? sortingOrder[fieldName] === Sorting.asc
          ? Sorting.dsc
          : Sorting.asc
        : sortingOrder[fieldName] === Sorting.asc
        ? Sorting.asc
        : Sorting.dsc;

      setSortingOrder({
        ...sortingOrder,
        [fieldName]: newSortingOrder,
      });
    };

    if (fieldName === activeSorting) {
      sort(true);
    } else {
      setActiveSorting(fieldName);
      sort();
    }
  };

  return { sortColumn, sortingOrder, activeSorting, setActiveSorting };
};
