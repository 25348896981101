import { Button, Select } from "components/common";
import s from "./FormBlock.module.scss";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useAppSelector, useAppDispatch } from "hooks/reduxHooks";
import {
  getSearchValues,
} from "store/actions/PayoffDemandActions";
import { useEffect, useState } from "react";
import { SEARCH_BY_OPTIONS } from "const";
import { SelectReact } from "components/common/FormElements/SelectReact/SelectReact";
import { resetUpdated } from "store/reducers/PayoffDemandSlice";

type Props = {
  getDataAction: any;
};

export const FormBlockSearchWithSelect = ({getDataAction}: Props) => {
  const { searchData, isLoading } = useAppSelector(
    (state) => state.payoffDemand,
  );

  const dispatch = useAppDispatch();

  const { valuesSelect, handleSelect, updateInitialSelect, resetOneSelect } =
    useFormSelectArr({
      searchBy: "",
      searchedData: "",
    });

  const { searchBy, searchedData } = valuesSelect;

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(getSearchValues({}));
  }, []);

  useEffect(() => {
    resetOneSelect("searchedData");
  }, [searchBy]);

  const getKey = (searchBy: string) => {
    return SEARCH_BY_OPTIONS.find((item) => item.value === searchBy)?.key;
  };

  const replaceKey = (key: string) => {
    switch (key) {
      case "contract_ids":
        return "contract_id";
      case "service_numbers":
        return "service_number";
      case "addresses":
        return "address";
    }
  };

  const getCurrentOptions = (searchBy: string) => {
    const key = getKey(searchBy);
    const rowsCount = 100;
    const ret =  key
        ? (searchValue
            ? searchData[key as keyof typeof searchData]
                .filter(v => `${v}`.toLowerCase().includes(searchValue.toLowerCase()))
                .slice(0, rowsCount)
            : searchData[key as keyof typeof searchData]
                .slice(0, rowsCount))
        : [];
    return searchedData? [searchedData, ...ret] : ret;
  };

  const onSearch = () => {
    if (!isLoading) {
      dispatch(resetUpdated());
      const key = replaceKey(getKey(searchBy) || "");

      dispatch(
          getDataAction({
          field: key || "",
          value: searchedData,
        }),
      );

      updateInitialSelect();
      resetOneSelect("searchedData");
    }
  };

  return (
    <div className={s.wrapSearch}>
      <span className={s.label}>Search by</span>
      <div className={s.searchRow}>
        <Select
          options={SEARCH_BY_OPTIONS}
          onSelect={(value: string) => handleSelect("searchBy", value)}
          placeholder="Select"
          value={searchBy}
        />
        <SelectReact
          name="searchedData"
          options={getCurrentOptions(searchBy)}
          value={searchedData}
          onSelect={(value: string) => handleSelect("searchedData", value)}
          onInputChange={(value: string) => setSearchValue(value)}
          disabled={!searchBy}
          isSearchable={true}
          isClearable={true}
        />
        <Button
          onClick={onSearch}
          title="Search"
          isLoading={isLoading}
          loader="..."
          disabled={!searchedData || !searchBy}
        />
      </div>
    </div>
  );
};
