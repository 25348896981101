import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlockSearchWithSelect } from "components/modules/FormBlock/FormBlockSearchWithSelect";
import { FormPayoffDemand } from "components/modules/FormUpdateData/FormPayoffDemand";
import { SuccessNotification } from "components/modules/UploadNotification/SuccessNotification";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useGoBackInUpdateForm } from "hooks/useGoBackInUpdateForm";
import { useEffect } from "react";
import { resetData, resetUpdated } from "store/reducers/PayoffDemandSlice";
import { getPayoffDemandData } from "../../../store/actions/PayoffDemandActions";
import {FieldsInfoBlocks} from "../../modules/FieldsInfoBlocks/FieldsInfoBlocks";
import {fieldsLookUpContract} from "../../../const/fieldsInfoBlocks";
import {ListSkeleton} from "../../modules";

export const PayoffDemand = () => {
  const { data, propertyStatuses, isLoading, isUpdated } = useAppSelector(
    (state) => state.payoffDemand
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  const { secondScreenMode, onGoBack } = useGoBackInUpdateForm(
    data.contract_id,
    resetData
  );

  const renderRightContent = () => {
    return (
      <SuccessNotification
        title="The Database"
        description="had been successfully updated!"
        onClose={() => dispatch(resetUpdated())}
      />
    );
  };

  return (
    <PageContent
      title="Payoff Demand Requests"
      path="Record Management"
      secondScreenMode={secondScreenMode}
      onGoBack={onGoBack}
    >
      <PageFormLayout rightContent={isUpdated ? renderRightContent() : null}>
        <>
          <FormBlockSearchWithSelect getDataAction={getPayoffDemandData} />
          {!isLoading ? (
              secondScreenMode && data.contract_id && (
                  <FormPayoffDemand
                      data={data}
                      isLoading={isLoading}
                      isUpdated={isUpdated}
                      statuses={propertyStatuses}
                  />
              )
          ) : (
              <ListSkeleton count={16}></ListSkeleton>
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
