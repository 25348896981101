import { useState, useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { fetchWithAuth } from "../utils/fetch-with-auth";

interface UseGetQueryType {
    get: (queryParams?: string) => Promise<Response>
    loading: boolean
    error: string | null
    responseData: any
    responseStatus: number | null
    resetResponseData: () => void
}

export const useGetQuery = (query: string): UseGetQueryType => {
    const [cookies] = useCookies(['token'])
    const [responseData, setResponseData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [responseStatus, setResponseStatus] = useState<number | null>(null)

    const resetResponseData = useCallback(() => {setResponseData(null); setResponseStatus(null)}, [])

    const get = async (queryParams?: string) => {
        try {
            setLoading(true)
            const headers = {
                authorization: `Bearer ${cookies.token}`
            }
            const response = await fetchWithAuth(queryParams ? `${query}?${queryParams}` : query, {
                method: 'GET',
                headers
            })
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const data = await response.json()
            setResponseData(data)
            setResponseStatus(response.status)
            setLoading(false)
            return data
        } catch ({ message }) {
            setError(message as string)
            setLoading(false)
            return Promise.reject(error)
        }
    }


    return { responseData, loading, error, get, responseStatus, resetResponseData }
}
