import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import s from "./Datepicker.module.scss";
import cn from "classnames";
import { DATE_FORMAT_DATEPICKER } from "const";

interface CustomDatePickerProps {
  startDate: Date | null;
  setStartDate: (date?: Date | null) => void;
  placement?: "bottom" | "top" | "right" | "left";
  open?: boolean;
  placeholder?: string;
  sizeInput?: "normal" | "xs";
  disabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
}

interface CustomMonthPickerProps {
  startDate: Date | null;
  setStartDate: (date?: Date | null) => void;
  placement?: "bottom" | "top" | "right" | "left";
  useShortMonthInDropdown?: boolean;
}

export const CustomDatePicker = forwardRef<any, CustomDatePickerProps>(
  (
    {
      startDate,
      setStartDate,
      placement = "right",
      open,
      placeholder = "Select",
      sizeInput,
      disabled,
      minDate,
      maxDate,
      ...rest
    },
    ref
  ) => {
    const renderDayContents = (day: number, date?: Date | null) => {
      if (date && date.getDate() < 10) {
        return <span>0{`${date.getDate()}`}</span>;
      }
      return <span>{date && date.getDate()}</span>;
    };

    return (
      <div className={cn(s.wrapper, open && s.open)}>
        <DatePicker
          preventOpenOnFocus
          className={cn(s.custom, sizeInput === "xs" && s.xs)}
          calendarClassName={s.calendar}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          calendarStartDay={1}
          dateFormat={DATE_FORMAT_DATEPICKER}
          renderDayContents={renderDayContents}
          popperPlacement={placement}
          placeholderText={placeholder}
          open={open}
          disabled={disabled}
          ref={ref}
          minDate={minDate}
          maxDate={maxDate}
          {...rest}
        />
      </div>
    );
  }
);

export const CustomMonthPicker: React.FC<CustomMonthPickerProps> = ({
  startDate,
  setStartDate,
  placement = "right",
}) => {
  return (
    <div className={s.wrapper}>
      <DatePicker
        preventOpenOnFocus
        className={s.custom}
        calendarClassName={s.calendar}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        calendarStartDay={1}
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        popperPlacement={placement}
        placeholderText="Select"
      />
    </div>
  );
};
