import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_LOOK_UP_CONTRACT_DATA } from "const";
import { encodeDataToUrl } from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";
const cookies = new Cookies();

export const getLookUpContractData = createAsyncThunk(
  "lookUpContract",
  async (
      _payload: {
          field: string;
          value: string;
      },
    thunkApi
  ) => {
    try {
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(
        `${GET_LOOK_UP_CONTRACT_DATA}?${queryParams}`,
        {
          method: "get",
          headers: {
            authorization: "Bearer " + cookies.get("token"),
          },
        }
      );
      const parsed = await response.json();
      const { data } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(data);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
