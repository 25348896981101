import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Watchlist.module.scss";

export const Watchlist = () => {
  return (
    <PageContent title="Watchlist">
      <></>
    </PageContent>
  );
};
