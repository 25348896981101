import { PageContent } from "components/layout/PageContent/PageContent";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import {
  columnsPledgeDebtLine,
  DOWNLOAD_PLEDGE_CREDIT_LINE_CSV,
  FICO_OPTIONS,
  LENDER_OPTIONS,
  LIEN_POSITION_OPTIONS,
  LOTV_OPTIONS, MULTIPLE_OPTIONS,
  ORIGINATOR_OPTIONS_BASE,
  PLEDGE_TO_COLLATERAL,
  RISK_ADJUSTMENT_OPTIONS,
  SEASONING_MONTHS,
} from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import {
  createPledgeCreditLine,
  getPledgeDebt,
} from "store/actions/PledgeCollateralActions";
import { downloadResourceFromURL, encodeDataToUrl } from "utils";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock } from "components/modules";
import { usePrevious } from "hooks/usePrevious";
import { TableControlsWithSelectRows } from "components/modules/TableControls/TableControlsWithSelectRows";
import moment from "moment";

export const PledgeDebtLine = () => {
  const { valuesSelect, handleSelect, updateInitialSelect } = useFormSelectArr({
    lender: "East West Bank",
    originator: "All",
    seasoning: "All",
    fico: "All",
    lotv: "All",
    risk: "All",
    multiple: "All",
    lien: "All",
    pledgeTo: "Point Digital Finance, LLC",
  });

  const { lender, originator, seasoning, fico, lotv, risk, multiple, lien, pledgeTo } =
    valuesSelect;

  const [tableMode, setTableMode] = useState(false);

  const {
    pledge_debt: list,
    pledge_debt_investment_amount: investment_amount,
    pledge_debt_investment_count: investment_count,
    isLoading,
  } = useAppSelector((state) => state.pledgeCollateral);

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
    }
  }, [isLoading, prevIsLoading, tableMode]);

  const fields = (raw: boolean) => ({
    select1: {
      options: LENDER_OPTIONS,
      onSelect: (value: string) =>
        raw ? handleSelect("lender", value) : onSelect("lender", value),
      value: lender,
      fieldName: "Lender",
    },
    select2: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) =>
        raw ? handleSelect("originator", value) : onSelect("originator", value),
      value: originator,
      fieldName: "Select Originator",
    },
    select3: {
      options: SEASONING_MONTHS,
      onSelect: (value: string) =>
        raw ? handleSelect("seasoning", value) : onSelect("seasoning", value),
      value: seasoning,
      fieldName: "Seasoning Period in Months",
    },
    select4: {
      options: FICO_OPTIONS,
      onSelect: (value: string) =>
        raw ? handleSelect("fico", value) : onSelect("fico", value),
      value: fico,
      fieldName: "FICO",
    },
    select5: {
      options: LOTV_OPTIONS,
      onSelect: (value: string) =>
        raw ? handleSelect("lotv", value) : onSelect("lotv", value),
      value: lotv,
      fieldName: "LOTV",
    },
    select6: {
      options: RISK_ADJUSTMENT_OPTIONS,
      onSelect: (value: string) =>
        raw ? handleSelect("risk", value) : onSelect("risk", value),
      value: risk,
      fieldName: "Risk Adjustment",
    },
    select7: {
      options: MULTIPLE_OPTIONS,
      onSelect: (value: string) =>
          raw ? handleSelect("multiple", value) : onSelect("multiple", value),
      value: multiple,
      fieldName: "Multiple",
    },
    select8: {
      options: LIEN_POSITION_OPTIONS,
      onSelect: (value: string) =>
        raw ? handleSelect("lien", value) : onSelect("lien", value),
      value: lien,
      fieldName: "Lien Position",
    },
  });

  const getTableList = ({
    lender,
    originator,
    seasoning,
    fico,
    lotv,
    risk,
    multiple,
    lien,
  }: {
    lender: string;
    originator: string;
    seasoning: string;
    fico: string;
    lotv: string;
    risk: string;
    multiple: string;
    lien: string;
  }) => {
    if (!isLoading) {
      dispatch(
        getPledgeDebt({
          lender,
          model: originator.toLocaleLowerCase(),
          period: seasoning,
          fico,
          lotv,
          risk,
          multiple,
          lien,
        })
      );
      updateInitialSelect();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      lender,
      model: originator.toLocaleLowerCase(),
      period: seasoning,
      fico,
      lotv,
      risk,
      multiple,
      lien,
    });

    downloadResourceFromURL(
      `Pledge Collateral ${seasoning}.xlsx`,
      `${DOWNLOAD_PLEDGE_CREDIT_LINE_CSV}?${params}`
    );
  };

  const onSelect = (field: string, value: string) => {
    handleSelect(field, value);
    const data = {
      ...valuesSelect,
      [field]: value,
    };
    getTableList({ ...data });
  };

  const handleClickImportBtn = () => {
    getTableList(valuesSelect);
  };

  const submitSelectedDataAction = async (
    selectedRows: string[],
    dateValue: string
  ) => {
    await dispatch(
      createPledgeCreditLine({
        list_ids: selectedRows.join(","),
        lender: PLEDGE_TO_COLLATERAL.filter((it) => it.label === pledgeTo)?.[0]
          ?.value,
        pledged_date: moment(dateValue).format("YYYY-MM-DD"),
      })
    );
    getTableList(valuesSelect);
  };

  const [isFiltersMultirow, setFiltersMultirow] = useState(false);

  return (
    <PageContent
      title="Available to Pledge"
      path="Pledge Collateral"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fields(true)}
            importButton={{
              label: isLoading ? "Processing..." : "Generate Data",
              onclick: handleClickImportBtn,
              disabled: tableMode ? isLoading : isLoading || !seasoning,
            }}
            tableMode={tableMode}
            labelInOneRow={true}
            sizeFields={"400"}
          />
          {tableMode && (
            <TableControlsWithSelectRows
              columnsList={columnsPledgeDebtLine}
              list={list}
              infoRow
              investment_amount={investment_amount}
              investment_count={investment_count}
              defaultSortedColumn="effective_date"
              onDownloadFile={onDownloadFile}
              onFiltersRegroup={(v) => setFiltersMultirow(v)}
              formInPanel={
                <FormBlock
                  fields={fields(false)}
                  tableMode
                  inControlPanel
                  isFiltersMultirow={isFiltersMultirow}
                  sizeFields={"180"}
                />
              }
              withFilters
              searchColumns={["investment_id"]}
              idRowSelector="investment_id"
              selectedActionSelectOptions={PLEDGE_TO_COLLATERAL}
              selectedActionSelectLabel="Pledge To"
              selectedActionSelectValue={pledgeTo}
              selectedActionSelectChange={(value: string) =>
                handleSelect("pledgeTo", value)
              }
              selectedActionBtnTitle="Submit pledge collateral"
              shortFilterBtn
              confirmModalText="Are you sure you want to submit pledge collateral?"
              confirmModalSubmitText="Submit"
              confirmModalSubtitle="Please select the pledge date: "
              submitSelectedDataAction={submitSelectedDataAction}
              isLoading={isLoading}
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
