import { Button } from "components/common";
import { useNavigate } from "react-router-dom";
import s from "./Auth.module.scss";
import { AuthRoute } from "const";

export const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className={s.container}>
      <div className={s.heading}>
        <p className={s.h2}>Your password has been successfully changed</p>
        <p className={s.h4}>Now please log in</p>
      </div>
      <div className={s.form}>
        <Button
          size="large"
          wide="long"
          title="LOG IN"
          onClick={() => navigate(`${AuthRoute.LOGIN}`, { replace: true })}
          uppercase
        />
      </div>
    </div>
  );
};
