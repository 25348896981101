import { createSlice } from "@reduxjs/toolkit";
import { getList } from "store/actions/PayoffsReportActions";
import { PayoffsReportType } from "store/types/PayoffsReportType";

const initialState = {
  isLoading: false,
  error: null,
  list: [],
} as PayoffsReportType;

export const PayoffsReportSlice = createSlice({
  name: "payoffsReport",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: {
    [getList.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.error = null;
    },
    [getList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getList.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetList } = PayoffsReportSlice.actions;

export default PayoffsReportSlice.reducer;
