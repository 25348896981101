import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import s from "./ForwardCashFlows.module.scss";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, CustomDatePicker, Input, Select } from "../../../common";
import { AssumptionRange } from "./AssumptionRange";
import { useFormSelectArr } from "../../../../hooks/useFormSelectArr";
import { TableControls } from "components/modules";
import { BasePaginator } from "components/modules/Paginator";
import {
  ORIGINATOR_OPTIONS_BASE,
  PRODUCT_TYPE,
  FEE_TYPE,
  DOWNLOAD_FORWARD_CASHFLOWS_REPORT,
  POOL_OPTIONS_2,
  CONTRACT_TERM,
  CONTRACT_STATUS,
  columnsForwardCashflows,
} from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useFormInputPercentageArr } from "hooks/useFormInputPercentageArr";
import { useFormSelectDate } from "hooks/useFormSelectDate";
import { usePrevious } from "hooks/usePrevious";
import moment from "moment";
import { downloadResourceFromURLPost } from "utils";
import { getList } from "store/actions/ForwardCashFlowsActions";
import cn from "classnames";

export const ForwardCashflows = () => {
  const [tableMode, setTableMode] = useState(false);
  const onBackTableMode = () => setTableMode(false);

  const {
    // @ts-ignore
    list: { items, total },
    isLoading,
    error,
  } = useAppSelector((state) => state.forwardCashFlows);

  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedPageSize, setSelectedPageSize] = useState(100);
  const [serverErrors, setServerErrors] = useState(null);

  const { valuesSelect, handleSelect, updateInitialSelect } = useFormSelectArr({
    pool: "all kingsbridge",
    originator: "All",
    productType: "all",
    contractTerm: "all",
    contractStatus: "all",
    purchaseAssumptionsAcquisitionFeeType: "flat",
    purchaseAssumptionsRecordingFeeType: "flat",
    servicingAssumptionsServicingFeeType: "flat",
    servicingAssumptionsDispositionFeeType: "flat",
    scenarioAssumptionsDefault: 0,
    scenarioAssumptionsSeverity: 0,
  });

  const { valuesInputPercent, handleChangeInputPercent, handleKeyDown } =
    useFormInputPercentageArr({
      purchaseAssumptionsAcquisitionFeeValue: "0%",
      purchaseAssumptionsRecordingFeeValue: "0%",
      servicingAssumptionsServicingFeeValue: "0%",
      servicingAssumptionsDispositionFeeValue: "0%",
      scenarioAssumptionsDefault: "0%",
      scenarioAssumptionsSeverity: "0%",
    });

  const {
    value: dateFrom,
    handleDateChange: handleDateFromChange,
    resetDate: resetDateFrom,
  } = useFormSelectDate(moment().subtract(1, "months").endOf("month").toDate());

  const {
    value: dateTo,
    handleDateChange: handleDateToChange,
    resetDate: resetDateTo,
  } = useFormSelectDate(moment().toDate());

  const {
    value: valuationDate,
    handleDateChange: handleValuationDateChange,
    resetDate: resetValuationDate,
  } = useFormSelectDate(moment().toDate());

  const {
    pool,
    originator,
    productType,
    contractTerm,
    contractStatus,
    purchaseAssumptionsAcquisitionFeeType,
    purchaseAssumptionsRecordingFeeType,
    servicingAssumptionsServicingFeeType,
    servicingAssumptionsDispositionFeeType,
  } = valuesSelect;

  const {
    purchaseAssumptionsAcquisitionFeeValue,
    purchaseAssumptionsRecordingFeeValue,
    servicingAssumptionsServicingFeeValue,
    servicingAssumptionsDispositionFeeValue,
    scenarioAssumptionsDefault,
    scenarioAssumptionsSeverity,
  } = valuesInputPercent;

  const [dealAssumptionsCprRange, setDealAssumptionsCprRange] = useState({
    range1: {
      startMonth: 1,
      endMonth: 12,
      rate: "10%",
    },
    range2: {
      startMonth: 13,
      endMonth: 60,
      rate: "10%",
    },
    range3: {
      startMonth: 61,
      endMonth: 120,
      rate: "10%",
    },
    range4: {
      startMonth: 121,
      endMonth: 180,
      rate: "10%",
    },
    range5: {
      startMonth: 181,
      endMonth: 360,
      rate: "10%",
    },
  });
  const [dealAssumptionsHpaRange, setDealAssumptionsHpaRange] = useState({
    range1: {
      startMonth: 1,
      endMonth: 12,
      rate: "4%",
    },
    range2: {
      startMonth: 13,
      endMonth: 60,
      rate: "4%",
    },
    range3: {
      startMonth: 61,
      endMonth: 120,
      rate: "4%",
    },
    range4: {
      startMonth: 121,
      endMonth: 180,
      rate: "4%",
    },
    range5: {
      startMonth: 181,
      endMonth: 360,
      rate: "4%",
    },
  });

  const [originatorOptions, setOriginatorOptions] = useState(
    ORIGINATOR_OPTIONS_BASE
  );
  const [productTypeOptions, setProductTypeOptions] = useState(PRODUCT_TYPE);
  const [
    purchaseAssumptionsAcquisitionFeeTypeOptions,
    setPurchaseAssumptionsAcquisitionFeeTypeOptions,
  ] = useState(FEE_TYPE);
  const [
    purchaseAssumptionsRecordingFeeTypeOptions,
    setPurchaseAssumptionsRecordingFeeTypeOptions,
  ] = useState(FEE_TYPE);
  const [
    servicingAssumptionsServicingFeeTypeOptions,
    setServicingAssumptionsServicingFeeTypeOptions,
  ] = useState(FEE_TYPE);
  const [
    servicingAssumptionsDispositionFeeTypeOptions,
    setServicingAssumptionsDispositionFeeTypeOptions,
  ] = useState(FEE_TYPE);

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  const handleGenerate = () => {
    setSelectedPage(0);
    const data = {
      ...valuesSelect,
      ...valuesInputPercent,
      dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      dateTo: moment(dateTo).format("YYYY-MM-DD"),
      valuationDate: moment(valuationDate).format("YYYY-MM-DD"),
      dealAssumptionsCprRange: dealAssumptionsCprRange,
      dealAssumptionsHpaRange: dealAssumptionsHpaRange,
      pageNumber: 0,
      pageSize: selectedPageSize,
    };
    getTableList(data);
  };

  useEffect(() => {
    if (["kasf point", "sp-1", "other point"].includes(pool)) {
      setOriginatorOptions(
        ORIGINATOR_OPTIONS_BASE.filter((option) => option.key == "point")
      );
      handleSelect("originator", "Point");
      setProductTypeOptions(
        PRODUCT_TYPE.filter((option) => option.key == "AS")
      );
      handleSelect("productType", "AS");
    } else if (["kasf splitero", "other splitero"].includes(pool)) {
      setOriginatorOptions(
        ORIGINATOR_OPTIONS_BASE.filter((option) => option.key == "splitero")
      );
      handleSelect("originator", "Splitero");
      setProductTypeOptions(PRODUCT_TYPE);
      handleSelect("productType", "all");
    } else {
      setOriginatorOptions(ORIGINATOR_OPTIONS_BASE);
      handleSelect("originator", "All");
      setProductTypeOptions(PRODUCT_TYPE);
      handleSelect("productType", "all");
    }

    if (["other point", "other splitero"].includes(pool)) {
      setPurchaseAssumptionsAcquisitionFeeTypeOptions(
        FEE_TYPE.filter((option) => option.key == "flat")
      );
      setPurchaseAssumptionsRecordingFeeTypeOptions(
        FEE_TYPE.filter((option) => option.key == "flat")
      );
      setServicingAssumptionsServicingFeeTypeOptions(
        FEE_TYPE.filter((option) => option.key == "flat")
      );
      setServicingAssumptionsDispositionFeeTypeOptions(
        FEE_TYPE.filter((option) => option.key == "flat")
      );
      handleSelect("purchaseAssumptionsAcquisitionFeeType", "flat");
      handleSelect("purchaseAssumptionsRecordingFeeType", "flat");
      handleSelect("servicingAssumptionsServicingFeeType", "flat");
      handleSelect("servicingAssumptionsDispositionFeeType", "flat");
    } else {
      setPurchaseAssumptionsAcquisitionFeeTypeOptions(FEE_TYPE);
      setPurchaseAssumptionsRecordingFeeTypeOptions(FEE_TYPE);
      setServicingAssumptionsServicingFeeTypeOptions(FEE_TYPE);
      setServicingAssumptionsDispositionFeeTypeOptions(FEE_TYPE);
    }
  }, [pool]);

  useEffect(() => {
    if (originator === "Point") {
      setProductTypeOptions(
        PRODUCT_TYPE.filter((option) => option.key == "AS")
      );
      handleSelect("productType", "AS");
    } else {
      setProductTypeOptions(PRODUCT_TYPE);
      handleSelect("productType", "all");
    }
  }, [originator]);

  useEffect(() => {
    if (purchaseAssumptionsAcquisitionFeeType === "actual") {
      handleChangeInputPercent("purchaseAssumptionsAcquisitionFeeValue", "");
    }
    if (purchaseAssumptionsRecordingFeeType === "actual") {
      handleChangeInputPercent("purchaseAssumptionsRecordingFeeValue", "");
    }
    if (servicingAssumptionsServicingFeeType === "actual") {
      handleChangeInputPercent("servicingAssumptionsServicingFeeValue", "");
    }
    if (servicingAssumptionsDispositionFeeType === "actual") {
      handleChangeInputPercent("servicingAssumptionsDispositionFeeValue", "");
    }
  }, [
    purchaseAssumptionsAcquisitionFeeType,
    purchaseAssumptionsRecordingFeeType,
    servicingAssumptionsServicingFeeType,
    servicingAssumptionsDispositionFeeType,
  ]);

  useEffect(() => {
    if (error) {
      // @ts-ignore
      setServerErrors(JSON.stringify(error));
    } else {
      setServerErrors(null);
      if (prevIsLoading && !isLoading) {
        !tableMode && setTableMode(true);
      }
    }
  }, [isLoading, error]);

  const getTableList = ({
    dateFrom,
    dateTo,
    valuationDate,
    pool,
    originator,
    productType,
    contractTerm,
    contractStatus,
    purchaseAssumptionsAcquisitionFeeType,
    purchaseAssumptionsRecordingFeeType,
    servicingAssumptionsServicingFeeType,
    servicingAssumptionsDispositionFeeType,
    purchaseAssumptionsAcquisitionFeeValue,
    purchaseAssumptionsRecordingFeeValue,
    servicingAssumptionsServicingFeeValue,
    servicingAssumptionsDispositionFeeValue,
    scenarioAssumptionsDefault,
    scenarioAssumptionsSeverity,
    dealAssumptionsCprRange,
    dealAssumptionsHpaRange,
    pageSize,
    pageNumber,
  }: any) => {
    if (!isLoading) {
      dispatch(
        getList({
          date_from: dateFrom,
          date_to: dateTo,
          valuation_date: valuationDate,
          pool: pool,
          originator: originator,
          product_type: productType,
          contract_term: contractTerm,
          contract_status: contractStatus,
          purchase_assumptions_acquisition_fee_type:
            purchaseAssumptionsAcquisitionFeeType,
          purchase_assumptions_recording_fee_type:
            purchaseAssumptionsRecordingFeeType,
          servicing_assumptions_servicing_fee_type:
            servicingAssumptionsServicingFeeType,
          servicing_assumptions_disposition_fee_type:
            servicingAssumptionsDispositionFeeType,
          purchase_assumptions_acquisition_fee_value:
            purchaseAssumptionsAcquisitionFeeValue,
          purchase_assumptions_recording_fee_value:
            purchaseAssumptionsRecordingFeeValue,
          servicing_assumptions_servicing_fee_value:
            servicingAssumptionsServicingFeeValue,
          servicing_assumptions_disposition_fee_value:
            servicingAssumptionsDispositionFeeValue,
          scenario_assumptions_default: scenarioAssumptionsDefault,
          scenario_assumptions_severity: scenarioAssumptionsSeverity,
          deal_assumptions_cpr_range: dealAssumptionsCprRange,
          deal_assumptions_hpa_range: dealAssumptionsHpaRange,
          page_size: pageSize,
          page: pageNumber,
        })
      );

      updateInitialSelect();
    }
  };

  const onSelectPage = (pageNumber: number) => {
    setSelectedPage(pageNumber);
    const data = {
      ...valuesSelect,
      ...valuesInputPercent,
      dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      dateTo: moment(dateTo).format("YYYY-MM-DD"),
      valuationDate: moment(valuationDate).format("YYYY-MM-DD"),
      dealAssumptionsCprRange: dealAssumptionsCprRange,
      dealAssumptionsHpaRange: dealAssumptionsHpaRange,
      pageNumber: pageNumber,
      pageSize: selectedPageSize,
    };
    getTableList({ ...data });
  };

  const onSelectPageSize = (pageSize: number) => {
    setSelectedPageSize(pageSize);
    setSelectedPage(0);
    const data = {
      ...valuesSelect,
      ...valuesInputPercent,
      dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      dateTo: moment(dateTo).format("YYYY-MM-DD"),
      valuationDate: moment(valuationDate).format("YYYY-MM-DD"),
      dealAssumptionsCprRange: dealAssumptionsCprRange,
      dealAssumptionsHpaRange: dealAssumptionsHpaRange,
      pageNumber: 0,
      pageSize: pageSize,
    };
    getTableList({ ...data });
  };

  const [isFileDownloading, setFileDownloading] = useState(false);
  const onDownloadFile = () => {
    setFileDownloading(true);
    const data = {
      pool: pool,
      originator: originator,
      product_type: productType,
      contract_term: contractTerm,
      contract_status: contractStatus,
      purchase_assumptions_acquisition_fee_type:
        purchaseAssumptionsAcquisitionFeeType,
      purchase_assumptions_recording_fee_type:
        purchaseAssumptionsRecordingFeeType,
      servicing_assumptions_servicing_fee_type:
        servicingAssumptionsServicingFeeType,
      servicing_assumptions_disposition_fee_type:
        servicingAssumptionsDispositionFeeType,
      purchase_assumptions_acquisition_fee_value:
        purchaseAssumptionsAcquisitionFeeValue,
      purchase_assumptions_recording_fee_value:
        purchaseAssumptionsRecordingFeeValue,
      servicing_assumptions_servicing_fee_value:
        servicingAssumptionsServicingFeeValue,
      servicing_assumptions_disposition_fee_value:
        servicingAssumptionsDispositionFeeValue,
      scenario_assumptions_default: scenarioAssumptionsDefault,
      scenario_assumptions_severity: scenarioAssumptionsSeverity,
      date_from: moment(dateFrom).format("YYYY-MM-DD"),
      date_to: moment(dateTo).format("YYYY-MM-DD"),
      valuation_date: moment(valuationDate).format("YYYY-MM-DD"),
      deal_assumptions_cpr_range: dealAssumptionsCprRange,
      deal_assumptions_hpa_range: dealAssumptionsHpaRange,
    };

    data.purchase_assumptions_acquisition_fee_value =
      data.purchase_assumptions_acquisition_fee_value.replace("%", "");
    data.purchase_assumptions_recording_fee_value =
      data.purchase_assumptions_recording_fee_value.replace("%", "");
    data.servicing_assumptions_servicing_fee_value =
      data.servicing_assumptions_servicing_fee_value.replace("%", "");
    data.servicing_assumptions_disposition_fee_value =
      data.servicing_assumptions_disposition_fee_value.replace("%", "");
    data.scenario_assumptions_default =
      data.scenario_assumptions_default.replace("%", "");
    data.scenario_assumptions_severity =
      data.scenario_assumptions_severity.replace("%", "");
    data.deal_assumptions_cpr_range.range1.rate =
      data.deal_assumptions_cpr_range.range1.rate.replace("%", "");
    data.deal_assumptions_cpr_range.range2.rate =
      data.deal_assumptions_cpr_range.range2.rate.replace("%", "");
    data.deal_assumptions_cpr_range.range3.rate =
      data.deal_assumptions_cpr_range.range3.rate.replace("%", "");
    data.deal_assumptions_cpr_range.range4.rate =
      data.deal_assumptions_cpr_range.range4.rate.replace("%", "");
    data.deal_assumptions_cpr_range.range5.rate =
      data.deal_assumptions_cpr_range.range5.rate.replace("%", "");
    data.deal_assumptions_hpa_range.range1.rate =
      data.deal_assumptions_hpa_range.range1.rate.replace("%", "");
    data.deal_assumptions_hpa_range.range2.rate =
      data.deal_assumptions_hpa_range.range2.rate.replace("%", "");
    data.deal_assumptions_hpa_range.range3.rate =
      data.deal_assumptions_hpa_range.range3.rate.replace("%", "");
    data.deal_assumptions_hpa_range.range4.rate =
      data.deal_assumptions_hpa_range.range4.rate.replace("%", "");
    data.deal_assumptions_hpa_range.range5.rate =
      data.deal_assumptions_hpa_range.range5.rate.replace("%", "");

    downloadResourceFromURLPost(
      `Forward Cashflow and Expected IRR Model.xlsx`,
      `${DOWNLOAD_FORWARD_CASHFLOWS_REPORT}`,
      data,
      () => setFileDownloading(false)
    );
  };

  return (
    <PageContent
      title="Forward Cashflows"
      path="Valuation"
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout widthLeft={100} tableMode={tableMode}>
        <>
          {!tableMode && (
            <>
              <div className={s.container}>
                <div
                  className={cn(s.filters, s.filtersBlock, s.filtersBlockInner)}
                >
                  <h3 className={s.h3}>Pool Assumptions</h3>
                  <div className={s.filterRow}>
                    <div className={s.field}>
                      <span className={s.label}>Pool</span>
                      <Select
                        options={POOL_OPTIONS_2}
                        onSelect={(value) => handleSelect("pool", value)}
                        value={pool}
                      />
                    </div>
                    <div className={s.field}>
                      <span className={s.label}>Originators</span>
                      <Select
                        options={originatorOptions}
                        onSelect={(value) => handleSelect("originator", value)}
                        value={originator}
                      />
                    </div>
                  </div>
                  <div className={s.filterRow}>
                    <div className={s.field}>
                      <span className={s.label}>Product Type</span>
                      <Select
                        options={productTypeOptions}
                        onSelect={(value) => handleSelect("productType", value)}
                        value={productType}
                      />
                    </div>
                    <div className={s.field}>
                      <span className={s.label}>Contract Term</span>
                      <Select
                        options={CONTRACT_TERM}
                        onSelect={(value) =>
                          handleSelect("contractTerm", value)
                        }
                        value={contractTerm}
                      />
                    </div>
                  </div>
                  <div className={s.filterRow}>
                    <div className={s.field}>
                      <span className={s.label}>Date Range</span>
                      <span className={s.labelSmall}>From</span>
                      <CustomDatePicker
                        startDate={dateFrom}
                        setStartDate={(value: Date | undefined | null) =>
                          handleDateFromChange(value)
                        }
                        sizeInput={"normal"}
                        maxDate={dateTo}
                      />
                    </div>
                    <div className={s.field}>
                      <span className={s.labelSmall}>To</span>
                      <CustomDatePicker
                        startDate={dateTo}
                        setStartDate={(value: Date | undefined | null) =>
                          handleDateToChange(value)
                        }
                        sizeInput={"normal"}
                      />
                    </div>
                  </div>
                  <div className={s.filterRow}>
                    <div className={s.field}>
                      <span className={s.label}>Valuation Date</span>
                      <CustomDatePicker
                        startDate={valuationDate}
                        setStartDate={(value: Date | undefined | null) =>
                          handleValuationDateChange(value)
                        }
                        sizeInput={"normal"}
                      />
                    </div>
                    <div className={s.field}>
                      <span className={s.label}>Contract Status</span>
                      <Select
                        options={CONTRACT_STATUS}
                        onSelect={(value) =>
                          handleSelect("contractStatus", value)
                        }
                        value={contractStatus}
                      />
                    </div>
                  </div>
                </div>
                <div className={cn(s.assumptions, s.filtersBlock)}>
                  <div className={s.filtersBlockInner}>
                    <h3>Purchase Assumptions</h3>
                    <div>
                      <span className={s.label}>Acquisition Fee</span>
                      <div className={s.filterRowNarrow}>
                        <div className={s.field}>
                          <Select
                            options={
                              purchaseAssumptionsAcquisitionFeeTypeOptions
                            }
                            onSelect={(value) =>
                              handleSelect(
                                "purchaseAssumptionsAcquisitionFeeType",
                                value
                              )
                            }
                            value={purchaseAssumptionsAcquisitionFeeType}
                            disabled={
                              purchaseAssumptionsAcquisitionFeeTypeOptions.length ==
                              1
                            }
                            hideIcon={
                              purchaseAssumptionsAcquisitionFeeTypeOptions.length ==
                              1
                            }
                            disabledAsNormal={
                              purchaseAssumptionsAcquisitionFeeTypeOptions.length ==
                              1
                            }
                          />
                        </div>
                        <div className={s.field}>
                          <Input
                            className={s.inputField}
                            value={purchaseAssumptionsAcquisitionFeeValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChangeInputPercent(
                                "purchaseAssumptionsAcquisitionFeeValue",
                                e.target.value
                              )
                            }
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLInputElement>
                            ) =>
                              handleKeyDown(
                                "purchaseAssumptionsAcquisitionFeeValue",
                                purchaseAssumptionsAcquisitionFeeValue,
                                e
                              )
                            }
                            disabled={
                              purchaseAssumptionsAcquisitionFeeType === "actual"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className={s.label}>Recording Fee</span>
                      <div className={s.filterRowNarrow}>
                        <div className={s.field}>
                          <Select
                            options={purchaseAssumptionsRecordingFeeTypeOptions}
                            onSelect={(value) =>
                              handleSelect(
                                "purchaseAssumptionsRecordingFeeType",
                                value
                              )
                            }
                            value={purchaseAssumptionsRecordingFeeType}
                            disabled={
                              purchaseAssumptionsRecordingFeeTypeOptions.length ==
                              1
                            }
                            hideIcon={
                              purchaseAssumptionsRecordingFeeTypeOptions.length ==
                              1
                            }
                            disabledAsNormal={
                              purchaseAssumptionsRecordingFeeTypeOptions.length ==
                              1
                            }
                          />
                        </div>
                        <div className={s.field}>
                          <Input
                            className={s.inputField}
                            value={purchaseAssumptionsRecordingFeeValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChangeInputPercent(
                                "purchaseAssumptionsRecordingFeeValue",
                                e.target.value
                              )
                            }
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLInputElement>
                            ) =>
                              handleKeyDown(
                                "purchaseAssumptionsRecordingFeeValue",
                                purchaseAssumptionsRecordingFeeValue,
                                e
                              )
                            }
                            disabled={
                              purchaseAssumptionsRecordingFeeType === "actual"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={s.filtersBlockInner}>
                    <h3>Servicing Assumptions</h3>
                    <div>
                      <span className={s.label}>Servicing Fee</span>
                      <div className={s.filterRowNarrow}>
                        <div className={s.field}>
                          <Select
                            options={
                              servicingAssumptionsServicingFeeTypeOptions
                            }
                            onSelect={(value) =>
                              handleSelect(
                                "servicingAssumptionsServicingFeeType",
                                value
                              )
                            }
                            value={servicingAssumptionsServicingFeeType}
                            disabled={
                              servicingAssumptionsServicingFeeTypeOptions.length ==
                              1
                            }
                            hideIcon={
                              servicingAssumptionsServicingFeeTypeOptions.length ==
                              1
                            }
                            disabledAsNormal={
                              servicingAssumptionsServicingFeeTypeOptions.length ==
                              1
                            }
                          />
                        </div>
                        <div className={s.field}>
                          <Input
                            className={s.inputField}
                            value={servicingAssumptionsServicingFeeValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChangeInputPercent(
                                "servicingAssumptionsServicingFeeValue",
                                e.target.value
                              )
                            }
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLInputElement>
                            ) =>
                              handleKeyDown(
                                "servicingAssumptionsServicingFeeValue",
                                servicingAssumptionsServicingFeeValue,
                                e
                              )
                            }
                            disabled={
                              servicingAssumptionsServicingFeeType === "actual"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className={s.label}>Disposition Fee</span>
                      <div className={s.filterRowNarrow}>
                        <div className={s.field}>
                          <Select
                            options={
                              servicingAssumptionsDispositionFeeTypeOptions
                            }
                            onSelect={(value) =>
                              handleSelect(
                                "servicingAssumptionsDispositionFeeType",
                                value
                              )
                            }
                            value={servicingAssumptionsDispositionFeeType}
                            disabled={
                              servicingAssumptionsDispositionFeeTypeOptions.length ==
                              1
                            }
                            hideIcon={
                              servicingAssumptionsDispositionFeeTypeOptions.length ==
                              1
                            }
                            disabledAsNormal={
                              servicingAssumptionsDispositionFeeTypeOptions.length ==
                              1
                            }
                          />
                        </div>
                        <div className={s.field}>
                          <Input
                            className={s.inputField}
                            value={servicingAssumptionsDispositionFeeValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChangeInputPercent(
                                "servicingAssumptionsDispositionFeeValue",
                                e.target.value
                              )
                            }
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLInputElement>
                            ) =>
                              handleKeyDown(
                                "servicingAssumptionsDispositionFeeValue",
                                servicingAssumptionsDispositionFeeValue,
                                e
                              )
                            }
                            disabled={
                              servicingAssumptionsDispositionFeeType ===
                              "actual"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={cn(s.filtersBlockInner)}>
                    <div className={s.field}>
                      <span className={s.label}>
                        Scenario Assumptions: Default
                      </span>
                      <Input
                        value={scenarioAssumptionsDefault}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChangeInputPercent(
                            "scenarioAssumptionsDefault",
                            e.target.value
                          )
                        }
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                          handleKeyDown(
                            "scenarioAssumptionsDefault",
                            scenarioAssumptionsDefault,
                            e
                          )
                        }
                      />
                    </div>
                    <div className={s.field}>
                      <span className={s.label}>
                        Scenario Assumptions: Severity
                      </span>
                      <Input
                        value={scenarioAssumptionsSeverity}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChangeInputPercent(
                            "scenarioAssumptionsSeverity",
                            e.target.value
                          )
                        }
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                          handleKeyDown(
                            "scenarioAssumptionsSeverity",
                            scenarioAssumptionsSeverity,
                            e
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={cn(
                    s.dealAssumption,
                    s.filtersBlock,
                    s.filtersBlockInner
                  )}
                >
                  <h3>Deal Assumptions (CPR Range) (Annual)</h3>
                  <AssumptionRange
                    rangeName={"CPR"}
                    value={dealAssumptionsCprRange}
                    handleChange={(v) => setDealAssumptionsCprRange(v)}
                  />
                </div>
                <div
                  className={cn(
                    s.dealAssumption,
                    s.filtersBlock,
                    s.filtersBlockInner
                  )}
                >
                  <h3>Deal Assumptions (HPA Range) (Annual)</h3>
                  <AssumptionRange
                    rangeName={"HPA"}
                    value={dealAssumptionsHpaRange}
                    handleChange={(v) => setDealAssumptionsHpaRange(v)}
                  />
                </div>
              </div>
              <div className={s.buttons}>
                <Button
                  className={s.generateBtn}
                  size="xs"
                  title={isLoading ? "Processing..." : "Generate"}
                  onClick={handleGenerate}
                />
                {serverErrors && (
                  <span className={s.errorArea}>Errors: {serverErrors}</span>
                )}
              </div>
            </>
          )}
          {tableMode && (
            <TableControls
              columnsList={columnsForwardCashflows}
              list={items || []}
              isLoading={isLoading || isFileDownloading}
              onDownloadFile={onDownloadFile}
              dynamicPinFirstCol
              searchColumns={["contract_id"]}
              withFilters
            />
          )}
          {tableMode && (
            <BasePaginator
              pageNumber={selectedPage}
              pageSize={selectedPageSize}
              total={total || 0}
              disabled={isLoading}
              onSelectPage={onSelectPage}
              onSelectPageSize={onSelectPageSize}
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
