import { createSlice } from "@reduxjs/toolkit";
import { getLookUpContractData } from "store/actions/LookUpContractActions";

import { LookUpContractType } from "store/types/LookUpContractType";

const initialState = {
  isLoading: false,
  error: null,
  data: {
    origination_terms: {
      contract_id: "",
    },
  },
  isUpdated: false,
} as LookUpContractType;

export const LookUpContractSlice = createSlice({
  name: "lookUpContract",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetData: (state) => {
      state.data = { origination_terms: { contract_id: "" } };
    },
  },
  extraReducers: {
    [getLookUpContractData.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    [getLookUpContractData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getLookUpContractData.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetData } = LookUpContractSlice.actions;

export default LookUpContractSlice.reducer;
