import React, { useRef } from "react";

import cn from "classnames";
import useClickOutside from "hooks/useClickOutside";
import s from "./ModalAside.module.scss";
import { CloseBtn } from "../CloseBtn/CloseBtn";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root") as HTMLElement;

interface IProps {
  children: JSX.Element;
  bottomBtn?: JSX.Element;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalAside = ({
  isOpen,
  children,
  bottomBtn,
  title,
  onClose,
}: IProps) => {
  const refElem = useRef<HTMLDivElement>(null);
  const refOverlay = useRef<HTMLDivElement>(null);

  useClickOutside(refElem, isOpen, onClose, refOverlay);

  return ReactDOM.createPortal(
    <div className={cn(s.wrapper, isOpen && s.open)}>
      <div className={s.modal} ref={refElem}>
        <CloseBtn onClose={onClose} margin={28} />
        <h2 className={s.title}>{title}</h2>
        <div className={cn(s.content)}>{children}</div>
        {bottomBtn && <div className={s.bottom}>{bottomBtn}</div>}
      </div>
      <span className={s.overlay} ref={refOverlay}></span>
    </div>,
    modalRoot
  );
};
