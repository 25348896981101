import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_PERMISSIONS, UPDATE_PERMISSIONS } from "const";
import { Cookies } from "react-cookie";
import { fetchWithAuth } from "utils/fetch-with-auth";

const getPermissions = createAsyncThunk(
  "permissions",
  async (_payload: string, thunkApi) => {
    try {
      const cookies = new Cookies();

      const response = await fetchWithAuth(
        `${GET_PERMISSIONS}?user_id=${_payload}`,
        {
          method: "get",
          headers: {
            authorization: "Bearer " + cookies.get("token"),
          },
        }
      );
      const parsed = await response.json();
      const { users_access } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(users_access);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const updatePermissions = createAsyncThunk(
  "update_permissions",
  async (
    payload: { list_value: { access_id: number; access: boolean }[] },
    thunkApi
  ) => {
    try {
      const cookies = new Cookies();

      const response = await fetchWithAuth(`${UPDATE_PERMISSIONS}`, {
        method: "post",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const parsed = await response.json();

      if (response.status !== 200) {
        throw new Error(parsed.detail);
      }
      return thunkApi.fulfillWithValue(parsed);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getPermissions, updatePermissions };
