import cn from "classnames";
import { Icon } from "../Icon/Icon";
import s from "./Accordion.module.scss";

type Props = {
  isOpen: boolean;
  size?: "sm" | "mid";
  className?: string;
};

export const ExpandIcon = ({ isOpen, size = "sm", className }: Props) => {
  return (
    <div className={className && s[className]}>
      <Icon className={cn(s[size])} icon={isOpen ? "minus" : "plus"} />
    </div>
  );
};
