import { createSlice } from "@reduxjs/toolkit";
import { cancelImpairedList, getList } from "store/actions/ImpairedListActions";
import {
  ImpairedListItemType,
  ImpairedListType,
} from "store/types/ImpairedListType";

const initialState = {
  isLoading: false,
  error: null,
  list: [],
} as ImpairedListType;

export const ImpairedListSlice = createSlice({
  name: "impairedList",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: {
    [getList.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      const list = action.payload.map((row: ImpairedListItemType) => ({
        ...row,
        custom_id: `${row.contract_name}---${row.filing_type}`,
      }));
      state.list = list;
      state.error = null;
    },
    [getList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getList.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [cancelImpairedList.fulfilled.type]: (state, action) => {
      state.error = null;
      state.isLoading = false;
    },
    [cancelImpairedList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [cancelImpairedList.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetList } = ImpairedListSlice.actions;

export default ImpairedListSlice.reducer;
