import React, {PropsWithChildren} from "react";
//@ts-ignore
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import s from "./SkeletonLoader.module.scss";

interface ListSkeletonProps {
    count?: number;
}

interface TableRowProps {
    colCount?: number;
    rowCount?: number;
}

export const ListSkeleton = ({ count }: PropsWithChildren<ListSkeletonProps>) => {
    return <SkeletonTheme baseColor="#ffffff" highlightColor="#eff2f6" height={36} duration={2}>
        <p>
            <Skeleton count={count ?? 10} />
        </p>
    </SkeletonTheme>
}

export const TableRowSkeleton = ({ colCount, rowCount }: TableRowProps) => {
    // @ts-ignore
    const rows: number[] = Array.from({ length: rowCount || 10 }, (_, index) => index);
    const cols: number[] = Array.from({ length: colCount || 10 }, (_, index) => index);
    return <>{rows.map((row) => (
        <tr key={row}>
            {cols.map((col) => (
                <td key={`${row}-${col}`}><Skeleton></Skeleton></td>
            ))}
        </tr>
        ))}</>
}
