import React, { useRef } from "react";

import cn from "classnames";
import useClickOutside from "hooks/useClickOutside";
import s from "./Modal.module.scss";
import { CloseBtn } from "../CloseBtn/CloseBtn";
import ReactDOM from "react-dom";
import { Button } from "../Button/Button";

const modalRoot = document.getElementById("modal-root") as HTMLElement;

interface IProps {
  isOpen: boolean;
  children?: JSX.Element;
  title?: string;
  subtitle?: string;
  onClose: () => void;
  submitText: string;
  withCancelBtn?: boolean;
  cancelText?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  idForm?: string;
  isLoading?: boolean;
  submitDisabled?: boolean;
  loaderText?: string;
}

export const Modal = ({
  isOpen,
  children,
  title,
  subtitle,
  onClose,
  submitText,
  withCancelBtn = true,
  cancelText = "Cancel",
  onSubmit,
  onCancel,
  idForm,
  isLoading,
  submitDisabled,
  loaderText,
}: IProps) => {
  const refElem = useRef<HTMLDivElement>(null);
  const refOverlay = useRef<HTMLDivElement>(null);

  useClickOutside(refElem, isOpen, onClose, refOverlay);

  const handleCancel = () => {
    onCancel && onCancel();
    onClose();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={cn(s.wrapper, isOpen && s.open)}>
      <div className={s.modal} ref={refElem}>
        <CloseBtn onClose={onClose} margin={28} />
        <div className={s.content}>
          <p className={s.title}>{title}</p>
          <p className={s.subtitle}>{subtitle}</p>
          {children && children}
        </div>
        <div className={s.btnRow}>
          {withCancelBtn && (
            <Button
              filled="empty"
              title={cancelText}
              onClick={handleCancel}
              wide="long"
              size="large"
              uppercase
            />
          )}
          {idForm ? (
            <Button
              type="submit"
              wide="long"
              size="large"
              form={idForm}
              title={submitText}
              disabled={submitDisabled}
              isLoading={isLoading}
              loader={loaderText}
              uppercase
            />
          ) : (
            <Button
              wide="long"
              size="large"
              title={submitText}
              onClick={onSubmit}
              disabled={submitDisabled}
              isLoading={isLoading}
              loader={loaderText}
              uppercase
            />
          )}
        </div>
      </div>
      <span className={s.overlay} ref={refOverlay}></span>
    </div>,
    modalRoot
  );
};
