import cn from "classnames";
import { Icon } from "components/common";
import s from "./SortBtn.module.scss";
import { Sorting } from "interfaces";

type Props = {
  order: Sorting;
  onClick?: () => void;
  isActive: boolean;
};

export const SortBtn = ({ order, onClick, isActive }: Props) => {
  return (
    <button className={cn(s.btn, isActive && s.active)} onClick={onClick}>
      {order === "asc" ? <Icon icon="sort-down" /> : <Icon icon="sort-up" />}
    </button>
  );
};
