import { IFieldsInfoBlock } from "interfaces/fieldsInfo";
import { FieldsInfoBlock } from "./FieldsInfoBlock";
import s from "./FieldsInfoBlocks.module.scss";
import { Fragment } from "react";

type Props = {
  blocks: IFieldsInfoBlock[];
  data: any;
};
export const FieldsInfoBlocks = ({ blocks, data }: Props) => {
  return (
    <div className={s.container}>
      {blocks.map(({ title, name, fields }) => (
        <Fragment key={name}>
          <FieldsInfoBlock title={title} fields={fields} data={data[name]} />
        </Fragment>
      ))}
    </div>
  );
};
