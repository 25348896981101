import { Icon } from "components/common/Icon/Icon";
import { forwardRef } from "react";
import Select, { components } from "react-select";

type Option = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  options: string[] | Option[];
  value: string | Option;
  onSelect: (val: string) => void;
  onInputChange?: (val: string) => void;
  disabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  width?: number;
};

export const SelectReact = forwardRef<any, Props>(
  (
    {
      name,
      options,
      value,
      onSelect,
      onInputChange,
      disabled,
      isSearchable,
      isClearable,
      width,
    },
    ref
  ) => {
    const optionIsString = typeof options[0] === "string";

    const selectOptions = optionIsString
      ? (options as string[])?.map((i: string) => ({ value: i, label: i }))
      : (options as Option[]);

    const getListOption = (list: Option[], val: string) => {
      return list.find(({ value }) => value === val) || "";
    };

    const DropdownIndicator = (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          <Icon icon="down" className="r-dropdownBtn" />
        </components.DropdownIndicator>
      );
    };

    const ClearIndicator = (props: any) => {
      return (
        <components.ClearIndicator {...props}>
          <Icon icon="close" className="r-clearBtn" />
        </components.ClearIndicator>
      );
    };

    return (
      <>
        {selectOptions && (
          <Select
            unstyled
            placeholder={isSearchable ? "Enter data" : "Select"}
            className="basic-single"
            classNamePrefix="select"
            isLoading={false}
            isClearable={isClearable}
            isSearchable={Boolean(isSearchable)}
            name={name}
            options={selectOptions}
            value={
              optionIsString
                ? getListOption(selectOptions, value as string)
                : value
            }
            onChange={(val: any) => onSelect(val?.value)}
            onInputChange={(val: any) => onInputChange && onInputChange(val)}
            components={{
              DropdownIndicator,
              ClearIndicator,
              IndicatorSeparator: null,
            }}
            classNames={{
              control: () =>
                `select r-select r-select-opener ${disabled && "disabled"}`,
              placeholder: () => "r-select-placeholder",
              menu: () => "r-select-menu",
              option: () => "r-select-option",
            }}
            isDisabled={disabled}
            noOptionsMessage={() => "Loading..."}
            styles={{
              control: (styles) => ({ ...styles, width: `${width}px` }),
            }}
            ref={ref}
          />
        )}
      </>
    );
  }
);
