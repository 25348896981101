import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_USER,
  DELETE_USER,
  EDIT_USER,
  GET_ALL_USERS,
  GET_USER_API,
} from "const";
import { ManageUsersTypes } from "store/types/ManageUsers";
import {
  encodeDataToUrl,
  getQueryText,
  throwErrorIfRequestNotSucceed,
} from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const getUsers = createAsyncThunk("kwm/users", async (_, thunkApi) => {
  try {
    const cookies = new Cookies();

    const response = await fetchWithAuth(`${GET_ALL_USERS}`, {
      method: "get",
      headers: {
        authorization: "Bearer " + cookies.get("token"),
      },
    });
    const parsed = await response.json();
    const { data } = parsed;

    return thunkApi.fulfillWithValue(data as ManageUsersTypes);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

const getCurrentUser = createAsyncThunk(
  "kwm/currentUser",
  async (_payload: { email: string }, thunkApi) => {
    const cookies = new Cookies();

    try {
      const { email } = _payload;
      const queryText = getQueryText([{ name: "email", value: email }]);

      const response = await fetchWithAuth(`${GET_USER_API}?${queryText}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();
      const { first_name, last_name, phone_number, role, id } = parsed;

      throwErrorIfRequestNotSucceed(response);

      return thunkApi.fulfillWithValue({
        firstName: first_name,
        lastName: last_name,
        email,
        phone: phone_number,
        role,
        id,
      });
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const createUser = createAsyncThunk(
  "kwm/create_user",
  async (
    _payload: {
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      role: string;
      password: string;
    },
    thunkApi
  ) => {
    try {
      const cookies = new Cookies();
      const { email, password, first_name, last_name, phone_number, role } =
        _payload;

      const formData = new FormData();
      formData.append("email", email);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("phone_number", phone_number);
      formData.append("role", role);
      formData.append("password", password);
      const response = await fetchWithAuth(`${CREATE_USER}`, {
        method: "post",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
        body: formData,
      });

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(_payload);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const editUser = createAsyncThunk(
  "kwm/edit_user",
  async (
    _payload: {
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      role: string;
    },
    thunkApi
  ) => {
    try {
      const cookies = new Cookies();
      const { first_name, last_name, phone_number, role, email } = _payload;

      const queryText = encodeDataToUrl({
        first_name,
        last_name,
        phone_number,
        role,
        email,
      });

      const response = await fetchWithAuth(`${EDIT_USER}?${queryText}`, {
        method: "put",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(_payload);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const deleteUser = createAsyncThunk(
  "kwm/delete_user",
  async (_payload: { id: number | string; email: string }, thunkApi) => {
    try {
      const cookies = new Cookies();
      const { email } = _payload;

      const queryText = getQueryText([{ name: "delete_email", value: email }]);

      const response = await fetchWithAuth(`${DELETE_USER}?${queryText}`, {
        method: "delete",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(_payload);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getUsers, getCurrentUser, createUser, editUser, deleteUser };
