import { createSlice } from '@reduxjs/toolkit'
import { getList } from '../actions/ActualReturnsReportList'
import { ActualReturnsReportListType } from 'store/types/ActualReturnsReportList'

const initialState = {
    isLoading: false,
    error: null,
    list: [],
} as ActualReturnsReportListType

export const ActualReturnsReportListSlice = createSlice({
    name: 'actualReturnsReportList',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload
        },
        resetList: (state) => {
            state.list = []
        },
    },
    extraReducers: {
        [getList.fulfilled.type]: (state, action) => {
            state.isLoading = false
            state.list = action.payload
            state.error = null
        },
        [getList.pending.type]: (state) => {
            state.isLoading = true
        },
        [getList.rejected.type]: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
    },
})

export const { setError, resetList } = ActualReturnsReportListSlice.actions

export default ActualReturnsReportListSlice.reducer
