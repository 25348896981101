import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Dashboard.module.scss";
import { portfolioTabs } from "const";

export const DashboardPortfolio = () => {
  return (
    <PageContent title="Portfolio Dashboard" path="Dashboards">
      <div className={s.container}>
        <iframe
          title="dashboards"
          src={portfolioTabs[0].link}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </PageContent>
  );
};
