import cn from "classnames";
import s from "./CloseBtn.module.scss";
import { Icon } from "../Icon/Icon";

type Props = {
  onClose: () => void;
  margin?: number;
};

export const CloseBtn = ({ onClose, margin }: Props) => {
  return (
    <button
      onClick={() => onClose()}
      className={cn("btn", s.btn)}
      style={{ margin: `${margin}px` }}
    >
      <Icon icon="close" />
    </button>
  );
};
