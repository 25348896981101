import { CustomDatePicker } from "components/common";
import s from "./TableFilters.module.scss";
import { IPropsFilter } from "./TableFilters";

export const DateFilter = ({
  name,
  label,
  filters,
  setFilters,
}: IPropsFilter) => {
  const handleDateChange = (
    name: string,
    field: "fromDate" | "toDate",
    value?: Date | null
  ) => {
    setFilters({
      ...filters,
      [name]: {
        ...filters[name],
        notEmpty:
          Boolean(value) ||
          Boolean(
            field === "fromDate" ? filters[name].toDate : filters[name].fromDate
          ),
        [field]: value,
      },
    });
  };

  return (
    <div className={s.item}>
      <span className={s.label}>{label}</span>
      <div className={s.itemsRow}>
        <div className={s.subItem}>
          <span className={s.labelSmall}>{"From"}</span>
          <CustomDatePicker
            startDate={filters[name]?.fromDate || null}
            setStartDate={(value: Date | undefined | null) =>
              handleDateChange(name, "fromDate", value)
            }
            placement="bottom"
          />
        </div>
        <div className={s.subItem}>
          <span className={s.labelSmall}>{"To"}</span>
          <CustomDatePicker
            startDate={filters[name]?.toDate || null}
            setStartDate={(value: Date | undefined | null) =>
              handleDateChange(name, "toDate", value)
            }
            placement="bottom"
          />
        </div>
      </div>
    </div>
  );
};
