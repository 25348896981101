import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  GET_PAYOFF_DEMAND_DATA,
  GET_SEARCH_VALUES,
  UPDATE_PAYOFF_DEMAND_DATA,
} from "const";
import { encodeDataToUrl } from "utils";
import { PayoffDemandDataType } from "store/types/PayoffDemandType";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const cookies = new Cookies();

export const getPayoffDemandData = createAsyncThunk(
  "payoffDemand",
  async (
    _payload: {
      field: string;
      value: string;
    },
    thunkApi
  ) => {
    try {
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(`${GET_PAYOFF_DEMAND_DATA}?${queryParams}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();
      const { contracts, property_status } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue({ contracts, property_status });
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getSearchValues = createAsyncThunk(
  "searchValues",
  async (_payload: {}, thunkApi) => {
    try {
      const response = await fetchWithAuth(`${GET_SEARCH_VALUES}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }

      return thunkApi.fulfillWithValue(parsed.data);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updatePayoffDemandData = createAsyncThunk(
  "payoffDemand/update",
  async (_payload: PayoffDemandDataType, thunkApi) => {
    try {
      await fetchWithAuth(`${UPDATE_PAYOFF_DEMAND_DATA}`, {
        method: "put",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(_payload),
      });

      return thunkApi.fulfillWithValue({});
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
