import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { UploadNotification, FormBlock } from "components/modules";
import { FHFA_IMPORT_API } from "const";
import { usePostQuery } from "hooks/usePostQuery";
import { useState } from "react";
import {
  getUploadStatusStorage,
  getUploadStatus,
  getInitialFileName,
  getLoading,
} from "utils";
import { useLocalStorage } from "hooks/useLocalStorage";

const InitialState = {
  hpiFile: null,
  hpiNonMetroFile: null,
  stateDataFile: null,
};

type ValuesTypes = {
  hpiFile: File | null;
  hpiNonMetroFile: File | null;
  stateDataFile: File | null;
};

export const FHFA = () => {
  const pageName = "fhfa";

  const [values, setValues] = useState<ValuesTypes>(InitialState);

  const [showUploadNotification, setShowUploadNotification] = useState(
    localStorage.getItem("fhfa") || false
  );

  const [succeedFileName, setSucceedFileName] = useState("");

  const { changeStorage } = useLocalStorage(pageName, () => {
    setSucceedFileName(getInitialFileName(pageName));
  });

  const { loading, error, postFormData, responseStatus } = usePostQuery(
    FHFA_IMPORT_API,
    changeStorage
  );

  const { hpiFile, hpiNonMetroFile, stateDataFile } = values;

  const handleFileUpload = (file: File, field: string) => {
    setValues({ ...values, [field]: file });
  };

  const handleRemove = (field: string) => {
    setValues({ ...values, [field]: null });
  };

  const handleImport = async () => {
    try {
      setShowUploadNotification(true);
      const requestData = new FormData();

      if (hpiFile && hpiNonMetroFile && stateDataFile) {
        requestData.append("file_hpi_metro_data", hpiFile);
        requestData.append("file_hpi_non_metro_data", hpiNonMetroFile);
        requestData.append("file_state_data", stateDataFile);
        await postFormData(requestData, undefined, "fhfa");
        setValues(InitialState);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const status = getUploadStatus(loading, responseStatus);

  const renderRightContent = () => {
    return (
      <UploadNotification
        status={getUploadStatusStorage(pageName, status)}
        fileName={""}
        closeNotification={() => setShowUploadNotification(false)}
        pageName={pageName}
      />
    );
  };

  return (
    <PageContent
      title="Federal Housing Financial Agency Information"
      path="Imports"
    >
      <PageFormLayout
        rightContent={showUploadNotification ? renderRightContent() : null}
      >
        <>
          <FormBlock
            fields={{
              selectFiles: [
                {
                  onRemove: () => handleRemove("hpiFile"),
                  fileList: hpiFile,
                  setFileList: (file: File) =>
                    handleFileUpload(file, "hpiFile"),
                  label: "Select HPI Metro Data",
                },
                {
                  onRemove: () => handleRemove("hpiNonMetroFile"),
                  fileList: hpiNonMetroFile,
                  setFileList: (file: File) =>
                    handleFileUpload(file, "hpiNonMetroFile"),
                  label: "Select HPI Non-Metro Data",
                },
                {
                  onRemove: () => handleRemove("stateDataFile"),
                  fileList: stateDataFile,
                  setFileList: (file: File) =>
                    handleFileUpload(file, "stateDataFile"),
                  label: "Select State Data",
                },
              ],
            }}
            importButton={{
              disabled: !hpiFile || !hpiNonMetroFile || !stateDataFile,
              loading: getLoading(loading, pageName),
              loadingText: "IMPORTING...",
              onclick: handleImport,
            }}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
