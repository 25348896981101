import { createSlice } from "@reduxjs/toolkit";
import { UserTypes } from "store/types/User";
import {
  forgotPassword,
  getUser,
  loginUser,
  updateUser,
} from "../actions/UserActions";

const initialState = {
  isLogin: false,
  isLoading: false,
  error: null,
  user: null,
} as UserTypes;

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLogin = false;
      state.user = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: {
    [loginUser.fulfilled.type]: (state, action) => {
      state.isLogin = true;
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    [loginUser.pending.type]: (state) => {
      state.isLoading = true;
      state.isLogin = false;
    },
    [loginUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isLogin = false;
    },
    [forgotPassword.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [forgotPassword.pending.type]: (state) => {
      state.isLoading = true;
    },
    [forgotPassword.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [updateUser.fulfilled.type]: (state, action) => {
      state.user = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    [updateUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [getUser.fulfilled.type]: (state, action) => {
      state.user = action.payload;
      state.error = null;
      state.isLoading = false;
      state.isLogin = true;
    },
    [getUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUser.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { logout, setError } = UserSlice.actions;

export default UserSlice.reducer;
