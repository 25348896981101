import { useEffect } from "react";

export const useLocalStorage = (
  item: string,
  handleStorageChange: () => void
) => {
  const changeStorage = () => {
    window.dispatchEvent(new Event(`storage-${item}`));
  };

  useEffect(() => {
    window.addEventListener(`storage-${item}`, handleStorageChange);

    return () => {
      window.removeEventListener(`storage-${item}`, handleStorageChange);
    };
  }, []);

  return { changeStorage };
};
