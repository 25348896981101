import s from "./BasePaginator.module.scss";
import React, { useEffect, useState } from "react";
import cn from "classnames";

interface IProps {
    pageSize: number;
    pageNumber: number;
    total: number;
    onSelectPage: (val: number) => void;
    onSelectPageSize: (val: number) => void;
    disabled: boolean
}

export const BasePaginator = ({
                                  pageSize,
                                  pageNumber,
                                  total,
                                  onSelectPage,
                                  onSelectPageSize,
                                  disabled
                              }: IProps) => {

    const lastNumber = pageSize * (pageNumber + 1)
    const indicatorText = `Showing ${pageSize * pageNumber + 1} to ${lastNumber < total ? lastNumber : total} of ${total} entries`;
    const lastPageNumber = total / pageSize > 0 ? Math.floor(total / pageSize) + 1 : Math.floor(total / pageSize)

    const [pageNumberForInput, setPageNumberForInput] = useState(`${pageNumber}`);

    useEffect(() => {
        setPageNumberForInput(`${pageNumber}`);
    }, [pageNumber]);

    const onInputPage = (e: any) => {
        const value = e.target.value;
        if (value) {
            const pn = +value - 1;
            const result = pn < lastPageNumber ? (pn < 0 ? 0 : pn) : lastPageNumber - 1;
            setPageNumberForInput(`${result}`);
        } else {
            setPageNumberForInput("");
        }
    }

    const handleKeyDown = (e: { key: string; }) => {
        if (e.key === 'Enter') {
            selectPageAsString(pageNumberForInput);
        }
    };

    const selectPageAsString = (pageNumber: string) => {
        if (pageNumber)
            onSelectPage(+pageNumber);
    }

    const selectPage = (pageNumber: number) => {
        if (+pageNumber >= 0 && +pageNumber < lastPageNumber)
            selectPageAsString(`${pageNumber}`);
    }

    return (
        <div className={s.container}>
            <div className={s.left}>
                <div className={s.indicator}>{indicatorText}</div>
            </div>
            <div className={s.right}>
                <span className={s.pageSizeLabel}>Page Size</span>
                <select className={s.select} value={pageSize} onChange={(e) => onSelectPageSize(+e.target.value)}
                        disabled={disabled}>
                    <option>10</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                    <option>1000</option>
                </select>
                <span className={s.pageTotalLabel}>Total Pages {lastPageNumber}</span>
                <button type="button" className={cn(s.btn, s.prevNextBtn)}
                        disabled={pageNumber == 0 || disabled}
                        onClick={() => selectPage(0)}>
                    First
                </button>
                <button type="button" className={cn(s.btn, s.prevNextBtn)}
                        disabled={pageNumber == 0 || disabled}
                        onClick={() => selectPage(pageNumber - 1)}>
                    Previous
                </button>
                <input type="number" className={s.pageInput}
                       placeholder="Page"
                       value={pageNumberForInput ? +pageNumberForInput + 1 : ""}
                       onChange={(e) => onInputPage(e)} disabled={disabled}
                       onKeyDown={(e) => handleKeyDown(e)}/>
                <button type="button" className={cn(s.btn, s.prevNextBtn)}
                        disabled={lastPageNumber == pageNumber + 1 || disabled}
                        onClick={() => selectPage(pageNumber + 1)}>
                    Next
                </button>
                <button type="button" className={cn(s.btn, s.prevNextBtn)}
                        disabled={lastPageNumber == pageNumber + 1 || disabled}
                        onClick={() => selectPage(lastPageNumber - 1)}>
                    Last
                </button>
            </div>
        </div>
    );
};
