import { CustomDatePicker, Input } from "components/common";
import s from "./FormUpdateData.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/reduxHooks";
import { FormUpdateDataButtons } from "./FormUpdateDataButtons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NOT_EMPTY_VALIDATION, REQUIRED_PERCENT } from "const";
import {
  formatAmountToNumber,
  getInitialAmount,
  getInitialPercent,
  getValueChangeInputAmount,
  getValueChangeInputPercentage,
} from "utils";
import moment from "moment";
import { FieldWrapper } from "components/common/FormElements/FieldWrapper";
import { PreliminaryExitDataType } from "store/types/PreliminaryExitType";
import { updatePreliminaryExitData } from "store/actions/PreliminaryExitActions";
import { resetData } from "store/reducers/PreliminaryExitSlice";
import { SelectReact } from "components/common/FormElements/SelectReact/SelectReact";

type Props = {
  data: PreliminaryExitDataType;
  statuses: string[];
  isLoading: boolean;
  isUpdated: boolean;
};

interface FormValues {
  contract_id: string;
  investment_amount: string;
  contract_status: string;
  date_funded: Date | null;
  exit_date: Date | null;
  gross_repayment: string;
  gross_irr: string;
  final_home_value: string;
}

export const FormPreliminaryExit = ({
  data,
  statuses,
  isLoading,
  isUpdated,
}: Props) => {
  const [fetchData, setFetchData] = useState<any>(null);

  const dispatch = useAppDispatch();

  const withSubmitModal = true;
  const idForm = "preliminaryExit";

  const getInitialData = (data: PreliminaryExitDataType) => {
    return {
      contract_id: data.contract_id,
      investment_amount: getInitialAmount(data.investment_amount),
      contract_status: data.contract_status,
      date_funded: data.date_funded ? moment(data.date_funded).toDate() : null,
      exit_date: data.exit_date ? moment(data.exit_date).toDate() : null,
      gross_repayment: getInitialAmount(data.gross_repayment),
      gross_irr: getInitialPercent(data.gross_irr),
      final_home_value: getInitialAmount(data.final_home_value),
    };
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    clearErrors,
  } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: getInitialData(data),
  });

  useEffect(() => {
    if (data.contract_id) {
      reset(getInitialData(data));
    }
  }, [data]);

  const onCancel = () => {
    dispatch(resetData());
  };

  const onChangeInputAmount = (name: keyof FormValues, value: string) => {
    const newVal = getValueChangeInputAmount(value);
    setValue(name, newVal);
  };

  const onChangeInputPercent = (name: keyof FormValues, value: string) => {
    const newVal = getValueChangeInputPercentage(value);
    setValue(name, newVal);
    const error = REQUIRED_PERCENT.validate(newVal);
    error === true && clearErrors(name);
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const {
      investment_amount,
      date_funded,
      exit_date,
      gross_repayment,
      gross_irr,
      final_home_value,
      ...rest
    } = formData;

    const fetchData = {
      ...rest,
      investment_amount: formatAmountToNumber(investment_amount),
      date_funded: date_funded ? moment(date_funded).format("MM/DD/YYYY") : "",
      exit_date: exit_date ? moment(exit_date).format("MM/DD/YYYY") : "",
      gross_repayment: formatAmountToNumber(gross_repayment),
      gross_irr: formatAmountToNumber(gross_irr),
      final_home_value: formatAmountToNumber(final_home_value),
    };

    withSubmitModal
      ? setFetchData(fetchData)
      : dispatch(updatePreliminaryExitData(fetchData));
  };

  return (
    <form
      id={idForm}
      onSubmit={handleSubmit(onSubmit)}
      className={s.form}
      noValidate
    >
      <div className={s.item}>
        <span className={s.label}>Contract ID:</span>
        <Controller
          control={control}
          name="contract_id"
          render={({ field }) => <Input {...field} disabled />}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Investment Amount:</span>
        <Controller
          control={control}
          name="investment_amount"
          render={({ field }) => (
            <Input
              {...field}
              onChangeCustom={onChangeInputAmount}
              leftSymbol="$"
              amountFormat
            />
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Contract Status:</span>
        <Controller
          control={control}
          name="contract_status"
          rules={NOT_EMPTY_VALIDATION}
          render={({ field }) => (
            <FieldWrapper error={errors.contract_status}>
              <SelectReact
                {...field}
                name="contract_status"
                options={statuses}
                onSelect={field.onChange}
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Date Funded:</span>
        <Controller
          control={control}
          name="date_funded"
          rules={NOT_EMPTY_VALIDATION}
          render={({ field }) => (
            <FieldWrapper error={errors.date_funded}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Exit Date:</span>
        <Controller
          control={control}
          name="exit_date"
          rules={NOT_EMPTY_VALIDATION}
          render={({ field }) => (
            <FieldWrapper error={errors.exit_date}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Gross Repayment:</span>
        <Controller
          control={control}
          name="gross_repayment"
          rules={NOT_EMPTY_VALIDATION}
          render={({ field }) => (
            <FieldWrapper error={errors.gross_repayment}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Gross IRR:</span>
        <Controller
          control={control}
          name="gross_irr"
          rules={REQUIRED_PERCENT}
          render={({ field }) => (
            <FieldWrapper error={errors.gross_irr}>
              <Input
                {...field}
                onChangeCustom={onChangeInputPercent}
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Final Home Value:</span>
        <Controller
          control={control}
          name="final_home_value"
          rules={NOT_EMPTY_VALIDATION}
          render={({ field }) => (
            <FieldWrapper error={errors.final_home_value}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <FormUpdateDataButtons
        disabled={!isDirty}
        withSubmitModal={withSubmitModal}
        withCancelModal
        onCancel={onCancel}
        idForm={idForm}
        fetchData={fetchData}
        fetchDataAction={updatePreliminaryExitData}
        isUpdated={isUpdated}
        isLoading={isLoading}
      />
    </form>
  );
};
