import { PageContent } from "components/layout/PageContent/PageContent";
import { historyColumns, S3_DOWNLOAD_FILE } from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { ActionsColumn } from "interfaces";
import { downloadFile, encodeDataToUrl, getHistoryList } from "utils";
import { useEffect } from "react";
import { getHistory } from "store/actions/HistoryActions";
import { TableControlsWithLazyLoading } from "components/modules/TableControls/TableControlsWithLazyLoading";
import { clearHistory } from "store/reducers/HistoryActionsSlice";
import { getUsers } from "store/actions/ManageUsersActions";
import { useGetQuery } from "../../../hooks/useGetQuery";

export const HistoryPage = () => {
  const { items, total, isLoading } = useAppSelector(
    (state) => state.actionsHistory
  );

  const { get: getS3FileUrl } = useGetQuery(S3_DOWNLOAD_FILE);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleDownload = async (file_id: string) => {

    const query = encodeDataToUrl({ _id: file_id });

    const response = await getS3FileUrl(query);
    const fileName = filesList.find(file => file.fileId == +file_id)?.file

    downloadFile(fileName ?? file_id, response?.url);
  };

  const filesList = getHistoryList(items).filter(({ file }) => file) || []

  return (
    <PageContent title="History" history={false}>
      <TableControlsWithLazyLoading
        columnsList={historyColumns}
        list={filesList}
        total={total}
        getListAction={getHistory}
        clearListAction={clearHistory}
        defaultSortedColumn="date"
        searchColumns={["file"]}
        actionsColumn={[ActionsColumn.download]}
        idRowActionColumn="fileId"
        onDownload={handleDownload}
        withFilters
        isLoading={isLoading}
      />
    </PageContent>
  );
};
