import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControls } from "components/modules";
import {
  DOWNLOAD_EXITS_VARIANCE,
  ORIGINATOR_OPTIONS_BASE,
  columnsExitsVarianceReport,
  DATE_FORMAT,
} from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useFormSelectDateArr } from "hooks/useFormSelectDateArr";
import { usePrevious } from "hooks/usePrevious";
import moment from "moment";
import { useEffect, useState } from "react";
import { getList } from "store/actions/ExitsVarianceList";
import { downloadResourceFromURL, encodeDataToUrl } from "utils";

export const ExitsVarianceReport = () => {
  const {
    valuesSelect,
    handleSelect,
    valuesSelectDirty,
    updateInitialSelect,
    resetSelect,
  } = useFormSelectArr({
    originator: "All",
  });

  const {
    valuesDate,
    handleDateChange,
    valuesDateDirty,
    updateInitialDate,
    resetDate,
  } = useFormSelectDateArr({
    startDate: new Date(moment("01-01-2024").format(DATE_FORMAT)),
    endDate: null,
  });

  const { originator } = valuesSelect;

  const { startDate, endDate } = valuesDate;

  const [tableMode, setTableMode] = useState(false);

  const [isOpenAssetList, toggleAssetList] = useState(false);

  const { list, isLoading } = useAppSelector(
    (state) => state.exitsVarianceList
  );

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
      isOpenAssetList && toggleAssetList(false);
    }
  }, [isLoading]);

  const fields = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => handleSelect("originator", value),
      value: originator,
      oneInRow: true,
      fieldName: "Select Originator",
    },
    startDate: {
      startDate,
      setStartDate: (value: Date | undefined | null) =>
        handleDateChange("startDate", value),
      fieldName: "Choose Date",
      label: "From",
    },
    endDate: {
      endDate,
      setEndDate: (value: Date | undefined | null) =>
        handleDateChange("endDate", value),
      label: "To",
    },
  };

  const getTableList = () => {
    if (!isLoading) {
      dispatch(
        getList({
          model: originator.toLocaleLowerCase(),
          date_from: moment(startDate).format("YYYY-MM-DD"),
          date_to: moment(endDate).format("YYYY-MM-DD"),
        })
      );

      updateInitialSelect();
      updateInitialDate();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      model: originator.toLocaleLowerCase(),
      date_from: moment(startDate).format("YYYY-MM-DD"),
      date_to: moment(endDate).format("YYYY-MM-DD"),
    });

    downloadResourceFromURL(
      `Exits Variance.xlsx`,
      `${DOWNLOAD_EXITS_VARIANCE}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    getTableList();
  };

  const onCloseAssetList = () => {
    toggleAssetList(false);
    resetSelect();
    resetDate();
  };

  return (
    <PageContent
      title="Exits Variance Report"
      path="Accounting"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fields}
            importButton={{
              label: isLoading ? "Processing..." : "Generate Report",
              onclick: handleClickImportBtn,
              disabled: tableMode
                ? isLoading ||
                  !startDate ||
                  !endDate ||
                  (!valuesSelectDirty && !valuesDateDirty)
                : isLoading,
            }}
            tableMode={tableMode}
            isOpenAssetList={isOpenAssetList}
            onCloseAssetList={onCloseAssetList}
          />
          {tableMode && (
            <TableControls
              columnsList={columnsExitsVarianceReport}
              list={list}
              defaultSortedColumn="effective_date"
              onDownloadFile={onDownloadFile}
              onOpenAssetList={() => toggleAssetList(true)}
              withFilters
              searchColumns={["contract_id"]}
              isLoading={isLoading}
              dynamicPinFirstCol
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
