import { CustomDatePicker } from "components/common";
import { useFormSelectDate } from "hooks/useFormSelectDate";
import s from "./PledgeConfirmModal.module.scss";
import cn from "classnames";
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";

type Props = {
  subtitle: string;
  setDirtyDate: Dispatch<SetStateAction<boolean>>;
};

export const PledgeConfirmModal = forwardRef(
  ({ subtitle, setDirtyDate }: Props, ref) => {
    const { value, handleDateChange } = useFormSelectDate(null);

    useEffect(() => {
      if (value) {
        setDirtyDate(true);
      } else setDirtyDate(false);
    }, [value]);

    useImperativeHandle(ref, () => value);

    return (
      <div className={cn(s.container)}>
        <h3 className={s.subtitle}>{subtitle}</h3>
        <CustomDatePicker
          startDate={value}
          setStartDate={(value: Date | undefined | null) =>
            handleDateChange(value)
          }
          placeholder="Select Date"
          open
        />
      </div>
    );
  }
);
