import { IFieldsInfoItem } from "interfaces/fieldsInfo";
import s from "./FieldsInfoBlocks.module.scss";
import { formatData } from "utils";
import cn from "classnames";
import { FormatType } from "interfaces";

type Props = {
  title: string;
  fields: IFieldsInfoItem[];
  data: any;
};

const alignClass = (align: "right" | "left" | undefined) => {
    if (align == "right") {
      return s.right
    } else if (align == "left") {
      return s.left
    } else {
      return ''
    }
}


export const FieldsInfoBlock = ({ title, fields, data }: Props) => {
  return (
    <div className={s.block}>
      <h3 className={s.title}>{title}</h3>
      <div className={s.columns}>
        {fields.map(({ label, name, formatType, align }) => {
          const value = formatType
            ? formatData(formatType, data[name])
            : data[name];

          return (
            <div className={s.item} key={name}>
              <span className={s.label}>{label}</span>

              <span
                className={cn(
                  alignClass(align as "right" | "left" | undefined),
                  s.value,
                  (formatType === FormatType.percentNotDecimal ||
                    formatType === FormatType.variance) &&
                    s.right
                )}
              >
                {Array.isArray(value) && value?.length === 2 ? (
                  <>
                    <span>{value[0]}</span>
                    <span>{value[1]}</span>
                  </>
                ) : (
                  <>{value}</>
                )}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
