import { Cookies } from "react-cookie";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { Layout } from "components/layout/Layout/Layout";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import {
  AVAILABLE_PRIVATE_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
} from "routes";

import { getUser } from "store/actions/UserActions";
import { useEffect } from "react";
import { NotFound } from "components/pages/NotFound/NotFound";
import { removePersistors } from "utils/common";
import { AuthRoute, NotFoundRoute, SettingsRoute } from "const/routes";
import { AuthLayout } from "components/layout/AuthLayout/AuthLayout";
import { configRouting } from "const/navigation";

interface RouteType {
  path: string;
  element: JSX.Element;
  subRoutes?: RouteType[];
  id: number;
}

const App = () => {
  const cookies = new Cookies();
  const dispatch = useAppDispatch();
  const isAuth = cookies.get("token");

  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (cookies.get("token") && cookies.get("email")) {
      dispatch(getUser({ email: cookies.get("email") }));
    }
  }, []);

  const renderRoutesWithPermissions = (routes: RouteType[]) => {
    removePersistors();
    return routes.map(({ path, element, subRoutes, id }) => {
      const label = configRouting.find(({ link }) => link === path)?.label;

      const enabled = user?.access?.find(
        ({ functional_name }) => functional_name === label
      )?.access;

      if ((label && enabled) || !label) {
        return (
          <Route path={path} element={element} key={id}>
            {subRoutes?.length ? renderRoutesWithPermissions(subRoutes) : null}
          </Route>
        );
      } else return null;
    });
  };

  const renderRoutes = (routes: RouteType[]) => {
    return routes.map(({ path, element, subRoutes, id }) => {
      return (
        <Route path={path} element={element} key={id}>
          {subRoutes?.length ? renderRoutes(subRoutes) : null}
        </Route>
      );
    });
  };

  return (
    <>
      {isAuth ? (
        user?.access && (
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              {renderRoutes(AVAILABLE_PRIVATE_ROUTES)}
              {renderRoutesWithPermissions(PRIVATE_ROUTES)}
              <Route index element={<Navigate to={SettingsRoute} />} />
              <Route path="*" element={<Navigate to={NotFoundRoute} />} />
            </Route>
          </Routes>
        )
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            {renderRoutes(PUBLIC_ROUTES)}
            <Route index element={<Navigate to={AuthRoute.LOGIN} />} />
            <Route
              path={AuthRoute.ROOT}
              element={<Navigate to={AuthRoute.LOGIN} />}
            />
            <Route path="*" element={<Navigate to={AuthRoute.LOGIN} />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default App;
