import { useEffect, useRef, useState } from "react";
import s from "./NavMenu.module.scss";
import { ISubmenuItem } from "interfaces";
import { Link } from "react-router-dom";
import cn from "classnames";

type Props = {
  child: ISubmenuItem[];
  pathname: string;
};

export const Submenu = ({ child, pathname }: Props) => {
  return (
    <ul className={s.submenu}>
      {child.map(({ label, link }) => {
        return (
          <li className={s.submenuItem} key={label}>
            <Link
              className={cn(s.link, link === pathname && s.active)}
              to={link}
            >
              {label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
