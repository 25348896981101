import { DetailedHTMLProps, InputHTMLAttributes } from "react";

interface IProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export const InputSearch = (props: IProps) => {
  return <input {...props} type="search" placeholder="Search" />;
};
