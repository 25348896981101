import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_PRELIMINARY_EXIT_DATA, UPDATE_PRELIMINARY_EXIT_DATA } from "const";
import { encodeDataToUrl } from "utils";
import { PreliminaryExitDataType } from "store/types/PreliminaryExitType";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const cookies = new Cookies();

export const getPreliminaryExitData = createAsyncThunk(
  "preliminaryExit",
  async (
    _payload: {
      contract_id: string;
    },
    thunkApi
  ) => {
    try {
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(
        `${GET_PRELIMINARY_EXIT_DATA}?${queryParams}`,
        {
          method: "get",
          headers: {
            authorization: "Bearer " + cookies.get("token"),
          },
        }
      );
      const parsed = await response.json();
      const { data, all_contract_status } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue({ data, all_contract_status });
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updatePreliminaryExitData = createAsyncThunk(
  "preliminaryExit/update",
  async (_payload: PreliminaryExitDataType, thunkApi) => {
    try {
      await fetchWithAuth(`${UPDATE_PRELIMINARY_EXIT_DATA}`, {
        method: "put",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(_payload),
      });

      return thunkApi.fulfillWithValue({});
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
