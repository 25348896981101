import { Cookies } from "react-cookie";
import { QueryParam, QueryTextParams } from "../interfaces/utils";
import moment from "moment";
import { DATE_FORMAT } from "const";
import { fetchWithAuth } from "./fetch-with-auth";

export const getQueryText = (params: QueryTextParams) => {
  const searchParams = new URLSearchParams();
  params?.forEach(({ name, value }: QueryParam) =>
    searchParams.append(name, value)
  );

  return searchParams.toString();
};

export const encodeDataToUrl = (
  params: { [key: string]: any },
  isSkipNull: boolean = true
) =>
  Object.keys(params)
    .filter((key) => !isSkipNull || (isSkipNull && params[key]))
    .map((key) => {
      if (Array.isArray(params[key])) {
        const out = params[key].map(
          (p: number) => `${encodeURIComponent(key)}=${p}`
        );

        return out.join("&");
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join("&");

export const responseHandler = (
  response: Response,
  setSucceedFileName?: React.Dispatch<React.SetStateAction<string>>,
  resetValues?: () => void,
  values?: {
    originator?: string;
    selectedFile?: File | null;
    startDate?: Date;
  }
) => {
  if (response?.status === 200) {
    const fileName = values?.selectedFile?.name || "";
    setSucceedFileName && setSucceedFileName(fileName);
  }
  resetValues && resetValues();
};

export const throwErrorIfRequestNotSucceed = (response: Response) => {
  if (!response.ok) throw new Error(response.statusText + response.status);
};

export const downloadFile = (
  fileName: string,
  urlData: string,
  blankWindow = true
) => {
  const aLink = document.createElement("a");
  // let headers = new Headers()
  // const cookies = new Cookies()
  // headers.append('authorization', `Bearer ${cookies.get('token')}`)

  fetchWithAuth(urlData)
    .then((response) => {
      const contentDisposition = response.headers.get("content-disposition");
      const filenameHeader = response.headers.get("Filename");
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].trim().split("=")[1]
        : filenameHeader
        ? filenameHeader
        : fileName
        ? fileName
        : "unknown";
      const sanitizedFilename = sanitizeFilename(filename);
      return response.blob().then((blob) => {
        return {
          filename: sanitizedFilename,
          blob: blob,
        };
      });
    })
    .then((data) => {
      const objectUrl = window.URL.createObjectURL(data.blob);
      aLink.href = objectUrl;
      if (blankWindow) aLink.target = "_blank";
      aLink.download = data.filename; // set filename obtained from content-disposition header
      aLink.click();
      window.URL.revokeObjectURL(objectUrl);
    })
    .catch((error) => console.log(error));
};

export const downloadS3File = async (
  fileName: string,
  urlData: string,
  token: string
) => {
  const aLink = document.createElement("a");
  const headers = {
    authorization: `Bearer ${token}`,
  };

  fetchWithAuth(urlData, {
    method: "GET",
    headers,
  })
    .then(async (response) => {
      const filenameFromHeader = response.headers.get("filename");

      const filename = filenameFromHeader ? fileName : "unknown";

      const sanitizedFilename = filename.replace(/"/gi, ""); // remove any quotes from filename

      const json = await response.json();

      return {
        filename: sanitizedFilename,
        url: json.url,
      };
    })
    .then((data) => {
      aLink.href = data.url;
      aLink.target = "_blank";
      aLink.download = data.filename; // set filename obtained from content-disposition header
      aLink.click();
    })
    .catch((error) => console.log(error));
};

export const downloadFileWithToken = (
  fileName: string,
  urlData: string,
  method?: string
) => {
  const aLink = document.createElement("a");
  const cookies = new Cookies();

  fetchWithAuth(urlData, {
    method: method || "GET",
    headers: {
      authorization: "Bearer " + cookies.get("token"),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob);
      aLink.href = objectUrl;
      aLink.download = `${fileName}`;
      aLink.click();
      window.URL.revokeObjectURL(objectUrl);
    });
};

export const downloadResourceFromURL = (
  fileName: string,
  url: string,
  method?: string
) => {
  const aLink = document.createElement("a");
  const headers = new Headers({});
  const cookies = new Cookies();

  fetchWithAuth(url, {
    method: method || "GET",
    headers: {
      authorization: "Bearer " + cookies.get("token"),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob);
      aLink.href = objectUrl;
      aLink.download = fileName || `unknown.csv`;
      aLink.click();
      window.URL.revokeObjectURL(objectUrl);
    });
};

export const downloadResourceFromURLPost = (
  fileName: string,
  url: string,
  data?: any,
  callBack?: () => void
) => {
  const aLink = document.createElement("a");
  const headers = new Headers({});
  const cookies = new Cookies();

  fetchWithAuth(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + cookies.get("token"),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob);
      aLink.href = objectUrl;
      aLink.download = fileName || `unknown.csv`;
      aLink.click();
      window.URL.revokeObjectURL(objectUrl);
    })
    .then(() => {
      if (callBack) callBack();
    })
    .catch((e) => console.log(e));
};

export const removePersistors = async () => {
  await localStorage.removeItem("oon");
  await localStorage.removeItem("detail");
  await localStorage.removeItem("tape");
  await localStorage.removeItem("preliminaryExits");
  await localStorage.removeItem("3rd_party");
  await localStorage.removeItem("fhfa");
  await localStorage.removeItem("asset_list");
  await localStorage.removeItem("internal_valuation");
  await localStorage.removeItem("official_run_selection");
  await localStorage.removeItem("third_party_comparision_report");
};

export const formatDateTime = (dateTimeStr: string) => {
  const dateTime = new Date(dateTimeStr);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneAbbreviation = dateTime
    .toLocaleString("en-US", { timeZone, timeZoneName: "short" })
    .split(" ")[3];

  const date = dateTime
    .toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, ".");
  const time = dateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const timeWithTimeZone = `${time} ${timeZoneAbbreviation}`;

  return { date, time, timeWithTimeZone };
};

export const formatPercent = (value: number, isDecimal = true) => {
  return value || value === 0
    ? `${(isDecimal ? value * 100 : value).toFixed(2)}%`
    : "";
};

export const formatUSD = (value: number, empty = true) => {
  const roundValue = value || value === 0 ? +value.toFixed(2) : undefined;

  const formatted =
    roundValue !== undefined
      ? [
          "$",
          roundValue?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        ]
      : [];
  return empty ? (value ? formatted : "") : formatted;
};

export const formatUSDFieldsInfo = (value: number) => {
  const roundValue = value || value === 0 ? +value.toFixed(2) : null;

  const formatted = [
    "$",
    roundValue !== null
      ? roundValue?.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "",
  ];
  return formatted;
};

export const formatDate = (date: string) => {
  const dateMoment = moment(date);
  return date && dateMoment.isValid() ? dateMoment.format(DATE_FORMAT) : "";
};

export const formatVariance = (value: number) => {
  return value < 0 ? `(${(value * -1).toFixed(2)})` : `${value.toFixed(2)}`;
};

export const getYearsOptions = (minYear: number) => {
  const years = [];

  for (let i = new Date().getFullYear(); i >= minYear; i--) {
    years.push({
      key: `${i}`,
      value: `${i}`,
      label: `${i}`,
    });
  }

  return years;
};

export const formatList = (value: string) => {
  if (value && value.includes("\n\n")) {
    const groups = value.split("\n\n");
    return groups.map((group) =>
      group && group.includes("\n") ? group.split("\n") : group
    );
  } else {
    return value && value.includes("\n") ? value.split("\n") : value;
  }
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const toJson = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const sanitizeFilename = (filename: string) => {
  const uuidRegex =
    /\b[\da-fA-F]{8}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{12}\b/;
  const sanitizedFilename = filename
    .replace(/"/gi, "")
    .replace(uuidRegex, "")
    .replace(" .xlsx", ".xlsx");
  return sanitizedFilename;
};

export const deepClone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export function capitalizeWords(str: string) {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}
