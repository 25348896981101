import s from "./TableFilters.module.scss";
import { IPropsFilter } from "./TableFilters";
import { CheckboxList } from "components/common/CheckboxList/CheckboxList";
import { IOption } from "interfaces";

interface IProps extends IPropsFilter {
  options: IOption[];
}

export const CheckboxFilter = ({
  name,
  label,
  filters,
  setFilters,
  options,
}: IProps) => {
  const getCheckboxList = (value: string, arr: string[]) => {
    return arr.includes(value)
      ? arr.filter((i) => i !== value)
      : [...arr, value];
  };

  const handleChange = (value: string) => {
    const checkboxList = getCheckboxList(
      value,
      filters[name]?.checkboxList || []
    );

    setFilters({
      ...filters,
      [name]: {
        ...filters[name],
        notEmpty: !!checkboxList?.length,
        checkboxList,
      },
    });
  };

  return (
    <div className={s.item}>
      <span className={s.label}>{label}</span>
      <CheckboxList
        list={options}
        handleChange={handleChange}
        checkedList={filters[name]?.checkboxList || []}
      />
    </div>
  );
};
