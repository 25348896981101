import { ISection, MenuActions, Roles } from "interfaces";
import {
  AccountingRoute,
  DashboardsRoute,
  HistoryRoute,
  ImportsRoute,
  KasfValuationModelRoute,
  ManageUsersRoute,
  PermissionsRoute,
  PledgeCollateralRoute,
  RecordManagement,
  SettingsRoute,
} from "./routes";

export const configRouting = [
  {
    parent: "Dashboards",
    label: "Securitization & Investment Tapes",
    link: DashboardsRoute.SECURITIZATION,
  },
  {
    parent: "Dashboards",
    label: "Portfolio Dashboard",
    link: DashboardsRoute.PORTFOLIO,
  },
  {
    parent: "Dashboards",
    label: "All MSAs HPA",
    link: DashboardsRoute.ALL_MSAS_HPA,
  },
  {
    parent: "Dashboards",
    label: "SP-1",
    link: DashboardsRoute.ACQUIRED_PORTFOLIO,
  },
  {
    parent: "Dashboards",
    label: "Point Buy Box Dashboard",
    link: DashboardsRoute.POINT_BUY_BOX,
  },
  {
    parent: "Dashboards",
    label: "PNT2023-2 Dashboard",
    link: DashboardsRoute.PNT2023_2,
  },
  {
    parent: "Dashboards",
    label: "Pledge Collateral Dashboard",
    link: DashboardsRoute.PLEDGE_COLLATERAL,
  },
  {
    parent: "Dashboards",
    label: "Exit Dashboard",
    link: DashboardsRoute.EXIT,
  },
  {
    parent: "Imports",
    label: "Demographics",
    link: ImportsRoute.DEMOGRAPHICS_DATA,
  },
  {
    parent: "Imports",
    label: "Default Monitoring",
    link: ImportsRoute.DEFAULT_MONITORING,
  },
  {
    parent: "Imports",
    label: "Detail",
    link: ImportsRoute.DETAIL,
  },
  {
    parent: "Imports",
    label: "FHFA",
    link: ImportsRoute.FHFA,
  },
  {
    parent: "Imports",
    label: "Monthly Collections",
    link: ImportsRoute.MONTHLY_COLLECTIONS,
  },
  {
    parent: "Imports",
    label: "OON",
    link: ImportsRoute.OON,
  },
  {
    parent: "Imports",
    label: "Origination Fees Sweep",
    link: ImportsRoute.ORIGINATION_FEES_SWEEP,
  },
  {
    parent: "Imports",
    label: "Preliminary Exits",
    link: ImportsRoute.PRELIMINARY_EXITS,
  },
  {
    parent: "Imports",
    label: "Tape",
    link: ImportsRoute.TAPE,
  },
  {
    parent: "Imports",
    label: "Title Policy",
    link: ImportsRoute.TITLE_POLICY,
  },
  {
    parent: "Imports",
    label: "3rd Party Report",
    link: ImportsRoute.THIRD_PARTY_REPORTS,
  },
  {
    parent: "Valuation",
    label: "Asset List",
    link: KasfValuationModelRoute.ASSET_LIST,
  },
  {
    parent: "Valuation",
    label: "Forward Cashflows",
    link: KasfValuationModelRoute.FORWARD_CASHFLOWS,
  },
  {
    parent: "Valuation",
    label: "HPA Contract",
    link: KasfValuationModelRoute.HPA_CONTRACT,
  },
  {
    parent: "Valuation",
    label: "HPA Metro",
    link: KasfValuationModelRoute.HPA_METRO,
  },
  {
    parent: "Valuation",
    label: "Internal Valuation Comparison Report",
    link: KasfValuationModelRoute.INTERNAL_VALUATION_COMPARISON_REPORT,
  },
  {
    parent: "Valuation",
    label: "KASF internal valuation",
    link: KasfValuationModelRoute.KASF_INTERNAL_VALUATION,
  },
  {
    parent: "Valuation",
    label: "Official Run Selection",
    link: KasfValuationModelRoute.OFFICIAL_RUN_SELECTION,
  },
  {
    parent: "Valuation",
    label: "3rd Party Comparison report",
    link: KasfValuationModelRoute.THIRD_PARTY_COMPARISON_REPORT,
  },
  {
    parent: "Pledge Collateral",
    label: "Available to Pledge",
    link: PledgeCollateralRoute.PLEDGE_DEBT_LINE,
  },
  {
    parent: "Pledge Collateral",
    label: "Pledge List",
    link: PledgeCollateralRoute.PLEDGE_LIST,
  },
  {
    parent: "Pledge Collateral",
    label: "SMM and Resolution Rates",
    link: PledgeCollateralRoute.SMM_RESOLUTION_RATES,
  },
  {
    parent: "Accounting",
    label: "Actual Returns Report",
    link: AccountingRoute.ACTUAL_RETURNS_REPORT,
  },
  {
    parent: "Accounting",
    label: "Exits Variance Report",
    link: AccountingRoute.EXITS_VARIANCE_REPORT,
  },
  {
    parent: "Accounting",
    label: "Gross Monthly Returns",
    link: KasfValuationModelRoute.GROSS_MONTHLY_RETURNS,
  },
  {
    parent: "Accounting",
    label: "Impaired List",
    link: AccountingRoute.IMPARED_LIST,
  },
  {
    parent: "Accounting",
    label: "Monthly Rolls Report",
    link: AccountingRoute.MONTHLY_ROLLS,
  },
  {
    parent: "Accounting",
    label: "Payoffs Report",
    link: AccountingRoute.PAYOFFS_REPORT,
  },
  {
    parent: "Record Management",
    label: "Delete Contract Details",
    link: RecordManagement.DELETE_CONTRACT_DETAILS,
  },
  {
    parent: "Record Management",
    label: "Look Up Contract Details",
    link: RecordManagement.LOOK_UP_CONTRACT_DETAILS,
  },
  {
    parent: "Record Management",
    label: "Pay-off Demand",
    link: RecordManagement.PAYOFF_DEMAND,
  },
  {
    parent: "Record Management",
    label: "Preliminary Exit",
    link: RecordManagement.PRELIMINARY_EXIT,
  },
  {
    parent: "Record Management",
    label: "Update Contract Details",
    link: RecordManagement.UPDATE_CONTRACT_DETAILS,
  },
  {
    parent: "",
    label: "History",
    link: HistoryRoute,
  },
  {
    parent: "",
    label: "Manage Users",
    link: ManageUsersRoute.ROOT,
  },
  {
    parent: "",
    label: "Settings",
    link: SettingsRoute,
  },
] as const;

export const navigation: ISection[] = [
  {
    id: 1,
    nav: [
      {
        id: 1,
        label: "Dashboards",
        link: "",
        icon: "pie-chart",
        action: MenuActions.toggle,
        child: configRouting.filter(({ parent }) => parent === "Dashboards"),
      },
      {
        id: 2,
        label: "Imports",
        link: "",
        icon: "imports",
        action: MenuActions.toggle,
        child: configRouting.filter(({ parent }) => parent === "Imports"),
      },
      {
        id: 3,
        label: "Valuation",
        link: "",
        icon: "pledge",
        action: MenuActions.toggle,
        child: configRouting.filter(({ parent }) => parent === "Valuation"),
      },
      {
        id: 4,
        label: "Pledge Collateral",
        link: "",
        icon: "bank",
        action: MenuActions.toggle,
        child: configRouting.filter(
          ({ parent }) => parent === "Pledge Collateral"
        ),
      },
      {
        id: 5,
        label: "Accounting",
        link: "",
        icon: "calculator",
        action: MenuActions.toggle,
        child: configRouting.filter(({ parent }) => parent === "Accounting"),
      },
      {
        id: 6,
        label: "Record Management",
        link: "",
        icon: "union",
        action: MenuActions.toggle,
        child: configRouting.filter(
          ({ parent }) => parent === "Record Management"
        ),
      },
      {
        id: 7,
        label: "History",
        link: HistoryRoute,
        icon: "clock",
        action: null,
        child: null,
      },
    ],
  },
  {
    id: 2,
    nav: [
      {
        id: 0,
        label: "Manage Users",
        link: ManageUsersRoute.ROOT,
        icon: "users",
        action: null,
        child: null,
        permissions: [Roles.Admin, Roles.SuperAdmin],
      },
      {
        id: 1,
        label: "Permissions",
        link: PermissionsRoute,
        icon: "crown",
        action: null,
        child: null,
        permissions: [Roles.SuperAdmin],
      },
      {
        id: 2,
        label: "Settings",
        link: SettingsRoute,
        icon: "settings",
        action: null,
        child: null,
        permissions: [Roles.User, Roles.Admin, Roles.SuperAdmin],
      },
      {
        id: 3,
        label: "Log Out",
        link: "",
        icon: "log-out",
        action: MenuActions.logout,
        child: null,
      },
    ],
  },
];
