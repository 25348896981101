import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_ASSET_LIST } from "const";
import { OfficialRunAssetListType } from "store/types/OfficialRunAssetList";
import { getQueryText } from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const getList = createAsyncThunk(
  "kwm/officialAssetList",
  async (
    _payload: {
      _type: string;
      model: string;
      date_from: string;
      date_to: string;
    },
    thunkApi,
  ) => {
    try {
      const cookies = new Cookies();
      const { _type, model, date_from, date_to } = _payload;
      const queryParams = getQueryText([
        { name: "_type", value: _type },
        { name: "model", value: model },
        { name: "date_from", value: date_from },
        { name: "date_to", value: date_to },
      ]);

      const response = await fetchWithAuth(`${GET_ASSET_LIST}?${queryParams}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(parsed as OfficialRunAssetListType);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

export { getList };
