import React from "react";
import { useTable } from "react-table";
import { TableRows } from "./TableRows";
import s from "./Table.module.scss";

type TableProps = {
  columns: any;
  data: any;
  headerLineText: string;
};

export const TableRowsMonth: React.FC<TableProps> = ({
  columns,
  data,
  headerLineText,
}) => {
  const { rows, prepareRow } = useTable({
    columns,
    data,
  });
  rows.forEach(row => prepareRow(row))

  return (
    <>
      <tr>
        <td className={s.monthName} colSpan={columns.length}>
          {headerLineText}
        </td>
      </tr>
      <TableRows rows={rows} isLoading={false}/>
    </>
  );
};
