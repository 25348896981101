import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Dashboard.module.scss";
import { TabPanel } from "components/common";
import { securitizationTabs } from "const";
import { TableauEmbedding } from "components/modules/TableauEmbedding/TableauEmbedding";
import { useEffect, useRef } from "react";
import useScript from "react-script-hook";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getTableauToken } from "store/actions/DashboardsActions";

export const DashboardSecuritization = () => {
  // const [loading, error] = useScript({
  //   src: "https://10az.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js",
  //   type: "module",
  // });

  // const activeLinkRef = useRef<{ activeLink: string } | null>({
  //   activeLink: securitizationTabs[0].link,
  // });

  // const { token } = useAppSelector((state) => state.actionsDashboards);

  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getTableauToken({}));
  // }, []);

  // console.log(token);

  // if (loading) return <h3>Loading...</h3>;
  // if (error)
  //   return <h3>Failed to load Tableau Embedding API: {error.message}</h3>;

  return (
    <PageContent title="Securitization & Investment Tapes" path="Dashboards">
      {/* <TabPanel tabsList={securitizationTabs} ref={activeLinkRef}>
        <>
          {activeLinkRef?.current && token && (
            <TableauEmbedding
              src={activeLinkRef?.current.activeLink}
              token={token}
            />
          )}
        </>
      </TabPanel> */}
      <div className={s.container}>
        <iframe
          title="dashboards"
          src={securitizationTabs[0].link}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </PageContent>
  );
};
