import { useEffect, useState } from "react";
import { useAppDispatch } from "./reduxHooks";

export const useGoBackInUpdateForm = (
  dataItem: string,
  resetData: () => void
) => {
  const [secondScreenMode, setSecondScreenMode] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dataItem) {
      setSecondScreenMode(true);
    } else setSecondScreenMode(false);
  }, [dataItem]);

  const onGoBack = () => {
    setSecondScreenMode(false);
    dispatch(resetData());
  };

  return { secondScreenMode, onGoBack };
};
