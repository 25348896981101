import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, UploadNotification } from "components/modules";
import {
  INTERNAL_VALUATION_COMPARISON_REPORT,
  DOWNLOAD_KASF_MODEL,
  MONTH_NAMES,
} from "const";
import { useLocalStorage } from "hooks/useLocalStorage";
import { usePostQuery } from "hooks/usePostQuery";
import { useFormSelect } from "hooks/useFormSelect";
import moment from "moment";
import { useState, useEffect } from "react";
import {
  encodeDataToUrl,
  downloadFileWithToken,
  getUploadStatus,
  getUploadStatusStorage,
  getLoading,
  getYearsOptions,
} from "utils";

export const InternalValuationComparisonReport = () => {
  const pageName = "internal_valuation_comparison_report";

  const { value: year, handleSelect: handleSelectYear } = useFormSelect(
    new Date().getFullYear().toString()
  );

  const initailMonth = MONTH_NAMES.find(
    ({ key }) =>
      key ===
      `${
        String(new Date().getMonth())?.length > 1
          ? String(new Date().getMonth())
          : "0" + String(new Date().getMonth())
      }`
  )?.value;

  const { value: month, handleSelect: handleSelectMonth } = useFormSelect(
    initailMonth || MONTH_NAMES[0].value
  );

  const [showUploadNotification, setShowUploadNotification] = useState(
    localStorage.getItem(pageName) || false
  );

  const getInitialFileData = () => ({
    file_name: localStorage.getItem(pageName)
      ? JSON.parse(localStorage.getItem(pageName) || "")?.fileName
      : "",
  });

  const [generatedFile, setGeneratedFile] = useState(getInitialFileData());

  const { changeStorage } = useLocalStorage(pageName, () => {
    setGeneratedFile(getInitialFileData());
  });

  const { post, loading, responseStatus, error, responseData } = usePostQuery(
    INTERNAL_VALUATION_COMPARISON_REPORT,
    changeStorage
  );

  useEffect(() => {
    setGeneratedFile({ file_name: responseData?.file_name });
  }, [responseData]);

  const generateReportFile = async () => {
    setGeneratedFile({ file_name: null });
    setShowUploadNotification(false);
    const monthText = MONTH_NAMES.find(({ value }) => value === month)?.key;

    const dateValue = `${year}-${monthText}-${moment(`${year}-${month}`)
      .endOf("month")
      .format("DD")}`;

    const query = encodeDataToUrl({ date: dateValue });

    setShowUploadNotification(true);

    try {
      await post("", query, pageName);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async () => {
    try {
      if (generatedFile?.file_name) {
        const query = encodeDataToUrl({ file: generatedFile.file_name });

        await downloadFileWithToken(
          generatedFile.file_name,
          `${DOWNLOAD_KASF_MODEL}?${query}`
        );
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const status = getUploadStatus(loading, responseStatus);

  const renderRightContent = () => {
    return (
      <UploadNotification
        status={getUploadStatusStorage(pageName, status)}
        fileName={generatedFile?.file_name}
        closeNotification={() => setShowUploadNotification(false)}
        pageName={pageName}
        pageTitle="KASF Internal Comparison"
        handleDownload={handleDownload}
        disabledDownload={!generatedFile?.file_name}
      />
    );
  };

  return (
    <PageContent title="Internal Valuation Comparison Report" path="Valuation">
      <PageFormLayout
        rightContent={showUploadNotification ? renderRightContent() : null}
      >
        <>
          <FormBlock
            fields={{
              select1: {
                options: getYearsOptions(2010),
                value: year,
                onSelect: handleSelectYear,
                fieldName: "Select Year",
              },
              select2: {
                options: MONTH_NAMES,
                value: month,
                onSelect: handleSelectMonth,
                fieldName: "Select Previous Month",
              },
            }}
            importButton={{
              label: "Generate comparison report",
              loading: getLoading(loading, pageName),
              loadingText: "GENERATING...",
              onclick: generateReportFile,
            }}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
