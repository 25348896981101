import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_EXITS_VARIANCE_LIST } from "const";
import { getQueryText } from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const getList = createAsyncThunk(
  "kwm/exitsVarianceList",
  async (
    _payload: {
      model: string;
      date_from: string;
      date_to: string;
    },
    thunkApi
  ) => {
    try {
      const cookies = new Cookies();
      const { model, date_from, date_to } = _payload;
      const queryParams = getQueryText([
        { name: "model", value: model },
        { name: "date_from", value: date_from },
        { name: "date_to", value: date_to },
      ]);

      const response = await fetchWithAuth(
        `${GET_EXITS_VARIANCE_LIST}?${queryParams}`,
        {
          method: "get",
          headers: {
            authorization: "Bearer " + cookies.get("token"),
          },
        }
      );
      const parsed = await response.json();
      const { data } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(data);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getList };
