import s from "./TableSearch.module.scss";
import cn from "classnames";
import { Icon } from "../../common/Icon/Icon";
import { ChangeEvent, useContext, useEffect } from "react";
import { InputSearch } from "../../common/FormElements/InputSearch";
import { SearchContext } from "context/SearchContext";

type Props = {
  className?: string;
  width?: number;
  setSearch?: (val: string) => void;
};

export const TableSearch = ({ className, width = 300 }: Props) => {
  const { search, setSearch } = useContext(SearchContext);

  useEffect(() => {
    return () => setSearch("");
  }, []);

  return (
    <div
      className={cn(s.container, className && className)}
      style={{ width: `${width}px` }}
    >
      <InputSearch
        value={search}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />
      <button className={s.btn}>
        <Icon icon="search" />
      </button>
    </div>
  );
};
