import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Settings.module.scss";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useState, useEffect, ChangeEvent } from "react";
import { updateUser } from "store/actions/UserActions";
import { Button, CloseBtn, Input } from "components/common";
import { ModalChangePassword } from "./ModalChangePassword";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import cn from "classnames";

export const Settings = () => {
  const initialState = {
    firstName: "",
    email: "",
    lastName: "",
    phone: "",
  };

  const modalsInitialState = {
    changePassword: false,
    result: false,
  };

  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState(initialState);
  const [modals, setModals] = useState(modalsInitialState);

  const { user, isLoading } = useAppSelector((state) => state.user);

  const { firstName, email, lastName, phone } = formValues;
  const { changePassword, result } = modals;

  const handleInputChange = (value: string, field: string) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const modalStateHandler = (
    name: keyof typeof modalsInitialState,
    value: boolean
  ) => {
    setModals((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = () => {
    dispatch(
      updateUser({
        first_name: firstName,
        email: email,
        last_name: lastName,
        phone_number: phone,
      })
    );
  };

  useEffect(() => {
    if (user && user.email) {
      setFormValues(user);
    }
  }, [user]);

  const handleOpenChangePassword = () => {
    modalStateHandler("result", false);
    modalStateHandler("changePassword", true);
  };

  const renderRightContent = () => {
    return (
      <div className={s.notification}>
        <span className={s.notifTitle}>New Password</span>
        <p>had been successfully saved!</p>
        <CloseBtn onClose={() => modalStateHandler("result", false)} />
      </div>
    );
  };

  return (
    <PageContent title="Account Settings" history={false}>
      <PageFormLayout
        rightContent={result ? renderRightContent() : null}
        widthLeft={60}
      >
        <>
          <h2 className={cn("h3", s.title)}>Personal Information</h2>
          <div className={s.form}>
            <div className={s.fields}>
              <div className={s.field}>
                <Input
                  label="First Name"
                  value={firstName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e.target.value, "firstName")
                  }
                />
              </div>
              <div className={s.field}>
                <Input
                  label="Email"
                  value={email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e.target.value, "email")
                  }
                  disabled={true}
                />
              </div>
              <div className={s.field}>
                <Input
                  label="Last Name"
                  value={lastName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e.target.value, "lastName")
                  }
                />
              </div>
              <div className={s.field}>
                <Input
                  label="Phone Number"
                  value={phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e.target.value, "phone")
                  }
                />
              </div>
            </div>
            <div className={s.field}>
              <Button
                disabled={isLoading}
                title={isLoading ? "Processing..." : "SAVE CHANGES"}
                onClick={onSubmit}
                wide="long"
                uppercase
              />
            </div>
          </div>
          <div className={s.form}>
            <h2 className={cn("h3", s.title)}>Password</h2>
            <Button
              title="Change password"
              size="xs"
              filled="grayDark"
              icon="lock"
              onClick={handleOpenChangePassword}
            />
          </div>
          <ModalChangePassword
            isOpen={changePassword}
            onClose={() => modalStateHandler("changePassword", false)}
            afterSuccess={() => {
              modalStateHandler("result", true);
            }}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
