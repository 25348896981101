import isEqual from "lodash/isEqual";

export const getValueChangeInputPercentage = (value: string) => {
  let sanitizedValue = value.replace(/[^\d.]/g, ""); // Removing all characters except digits and dots
  sanitizedValue = sanitizedValue.replace(/^0+(\d)/, "$1"); // Removing leading zeros
  sanitizedValue = sanitizedValue.replace(/(\.\d\d)\d+/, "$1"); // Limiting to two decimal places
  sanitizedValue = sanitizedValue || "0"; // Replacing empty value with 0
  sanitizedValue = parseFloat(sanitizedValue) > 100 ? "100" : sanitizedValue; // Limiting to 100
  sanitizedValue += "%"; // Adding the percentage symbol
  return sanitizedValue;
};

export const getValueKeyDownInputPercentage = (
  value: string,
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  if (
    typeof value === "string" &&
    (event.key === "ArrowUp" || event.key === "ArrowDown" || event.key == "Backspace")
  ) {
    event.preventDefault();
    const currentValue = parseFloat(value.replace(/[^0-9.]/g, "")) || 0;
    let newValue = 0;

    if (event.key === "ArrowUp") {
      newValue = Math.min(100, currentValue + 0.01);
    } else if (event.key === "ArrowDown") {
      newValue = Math.max(0, currentValue - 0.01);
    } else if (event.key == "Backspace") {
      const asStr = `${currentValue}`
      return `${asStr.slice(0, asStr.length - 1)}%`;
    }

    const roundedValue = newValue.toFixed(2);

    return `${roundedValue}%`;
  }
  return null;
};

export const getValueKeyDownInputPercentageWithNegative = (
  value: string,
  event: React.KeyboardEvent<HTMLInputElement>,
  step = 0.01
) => {
  if (
    typeof value === "string" &&
    (event.key === "ArrowUp" || event.key === "ArrowDown")
  ) {
    event.preventDefault();
    const currentValue = parseFloat(value.replace(/[^0-9.-]/g, "")) || 0;

    let newValue = 0;

    if (event.key === "ArrowUp") {
      newValue = Math.min(100, currentValue + step);
    } else if (event.key === "ArrowDown") {
      newValue = Math.max(-100, currentValue - step);
    }

    const roundedValue = newValue.toFixed(2);

    return `${roundedValue}%`;
  }
  return null;
};

export const getValueChangeInputAmount = (value: string) => {
  let sanitizedValue = value.replace(/[^\d.]/g, ""); // Removing all characters except digits and dots
  sanitizedValue = sanitizedValue.replace(/^0+(\d)/, "$1"); // Removing leading zeros
  sanitizedValue = sanitizedValue.replace(/(\.\d\d)\d+/, "$1"); // Limiting to two decimal places
  // sanitizedValue = sanitizedValue || "0"; // Replacing empty value with 0

  const [integerPart, decimalPart] = sanitizedValue.split(".");

  const formattedIntegerPart = integerPart === "" ? "" : integerPart;

  const formattedDecimalPart =
    decimalPart !== undefined ? `.${decimalPart.slice(0, 2)}` : "";

  return (
    formattedIntegerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    formattedDecimalPart
  );
};

export const checkDirty = (
  initialValues: any,
  values: any,
  field: string,
  value: string | null
) => {
  if (initialValues[field] !== value) {
    return true;
  } else if (
    isEqual(
      Object.entries(initialValues).filter((item) => item[0] !== field),
      Object.entries(values).filter((item) => item[0] !== field)
    )
  ) {
    return false;
  }
};

export const displayError = (error: any) => {
  if (typeof error === "object" && error.message) {
    return error.message;
  }
};

export const getInitialAmount = (value: number | null) => {
  return value || value === 0
    ? value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : "";
};

export const getInitialPercent = (value: number | null) => {
  return value || value === 0 ? `${value}%` : "%";
};

export const formatAmountToNumber = (value: string) => {
  return +parseFloat(value.replace(/[^0-9.]/g, "")).toString();
};
