import { Select } from "components/common";
import { IOption } from "interfaces";
import s from "./TableFilters.module.scss";
import { IPropsFilter } from "./TableFilters";

interface IProps extends IPropsFilter {
  filterSelectOptions: IOption[];
}

export const SelectFilter = ({
  name,
  label,
  filterSelectOptions,
  filters,
  setFilters,
}: IProps) => {
  const handleSelect = (value: string) => {
    setFilters({
      ...filters,
      [name]: { ...filters[name], notEmpty: Boolean(value), equal: value },
    });
  };

  return (
    <div className={s.item}>
      <span className={s.label}>{label}</span>
      <Select
        options={filterSelectOptions}
        onSelect={(val) => handleSelect(val)}
        placeholder="Select"
        value={filters[name]?.equal}
      />
    </div>
  );
};
