import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { CANCEL_IMPARED_LIST, GET_IMPAIRED_LIST } from "const";
import { encodeDataToUrl } from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const cookies = new Cookies();

const getList = createAsyncThunk(
  "kwm/impairedList",
  async (
    _payload: {
      originator: string;
      filing_type: string;
      sale_discount: string;
      foreclosure_costs: string;
      filing_date_from: string;
      filing_date_to: string;
    },
    thunkApi
  ) => {
    try {
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(`${GET_IMPAIRED_LIST}?${queryParams}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();
      const { contracts } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(contracts);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const cancelImpairedList = createAsyncThunk(
  "kwm/impairedList/cancel",
  async (
    _payload: {
      list_value: {
        contract_name: string;
        filing_type: string;
        cured_date: string;
      }[];
    },
    thunkApi
  ) => {
    try {
      const response = await fetchWithAuth(`${CANCEL_IMPARED_LIST}`, {
        method: "put",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(_payload),
      });

      await response.json();

      return thunkApi.fulfillWithValue({});
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { getList, cancelImpairedList };
