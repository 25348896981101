import React, { forwardRef, useMemo, useCallback } from "react";
import { useTable } from "react-table";
import s from "./Table.module.scss";
import { TableRows } from "./TableRows";
import cn from "classnames";
import _ from "lodash";

interface IProps {
    columns: any[];
    data: any[];
    fixedFirstCol?: boolean;
    isLoading?: boolean;
}

// Define the Table component
const TableComponent = forwardRef<any, IProps>(
    ({ columns, data, isLoading, fixedFirstCol = false }, ref) => {

        //@ts-ignore
        const listContainerRef = ref?.listContainerRef;
        //@ts-ignore
        const listRef = ref?.listRef;

        // Initialize table instance with columns and data
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
            useTable({
                columns,
                data,
            });
        rows.forEach(row => prepareRow(row))

        // Memoize rows and prepareRow function
        const memoizedRows = useMemo(() => rows, [rows]);

        const colCount = headerGroups ? headerGroups[0].headers.length : undefined;

        return (
            <div className={s.containerVertical}>
                <div className={s.containerHorizontal} ref={listContainerRef}>
                    <table
                        {...getTableProps()}
                        className={cn(s.table, fixedFirstCol && s.fixedFirstCol)}
                        ref={listRef}
                    >
                        <thead className={s.head}>
                        {headerGroups?.map((headerGroup, u) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={headerGroup.id + `${u}`}
                            >
                                {headerGroup.headers.map((column, u) => (
                                    <th {...column.getHeaderProps()} key={`${u}` + column.id}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        <TableRows rows={memoizedRows} isLoading={isLoading}
                                   colCount={colCount} checkForRender={true}/>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
);

export const Table = React.memo(TableComponent, (prevProps, nextProps) => {
    const columnsEqual = _.isEqual(prevProps.columns, nextProps.columns);
    const dataEqual = _.isEqual(prevProps.data, nextProps.data);
    const fixedFirstColEqual = prevProps.fixedFirstCol === nextProps.fixedFirstCol;
    const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
    return columnsEqual && dataEqual && fixedFirstColEqual && isLoadingEqual;
});
