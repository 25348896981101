import { useState } from "react";

export const useFormSelectFile = (callbackUpload: () => void) => {
  const [value, setValue] = useState<File | null>(null);

  const handleFileUpload = (val: File) => {
    callbackUpload();
    setValue(val);
  };

  const handleFileRemove = () => {
    setValue(null);
  };

  return { value, handleFileUpload, handleFileRemove };
};
