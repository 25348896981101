import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlockSearchByContract } from "components/modules/FormBlock/FormBlockSearchByContract";
import { FormUpdateContract } from "components/modules/FormUpdateData/FormUpdateContract";
import { SuccessNotification } from "components/modules/UploadNotification/SuccessNotification";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useGoBackInUpdateForm } from "hooks/useGoBackInUpdateForm";
import { useEffect } from "react";
import { getUpdateContractData } from "store/actions/UpdateContractActions";
import { resetData, resetUpdated } from "store/reducers/UpdateContractSlice";
import { ListSkeleton } from "../../modules";

type Props = {};
export const UpdateContractDetails = (props: Props) => {
  const { data, isLoading, isUpdated } = useAppSelector(
    (state) => state.updateContract
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  const { secondScreenMode, onGoBack } = useGoBackInUpdateForm(
    data.contract_id,
    resetData
  );

  const renderRightContent = () => {
    return (
      <SuccessNotification
        title="The Database"
        description="had been successfully updated!"
        onClose={() => dispatch(resetUpdated())}
      />
    );
  };

  return (
    <PageContent
      title="Update Contract Details"
      path="Record Management"
      secondScreenMode={secondScreenMode}
      onGoBack={onGoBack}
    >
      <PageFormLayout rightContent={isUpdated ? renderRightContent() : null}>
        <>
          <FormBlockSearchByContract
            getDataAction={getUpdateContractData}
            resetUpdated={resetUpdated}
            isLoading={isLoading}
          />
          {!isLoading ? (
              secondScreenMode && data.contract_id && (
                  <FormUpdateContract
                      data={data}
                      isLoading={isLoading}
                      isUpdated={isUpdated}
                  />
              )
          ) : (
              <ListSkeleton count={13}></ListSkeleton>
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
