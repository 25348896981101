import { createSlice } from '@reduxjs/toolkit'
import { getList } from '../actions/ExitsVarianceList'
import { ExitsVarianceListType } from 'store/types/ExitsVarianceListType'

const initialState = {
    isLoading: false,
    error: null,
    list: [],
} as ExitsVarianceListType

export const ExitsVarianceListSlice = createSlice({
    name: 'exitsVarianceList',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload
        },
        resetList: (state) => {
            state.list = []
        },
    },
    extraReducers: {
        [getList.fulfilled.type]: (state, action) => {
            state.isLoading = false
            state.list = action.payload
            state.error = null
        },
        [getList.pending.type]: (state) => {
            state.isLoading = true
        },
        [getList.rejected.type]: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
    },
})

export const { setError, resetList } = ExitsVarianceListSlice.actions

export default ExitsVarianceListSlice.reducer
