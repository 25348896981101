import React from "react";
import { useTable } from "react-table";
import { TableRowsMonth } from "./TableRowsMonth";
import s from "./Table.module.scss";
import cn from "classnames";

type IProps = {
  columns: any;
  data: any;
  fixedFirstCol: boolean;
};

export const TableWithMonths = ({ columns, data, fixedFirstCol }: IProps) => {
  const { getTableProps, getTableBodyProps, headerGroups } = useTable({
    columns,
    data: data.length ? data[0]?.group : [],
  });

  return (
    <div className={s.containerVertical}>
      <div className={s.containerHorizontal}>
        <table
          {...getTableProps}
          className={cn(s.table, fixedFirstCol && s.fixedFirstCol)}
        >
          <thead className="fixedHeader">
            {headerGroups?.map((headerGroup, u) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id + `${u}`}
              >
                {headerGroup.headers.map((column, u) => (
                  <th
                    {...column.getHeaderProps()}
                    key={`${u}` + column.id}
                    style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data.map((month: any) => (
              <TableRowsMonth
                key={month.headerLineText}
                columns={columns}
                data={month.group}
                headerLineText={month.headerLineText}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
