import { forwardRef } from "react";
import s from "./Checkbox.module.scss";
import cn from "classnames";

type IProps = {
  label?: string;
  checked?: boolean;
  onChange: () => void;
  type?: "square" | "circle";
  className?: string;
  disabled?: boolean;
};

export const Checkbox = forwardRef<any, IProps>(
  ({ label, checked, onChange, type = "square", className, disabled }, ref) => {
    return (
      <label className={s.container}>
        <input
          ref={ref}
          type="checkbox"
          className={cn(s.input, s[type], className && s[className])}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        {label && <span className={s.label}>{label}</span>}
      </label>
    );
  }
);
