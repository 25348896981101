import { createSlice } from "@reduxjs/toolkit";
import {
  getPayoffDemandData,
  getSearchValues,
  updatePayoffDemandData,
} from "store/actions/PayoffDemandActions";
import { PayoffDemandType } from "store/types/PayoffDemandType";

const initialData = {
  contract_id: "",
  servicer_account_number: "",
  address: "",
  investment_amount: null,
  payoff_requested_date: "",
  payoff_valid_date: "",
  appreciation_share: null,
  payoff_home_amount: null,
  payoff_home_value: null,
  hec_id: "",
  property_status: "",
};

const initialState = {
  isLoading: false,
  error: null,
  searchData: {
    addresses: [],
    contract_ids: [],
    service_numbers: [],
    preliminary_contracts: [],
    lookup_contracts: [],
  },
  data: initialData,
  propertyStatuses: [],
  isUpdated: false,
} as PayoffDemandType;

export const PayoffDemandSlice = createSlice({
  name: "payoffDemand",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetData: (state) => {
      state.data = initialData;
    },
    resetUpdated: (state) => {
      state.isUpdated = false;
    },
  },
  extraReducers: {
    [getSearchValues.fulfilled.type]: (state, action) => {
      state.searchData = action.payload;
      state.error = null;
    },
    [getSearchValues.rejected.type]: (state, action) => {
      state.error = action.payload;
    },
    [getPayoffDemandData.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.contracts;
      state.propertyStatuses = action.payload.property_status;
      state.error = null;
    },
    [getPayoffDemandData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPayoffDemandData.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [updatePayoffDemandData.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.isUpdated = true;
      state.data = initialData;
      state.error = null;
    },
    [updatePayoffDemandData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updatePayoffDemandData.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetData, resetUpdated } = PayoffDemandSlice.actions;

export default PayoffDemandSlice.reducer;
