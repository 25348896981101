import { Button } from "components/common/Button/Button";
import React, { useRef, ChangeEvent, useState } from "react";
import s from "./UploadFile.module.scss";

interface UploadFilesProps {
  disabled?: boolean;
  multiple?: boolean;
  loading?: boolean;
  label?: string;
  bordered?: boolean;
  file: File | null;
  setFile: (file: File) => void;
}

export const UploadFile: React.FC<UploadFilesProps> = ({
  disabled = false,
  multiple = false,
  loading = false,
  label = "BROWSE",
  setFile,
}) => {
  const [fileName, setFileName] = useState("");
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile(e.target.files[0]);
    setFileName("");
  };

  return (
    <>
      <input
        ref={uploadInputRef}
        type="file"
        value={fileName}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple={multiple}
        disabled={disabled}
      />
      <div className={s.container}>
        <Button
          title={label}
          wide="long"
          disabled={disabled}
          onClick={() => uploadInputRef?.current?.click()}
          isLoading={loading}
          uppercase
        />
      </div>
    </>
  );
};
