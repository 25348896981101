import React from "react";
import cn from "classnames";

interface IProps {
  icon: string;
  className?: string;
  onClick?: () => void;
}

export const Icon: React.FC<IProps> = ({ icon, className, onClick }) => {
  return (
    <span
      className={cn(className, `icon-${icon}`)}
      aria-hidden="true"
      onClick={onClick}
    ></span>
  );
};
