const emailValidator = (email: string) => {
  if (!email) {
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    return "Please enter a valid email";
  }
  return "";
};

const nonEmptyField = (field: string) => {
  if (!field) {
    return `The field is required`;
  }
  return "";
};

const passwordsMatch = (password: string, repeating: string) => {
  if (password !== repeating) {
    return "Passwords do not match";
  }
  return "";
};

export { emailValidator, nonEmptyField, passwordsMatch };
