import { useState } from "react";
import {
  checkDirty,
  getValueChangeInputPercentage,
  getValueKeyDownInputPercentage,
} from "utils";

export const useFormInputPercentageArr = (defaultValues: any) => {
  const [valuesInputPercent, setValue] = useState(defaultValues);

  const [initialValues, setInitialValues] = useState(valuesInputPercent);

  const [valuesInputPercentDirty, setValuesDirty] = useState(false);

  const handleChangeInputPercent = (field: string, value: string) => {
    const isDirty = checkDirty(initialValues, valuesInputPercent, field, value);
    isDirty !== undefined && setValuesDirty(isDirty);

    setValue((valuesInputPercent: any) => ({
      ...valuesInputPercent,
      [field]: getValueChangeInputPercentage(value),
    }));
  };

  const handleKeyDown = (
    field: string,
    value: string,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const newValue = getValueKeyDownInputPercentage(value, event);

    const isDirty = checkDirty(
      initialValues,
      valuesInputPercent,
      field,
      newValue
    );
    isDirty !== undefined && setValuesDirty(isDirty);

    newValue &&
      setValue((valuesInputPercent: any) => ({
        ...valuesInputPercent,
        [field]: newValue,
      }));
  };

  const updateInitialInputPercent = () => {
    setInitialValues(valuesInputPercent);
    setValuesDirty(false);
  };

  const resetInputPercent = () => {
    setValue(initialValues);
    setValuesDirty(false);
  };

  return {
    valuesInputPercent,
    handleChangeInputPercent,
    handleKeyDown,
    valuesInputPercentDirty,
    updateInitialInputPercent,
    resetInputPercent,
  };
};
