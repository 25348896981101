import cn from "classnames";
import s from "./Accordion.module.scss";
import { ExpandIcon } from "./ExpandIcon";
import { useCollapse } from "react-collapsed";
import { useEffect, useRef, useState } from "react";

type Props = {
  title: JSX.Element;
  content: JSX.Element;
  togglerPosition?: "start" | "end";
  titleClass?: string;
  contentClass?: string;
  expandClass?: string;
  activeClass?: string | null;
};

export const AccordionItem = ({
  title,
  content,
  togglerPosition = "end",
  titleClass,
  contentClass,
  expandClass,
  activeClass,
}: Props) => {
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse();

  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isExpanded && ref.current?.clientHeight)
      setHeight(ref.current?.clientHeight);
  }, [isExpanded]);

  return (
    <>
      <div
        className={cn(
          s.title,
          titleClass && s[titleClass],
          activeClass && s[activeClass]
        )}
        {...getToggleProps()}
      >
        {togglerPosition === "start" && <ExpandIcon isOpen={isExpanded} />}
        {title}
        {togglerPosition === "end" && (
          <ExpandIcon isOpen={isExpanded} className={expandClass} />
        )}
      </div>
      <div
        className={contentClass && s[`${contentClass}Wrap`]}
        {...getCollapseProps()}
      >
        <div
          className={cn(s.content, contentClass && s[contentClass])}
          style={height ? { height } : {}}
          ref={ref}
        >
          {content}
        </div>
      </div>
    </>
  );
};
