import { InfoRow } from "components/common";
import React, { useEffect } from "react";
import { useState } from "react";
import { formatUSD } from "utils";

type Props = {
  val1: number;
  val2: number;
  val3: number;
  val4: number;
};

export const PledgeInfoRow: React.FC<Props> = ({ val1, val2, val3, val4 }) => {
  const [values, setValues] = useState({
    val1,
    val2,
    val3,
    val4,
  });

  const infolist = [
    {
      id: 1,
      title: "Total Eligible Contracts:",
      value: values.val1.toString(),
    },
    {
      id: 2,
      title: "Total Option Amount:",
      value: formatUSD(values.val2, false),
    },
    {
      id: 3,
      title: "Selected Contracts:",
      value: values.val3.toString(),
    },
    {
      id: 4,
      title: "Selected Contracts Option Amount:",
      value: formatUSD(values.val4, false),
    },
  ];

  useEffect(() => {
    setValues((values) => {
      return {
        ...values,
        val1,
        val2,
      };
    });
  }, [val1, val2]);

  useEffect(() => {
    setValues((values) => {
      return {
        ...values,
        val3,
        val4,
      };
    });
  }, [val3, val4]);

  return <InfoRow infoList={infolist} />;
};
