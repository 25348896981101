import { useState } from "react";

export const useFormSelectDate = (defaultValue: Date | null) => {
  const [value, setDate] = useState(defaultValue);

  const handleDateChange = (val?: Date | null) => {
    setDate(val || new Date());
  };

  const resetDate = () => {
    setDate(defaultValue);
  };

  return { value, handleDateChange, resetDate };
};
