import { UploadStatus } from "interfaces";

export const getUploadStatus = (
  loading: boolean,
  responseStatus: number | null
) => {
  if (loading) return UploadStatus.loading;
  if (responseStatus === 200) return UploadStatus.done;
  if (responseStatus && responseStatus < 500) return UploadStatus.failedData;
  return UploadStatus.failed;
};

export const getUploadStatusStorage = (item: string, status: UploadStatus) => {
  const itemState = JSON.parse(localStorage.getItem(item) || "");

  if (itemState) {
    if (itemState.loading) {
      return UploadStatus.loading;
    }

    if (itemState.error) {
      return itemState.responseStatus && itemState.responseStatus < 500
        ? UploadStatus.failedData
        : UploadStatus.failed;
    }
    return UploadStatus.done;
  }
  return status;
};

export const getFileName = (
  item: string,
  selectedFileName: string,
  status: UploadStatus,
  succeedFileName: string
) => {
  return localStorage.getItem(item)
    ? JSON.parse(localStorage.getItem(item) || "")?.fileName
    : selectedFileName || (status === "done" ? succeedFileName : null);
};

export const getInitialFileName = (pageName: string) => {
  return localStorage.getItem(pageName)
    ? JSON.parse(localStorage.getItem(pageName) || "")?.fileName
    : "";
};

export const getLoading = (loading: boolean, pageName: string) => {
  return loading || localStorage.getItem(pageName)
    ? JSON.parse(localStorage.getItem(pageName) || "")?.loading
    : false;
};
