import { useContext, useEffect, useState } from "react";

import { IChangeListParams } from "interfaces";
import { useDispatch } from "react-redux";
import { UpdateListContext } from "context/UpdateListContext";
import { useGetElementHeight } from "./useGetElementHeight";
import { getNotEmptyFilters } from "utils";

export const useGetListInfinity = ({
  total,
  getListAction,
  clearListAction,
  listContainer,
  listRef,
  limitList = 50,
  tableHeaderHeight = 0,
  // sort,
  search,
  filters,
}: IChangeListParams) => {
  const dispatch = useDispatch();

  const [listHeight] = useGetElementHeight(listRef);

  const { updateList, setUpdateList } = useContext(UpdateListContext);

  const [isFetching, setIsFetching] = useState(false);

  const [offset, setOffset] = useState(0);

  const getParams = () => {
    const getFilters = () => {
      const notEmpty = filters && getNotEmptyFilters(filters);

      return notEmpty?.length ? Object.fromEntries(notEmpty) : null;
    };

    return {
      size: limitList,
      // sort,
      search,
      filters: getFilters(),
    };
  };

  const dispatchAction = async () => {
    setOffset(0);
    setUpdateList(false);
    dispatch(clearListAction());
    await dispatch(getListAction(getParams()));
    setOffset(limitList);
  };

  const onScroll = () => {
    if (
      (listContainer &&
        listHeight &&
        listContainer?.offsetHeight + listContainer?.scrollTop < listHeight) ||
      isFetching
    ) {
      return;
    }

    setTimeout(() => setIsFetching(true), 300);
  };

  const checkIsListLessThanContainer = () => {
    if (
      listContainer &&
      listHeight &&
      listHeight !== tableHeaderHeight &&
      listContainer?.offsetHeight > listHeight
    ) {
      setIsFetching(true);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearListAction());
    };
  }, []);

  useEffect(() => {
    dispatchAction();
    // }, [filters, sort, search]);
  }, [search]);

  useEffect(() => {
    if (updateList) {
      dispatchAction();
    }
  }, [updateList]);

  // get other pages of list
  useEffect(() => {
    if (total && listHeight) {
      checkIsListLessThanContainer();
    }
  }, [total]);

  useEffect(() => {
    if (listHeight) {
      listContainer?.removeEventListener("scroll", onScroll);
      listContainer?.addEventListener("scroll", onScroll);
    }
    if (total && listHeight) {
      checkIsListLessThanContainer();
    }
    return () => {
      listContainer?.removeEventListener("scroll", onScroll);
    };
  }, [listHeight]);

  useEffect(() => {
    if (!isFetching || offset >= total) {
      setIsFetching(false);
      return;
    }

    const dispatchAction = async () => {
      await dispatch(
        getListAction({
          ...getParams(),
          size: limitList,
          page: (offset + limitList) / limitList,
        })
      );
      setOffset((offset) => offset + limitList);
      setIsFetching(false);
    };

    dispatchAction();
  }, [isFetching]);
};
