import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Users.module.scss";
import { Button, Checkbox, Input, Modal } from "components/common";
import { useNavigate, useParams } from "react-router-dom";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import cn from "classnames";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { emailValidator, nonEmptyField, passwordsMatch } from "utils";
import {
  getCurrentUser,
  editUser,
  createUser,
} from "store/actions/ManageUsersActions";
import { Roles } from "interfaces";
import { clearCurrentUser } from "store/reducers/ManageUsersSlice";
import isEqual from "lodash/isEqual";

type Props = {
  action: "add" | "edit";
};

export const UpdateUser = ({ action }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { users, currentUser, isLoading, error } = useAppSelector(
    (state) => state.manageUsers
  );

  const { id } = useParams();

  useEffect(() => {
    if (id && users) {
      const email = users?.find((user) => user.id === +id)?.email;

      dispatch(getCurrentUser({ email: email || "" }));
    }
  }, [id, users]);

  const initialState = {
    firstName: "",
    email: "",
    lastName: "",
    phone: "",
    newPassword: "",
    reNewPassword: "",
    admin: false,
  };

  const [formValues, setFormValues] = useState(initialState);

  const [initialValues, setInitialValues] = useState(formValues);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    newPassword: "",
    reNewPassword: "",
  });

  const [valuesDirty, setValuesDirty] = useState(false);

  const [isOpenModalGoBack, setIsOpenModalGoBack] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const initialValues = {
        firstName: currentUser.firstName,
        email: currentUser.email,
        lastName: currentUser.lastName,
        phone: currentUser.phone,
        admin: currentUser.role === Roles.Admin,
        newPassword: "",
        reNewPassword: "",
      };
      setFormValues(initialValues);
      setInitialValues(initialValues);
    }
  }, [currentUser]);

  const {
    firstName,
    email,
    lastName,
    phone,
    newPassword,
    reNewPassword,
    admin,
  } = formValues;

  const handleInputChange = <T extends keyof typeof formValues>(
    value: string,
    field: T,
    error: string
  ) => {
    const initialValue = currentUser ? initialValues[field] : "";

    if (initialValue !== value) {
      setValuesDirty(true);
    } else if (
      isEqual(
        Object.entries(initialValues).filter((item) => item[0] !== field),
        Object.entries(formValues).filter((item) => item[0] !== field)
      )
    ) {
      setValuesDirty(false);
    }

    setFormValues({
      ...formValues,
      [field]: value,
    });

    setValidationErrors({
      ...validationErrors,
      [field]: error,
    });
  };

  const handleCheckboxChange = () => {
    const initialValue = currentUser ? initialValues.admin : false;

    if (initialValue === admin) {
      setValuesDirty(true);
    } else if (
      isEqual(
        Object.entries(initialValues).filter((item) => item[0] !== "admin"),
        Object.entries(formValues).filter((item) => item[0] !== "admin")
      )
    ) {
      setValuesDirty(false);
    }

    setFormValues((formValues) => {
      return {
        ...formValues,
        admin: !formValues.admin,
      };
    });
  };

  const onSubmit = async () => {
    const validator = {
      firstName: nonEmptyField(firstName),
      lastName: nonEmptyField(lastName),
      email: emailValidator(email),
      phone: nonEmptyField(phone),
      newPassword: action === "add" ? nonEmptyField(newPassword) : "",
      reNewPassword: newPassword
        ? passwordsMatch(newPassword, reNewPassword)
        : "",
    };
    setValidationErrors(validator);

    if (Object.values(validator).find((item) => item)) return;

    const data = {
      first_name: firstName,
      email: email,
      last_name: lastName,
      phone_number: phone,
      role: admin ? Roles.Admin : Roles.User,
    };
    action === "add"
      ? await dispatch(
          createUser({
            ...data,
            password: newPassword,
          })
        )
      : await dispatch(editUser(data));

    if (!error) onGoBack();
  };

  const onGoBack = () => {
    currentUser && dispatch(clearCurrentUser());
    navigate(-1);
  };

  const handleClickGoBack = () => {
    if (valuesDirty) {
      setIsOpenModalGoBack(true);
      return;
    }
    onGoBack();
  };

  return (
    <PageContent
      title={action === "add" ? "Add user" : "Edit user"}
      path="Manage Users"
      history={false}
    >
      <div className={s.container}>
        <Button
          title="Back"
          filled="grayDark"
          size="xs"
          icon="arrow-left"
          onClick={handleClickGoBack}
        />
        <PageFormLayout widthLeft={60}>
          <>
            <div className={s.form}>
              <h2 className={cn("h3", s.title)}>Personal Information</h2>
              <div className={s.fields}>
                <div className={s.field}>
                  <Input
                    label="First Name"
                    placeholder="John"
                    value={firstName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(
                        e.target.value,
                        "firstName",
                        nonEmptyField(e.target.value)
                      )
                    }
                    status={validationErrors.firstName ? "error" : "normal"}
                    statusText={validationErrors.firstName}
                  />
                </div>
                <div className={s.field}>
                  <Input
                    label="Email"
                    placeholder="youremail@example.com"
                    value={email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(
                        e.target.value,
                        "email",
                        emailValidator(e.target.value)
                      )
                    }
                    status={validationErrors.email ? "error" : "normal"}
                    statusText={validationErrors.email}
                    disabled={action === "edit"}
                  />
                </div>
                <div className={s.field}>
                  <Input
                    label="Last Name"
                    placeholder="Johnson"
                    value={lastName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(
                        e.target.value,
                        "lastName",
                        nonEmptyField(e.target.value)
                      )
                    }
                    status={validationErrors.lastName ? "error" : "normal"}
                    statusText={validationErrors.lastName}
                  />
                </div>
                <div className={s.field}>
                  <Input
                    label="Phone Number"
                    placeholder="(___) ___-__-__"
                    value={phone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(
                        e.target.value,
                        "phone",
                        nonEmptyField(e.target.value)
                      )
                    }
                    status={validationErrors.phone ? "error" : "normal"}
                    statusText={validationErrors.phone}
                  />
                </div>
              </div>
              {action === "add" && (
                <>
                  <h2 className={cn("h3", s.title)}>Password</h2>
                  <div className={s.fields}>
                    <div className={s.field}>
                      <Input
                        value={newPassword}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleInputChange(
                            e.target.value,
                            "newPassword",
                            nonEmptyField(e.target.value)
                          )
                        }
                        label="New Password"
                        placeholder="Enter password"
                        type={"password"}
                        status={
                          validationErrors.newPassword ? "error" : "normal"
                        }
                        statusText={validationErrors.newPassword}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className={s.field}>
                      <Input
                        value={reNewPassword}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleInputChange(
                            e.target.value,
                            "reNewPassword",
                            passwordsMatch(newPassword, e.target.value)
                          )
                        }
                        label="Confirm New Password"
                        placeholder="Enter password"
                        type={passwordVisible ? "text" : "password"}
                        onClickIcon={() =>
                          setPasswordVisible(
                            (passwordVisible) => !passwordVisible
                          )
                        }
                        rightIcon={passwordVisible ? "eye-closed" : "eye"}
                        status={
                          validationErrors.reNewPassword ? "error" : "normal"
                        }
                        statusText={validationErrors.reNewPassword}
                        iconWarning={false}
                      />
                    </div>
                  </div>
                </>
              )}
              <Checkbox
                checked={admin}
                onChange={handleCheckboxChange}
                label="Administrator"
              />
            </div>
            <div className={s.field}>
              <Button
                disabled={!valuesDirty}
                title={action === "add" ? "Add user" : "Save changes"}
                isLoading={isLoading}
                loader="Processing..."
                onClick={onSubmit}
                wide="long"
                uppercase
              />
            </div>
            <Modal
              title="There are unsaved changes on this page"
              subtitle="Leave without saving changes?"
              isOpen={isOpenModalGoBack}
              onClose={() => setIsOpenModalGoBack(false)}
              submitText="Yes, Leave"
              cancelText="No, Stay"
              onSubmit={onGoBack}
            />
          </>
        </PageFormLayout>
      </div>
    </PageContent>
  );
};
