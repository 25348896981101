import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { UploadNotification, FormBlock } from "components/modules";
import {
  GENERATE_PROMO_STATUS,
  ORIGINATOR_OPTIONS_BASE,
  DEFAULT_MONITORING_IMPORT_API,
  FILLING_TYPE_OPTIONS,
} from "const";
import { usePostQuery } from "hooks/usePostQuery";
import { useState } from "react";
import {
  encodeDataToUrl,
  getUploadStatusStorage,
  getFileName,
  getInitialFileName,
  getUploadStatus,
  getLoading,
} from "utils";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useFormSelectFile } from "hooks/useFormSelectFile";
import { useFormSelectArr } from "../../../hooks/useFormSelectArr";

export const DefaultMonitoring = () => {
  const pageName = "defaultMonitoring";

  const { valuesSelect, handleSelect, resetSelect } = useFormSelectArr({
    originator: "all",
    fillingType: "all",
  });

  const { originator, fillingType } =
      valuesSelect;

  const {
    value: selectedFile,
    handleFileUpload,
    handleFileRemove,
  } = useFormSelectFile(() => setShowUploadNotification(false));

  const [showUploadNotification, setShowUploadNotification] = useState(
    localStorage.getItem(pageName) || false
  );

  const [succeedFileName, setSucceedFileName] = useState("");

  const { changeStorage } = useLocalStorage(pageName, () => {
    setSucceedFileName(getInitialFileName(pageName));
  });

  const { loading, error, postFormData, responseStatus } = usePostQuery(
    DEFAULT_MONITORING_IMPORT_API,
    changeStorage
  );

  const { post: generatePromoStatus } = usePostQuery(GENERATE_PROMO_STATUS);

  const resetValues = () => {
    resetSelect();
    handleFileRemove();
  };

  const handleImport = async () => {
    try {
      setShowUploadNotification(true);
      const requestData = new FormData();

      const query = encodeDataToUrl({
        model: originator,
        filling_type: fillingType,
      });

      selectedFile && requestData.append("file", selectedFile);

      const result = await postFormData(
        requestData,
        query,
        pageName,
        selectedFile?.name
      );
      if (result?.status) {
        resetValues();
        if (result?.status === 200) {
          generatePromoStatus(null);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const status = getUploadStatus(loading, responseStatus);

  const renderRightContent = () => {
    return (
      <UploadNotification
        status={getUploadStatusStorage(pageName, status)}
        fileName={getFileName(
          pageName,
          selectedFile?.name || "",
          status,
          succeedFileName
        )}
        closeNotification={() => setShowUploadNotification(false)}
        pageName={pageName}
      />
    );
  };

  return (
    <PageContent title="Default Monitoring" path="Imports">
      <PageFormLayout
        rightContent={showUploadNotification ? renderRightContent() : null}
      >
        <>
          <FormBlock
            fields={{
              select1: {
                options: ORIGINATOR_OPTIONS_BASE,
                onSelect: (value: string) => handleSelect("originator", value),
                value: originator,
                fieldName: "Select Originator",
              },
              select2: {
                options: FILLING_TYPE_OPTIONS,
                onSelect: (value: string) => handleSelect("fillingType", value),
                value: fillingType,
                fieldName: "Filling Type",
                hidden: originator !== "Splitero",
              },
              file: {
                onRemove: handleFileRemove,
                file: selectedFile,
                setFile: handleFileUpload,
              },
            }}
            importButton={{
              disabled: !selectedFile || !originator,
              loading: getLoading(loading, pageName),
              loadingText: "IMPORTING...",
              onclick: handleImport,
            }}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
