import { FilterType, IColumnData, IFilters } from "interfaces";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";

import { CheckboxFilter, DateFilter, PercentFilter, SelectFilter } from ".";
import { AMOUNT_FILTER_OPTIONS } from "const";
import { usePrevious } from "hooks/usePrevious";
import isEqual from "lodash/isEqual";
import { Button } from "components/common";
import s from "./TableFilters.module.scss";
import {
  getNotEmptyFilters,
  getInitialStateFilters,
  generateDataByMonthsAndPools,
} from "utils";

type Props = {
  columnsList: IColumnData[];
  sortedData: IColumnData[];
  setFilteredData: Dispatch<SetStateAction<any[]>>;
  isStartFilter: boolean;
  setStartFilter: Dispatch<SetStateAction<boolean>>;
  setDataIsFiltered: Dispatch<SetStateAction<boolean>>;
  setFilterIsChanged: Dispatch<SetStateAction<boolean>>;
  dataByMonths?: boolean;
};

export interface IPropsFilter {
  name: string;
  label: string;
  filters: IFilters;
  setFilters: Dispatch<SetStateAction<IFilters>>;
}

export const TableFilters = ({
  columnsList,
  sortedData,
  setFilteredData,
  isStartFilter,
  setStartFilter,
  setDataIsFiltered,
  setFilterIsChanged,
  dataByMonths,
}: Props) => {
  const [filters, setFilters] = useState(getInitialStateFilters(columnsList));

  const [filterIsSelected, setFilterIsSelected] = useState(false);

  const prevFilters = usePrevious(filters);
  const prevSortedData = usePrevious(sortedData);
  const prevColumnList = usePrevious(columnsList);

  const clearFilters = () => {
    setFilters(getInitialStateFilters(columnsList));
  };

  const updateTableData = () => {
    dataByMonths
      ? setFilteredData(generateDataByMonthsAndPools(sortedData))
      : setFilteredData(sortedData);
    setFilterIsSelected(false);
    setDataIsFiltered(false);
    setFilterIsChanged(false);
  };

  const onClear = () => {
    clearFilters();
    updateTableData();
  };

  useEffect(() => {
    onClear();
  }, [columnsList]);

  useEffect(() => {
    if (!prevFilters && filters) { return; }

    if (!isEqual(prevFilters, filters)) {
      if (getNotEmptyFilters(filters).length) {
        setFilterIsSelected(true);
        setFilterIsChanged(true);
      } else { updateTableData(); }
    } else { setFilterIsChanged(false); }
  }, [filters]);

  useEffect(() => {
    const filter = () => {
      const arr = Object.entries(filters).filter(
        (item: any) => item[1].notEmpty,
      );

      const result = arr.reduce((accum: any, item: any) => {
        const field = item[0];
        const res = accum.filter((row: any) => {
          if (item[1].equal) { return row[field] === +item[1].equal; }

          const fieldVal =
            item[1].filterType === FilterType.date
              ? new Date(row[field])
              : item[1].filterType === FilterType.percent
              ? +row[field] * 100
              : +row[field];

          const itemFrom =
            item[1].filterType === FilterType.date
              ? item[1].fromDate
              : parseFloat(item[1].from);

          const itemTo =
            item[1].filterType === FilterType.date
              ? item[1].toDate
              : parseFloat(item[1].to);

          if (itemFrom && itemTo) {
            return fieldVal >= itemFrom && fieldVal <= itemTo;
          }

          if (itemFrom) { return fieldVal >= itemFrom; }

          if (itemTo) { return fieldVal <= itemTo; }

          if (item[1].checkboxList) {
            const arr: { from: number; to: number }[] = [];
            item[1].checkboxList.forEach((checkbox: string) => {
              const option = AMOUNT_FILTER_OPTIONS.find(
                ({ value }) => value === checkbox,
              );
              arr.push({
                from: option?.from || -Infinity,
                to: option?.to || Infinity,
              });
            });
            return (
              (row[field] >= arr[0]?.from && row[field] <= arr[0]?.to) ||
              (Boolean(arr[1]) &&
                row[field] >= arr[1]?.from &&
                row[field] <= arr[1]?.to) ||
              (Boolean(arr[2]) &&
                row[field] >= arr[2]?.from &&
                row[field] <= arr[2]?.to)
            );
          }
          return row[field];
        });
        return res;
      }, sortedData);
      dataByMonths
        ? setFilteredData(generateDataByMonthsAndPools(result))
        : setFilteredData(result);
      setStartFilter(false);
      setFilterIsChanged(false);
    };

    if (isStartFilter) {
      filter();
    }
  }, [isStartFilter]);

  useEffect(() => {
    if (
      prevColumnList !== columnsList ||
      !prevSortedData ||
      (!prevSortedData.length && sortedData.length)
    ) {
      return;
    }
    // new data with new request
    if (!isEqual(prevSortedData, sortedData)) {
      setStartFilter(true);
    }
  }, [sortedData, columnsList]);

  return (
    <>
      {filterIsSelected && (
        <div className={s.sticky}>
          <Button
            title="Clear All Filters"
            onClick={onClear}
            size="xs"
            icon="close"
            className={s.gray}
            uppercase={true}
          />
        </div>
      )}
      {columnsList.map(({ filterType, filterSelectOptions, name, label }) => {
        switch (filterType) {
          case FilterType.select:
            return (
              <Fragment key={name}>
                <SelectFilter
                  name={name}
                  label={label}
                  filterSelectOptions={filterSelectOptions || []}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Fragment>
            );
          case FilterType.date:
            return (
              <Fragment key={name}>
                <DateFilter
                  name={name}
                  label={label}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Fragment>
            );
          case FilterType.amount:
            return (
              <Fragment key={name}>
                <CheckboxFilter
                  name={name}
                  label={label}
                  filters={filters}
                  setFilters={setFilters}
                  options={AMOUNT_FILTER_OPTIONS}
                />
              </Fragment>
            );
          case FilterType.percent:
          case FilterType.percentNotDecimal:
            return (
              <Fragment key={name}>
                <PercentFilter
                  name={name}
                  label={label}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Fragment>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
