import React, { Fragment } from "react";
import { Row } from "react-table";
import s from "./Table.module.scss";
import cn from "classnames";
import _ from "lodash";

import { TableRowSkeleton } from "../../modules";

type RowComponentProps = {
  k: string;
  row: Row<object>;
  rowsCount?: number;
  checkForRender?: boolean;
};

const RowComponent = React.memo(
  ({ k, row }: RowComponentProps) => {
    return (
      <tr {...row.getRowProps()} key={k}>
        {row.cells.map((cell, u) => {
          const className = cn(
            (cell.column as any)?.className === "status" &&
              cell.value === "Failed" &&
              "alert",
            (cell.column as any)?.className || ""
          );

          return (
            <Fragment key={`${u}_`}>
              {Array.isArray(cell.value) && cell.value?.length === 2 ? (
                <td>
                  <span className={s.accountingStyle}>
                    <span>{cell.value[0]}</span>
                    <span>{cell.value[1]}</span>
                  </span>
                </td>
              ) : (
                <td {...cell.getCellProps()} className={className}>
                  {cell.render("Cell")}
                </td>
              )}
            </Fragment>
          );
        })}
      </tr>
    );
  },
  (prevProps, nextProps) => {
    if (!nextProps.checkForRender) return false;

    // const rowsEqual = _.isEqual(prevProps.row, nextProps.row);
    const rowsSelectorEqual =
      // @ts-ignore
      prevProps.row.original?.selector?.props?.checked ==
      // @ts-ignore
      nextProps.row.original?.selector?.props?.checked;
    const rowsEqual = _.isEqual(
      _.omit(prevProps.row.original, "selector"),
      _.omit(nextProps.row.original, "selector")
    );
    const rowsCountEqual = prevProps.rowsCount === nextProps.rowsCount;

    return rowsSelectorEqual && rowsEqual && rowsCountEqual;
  }
);

export type TableProps = {
  rows: Row<object>[];
  isLoading?: boolean;
  colCount?: number | undefined;
  checkForRender?: boolean;
};

export const TableRows: React.FC<TableProps> = ({
  rows,
  isLoading,
  colCount,
  checkForRender,
}) => {
  return (
    <>
      {!isLoading ? (
        rows.map((row, i) => (
          <RowComponent
            k={`${i}`}
            row={row}
            key={row.id || i}
            rowsCount={rows.length}
            checkForRender={checkForRender}
          />
        ))
      ) : (
        <TableRowSkeleton colCount={colCount} rowCount={20}></TableRowSkeleton>
      )}
    </>
  );
};
