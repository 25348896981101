import React from "react";
import s from "./InfoRow.module.scss";

interface IProps {
  infoList: {
    id: number;
    title: string;
    value: string | string[];
  }[];
}

export const InfoRow = ({ infoList }: IProps) => {
  return (
    <ul
      className={s.list}
      style={{ gridTemplateColumns: `repeat(${infoList.length}, auto)` }}
    >
      {infoList.map(({ id, title, value }) => {
        return (
          <li className={s.item} key={id}>
            <span className={s.title}>{title}</span>
            <span className={s.value}>{value}</span>
          </li>
        );
      })}
    </ul>
  );
};
