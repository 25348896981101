export const securitizationTabs = [
  {
    id: 1,
    label: "PNT 2021-1 Dashboard",
    link: "https://10az.online.tableau.com/t/kingsbridge/views/1_SecuritizationInvestmentTapesv_2/PNT2021-1Dashboard?:origin=card_share_link&:embed=y",
  },
];

export const portfolioTabs = [
  {
    id: 1,
    label: "Combined Portfolio Dashboard",
    link: "https://10az.online.tableau.com/t/kingsbridge/views/2_1PortfolioDashboardnew/LivePortfolio?:origin=card_share_link&:embed=y",
  },
];

export const allMSASHPATabs = [
  {
    id: 1,
    label: "ALL MSA HPA",
    link: "https://10az.online.tableau.com/t/kingsbridge/views/3_AllMSAsHPAv2/ALLMSAHPA?:origin=card_share_link&:embed=y",
  },
];

export const acquiredPortfolioTabs = [
  {
    id: 1,
    label: "3rd Party Dashboard",
    link: "https://10az.online.tableau.com/t/kingsbridge/views/4_SP-1Dashboard/SP-1Dashboard?:origin=card_share_link&:embed=y",
  },
];

export const pointBuyBoxTabs = [
  {
    id: 1,
    label: "Point Buy Box Dashboard",
    link: "https://10az.online.tableau.com/t/kingsbridge/views/PointBuyBoxDashboard/PointBuyBoxDashboard?:origin=card_share_link&:embed=y",
  },
];

export const pnt20232Tabs = [
  {
    id: 1,
    label: "PNT 2023-2 Dashboard",
    link: "https://10az.online.tableau.com/t/kingsbridge/views/PNT2023-2Dashboard/PNT2023-2Dashboard?:origin=card_share_link&:embed=y",
  },
];

export const pledgeCollateralTabs = [
  {
    id: 1,
    label: "Pledge Collateral Dashboard",
    link: "https://10az.online.tableau.com/t/kingsbridge/views/8_PledgeCollateralDashboard/PledgeCollateralDashboard?:origin=card_share_link&:embed=y",
  },
];

export const exitTabs = [
  {
    id: 1,
    label: "Exit Dashboard",
    link: "https://10az.online.tableau.com/t/kingsbridge/views/9_ExitDashboard/ExitDashboard?:origin=card_share_link&:embed=y",
  },
];
