import cn from "classnames";
import s from "./Loader.module.scss";

interface IProps {
  color: "dark" | "accent" | "white";
}

const Loader = ({ color = "accent" }: IProps) => {
  return (
    <div className={s.container}>
      <div className={cn(s.loader, s[color])}></div>
    </div>
  );
};

export default Loader;
