import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControls } from "components/modules";
import {
  DATE_FORMAT,
  DOWNLOAD_GROSS_MONTHLY_RETURNS_REPORT,
  ORIGINATOR_OPTIONS_BASE,
  columnsGrossMonthlyReturns,
  REPORTING_PERIOD,
  generateYearOptions,
} from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useFormSelectDateArr } from "hooks/useFormSelectDateArr";
import { usePrevious } from "hooks/usePrevious";
import moment from "moment";
import { useEffect, useState } from "react";
import { getList } from "store/actions/GrossMonthlyReturnsActions";
import { downloadResourceFromURL, encodeDataToUrl } from "utils";

export const GrossMonthlyReturns = () => {
  const startYear = 2015;

  const { valuesSelect, handleSelect, updateInitialSelect } = useFormSelectArr({
    originator: "All",
    reportingPeriod: "month",
    year: "none",
  });

  const { valuesDate, handleDateChange, updateInitialDate } =
    useFormSelectDateArr({
      valuationDate: new Date(moment().endOf("month").format(DATE_FORMAT)),
    });

  const { originator, reportingPeriod, year } = valuesSelect;

  const { valuationDate } = valuesDate;

  const [tableMode, setTableMode] = useState(false);

  const { list, isLoading } = useAppSelector(
    (state) => state.grossMonthlyReturns
  );

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
    }
  }, [isLoading]);

  const fieldsInForm = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => handleSelect("originator", value),
      value: originator,
      fieldName: "Select Originator",
    },
    valuationDate: {
      setValuationDate: (value: Date | undefined | null) =>
        handleDateChange("valuationDate", value),
      valuationDate: valuationDate,
      fieldName: "Valuation Date",
    },
    select2: {
      fieldName: "Reporting Period",
      options: REPORTING_PERIOD,
      onSelect: (value: string) => handleSelect("reportingPeriod", value),
      value: reportingPeriod,
    },
    select3: {
      fieldName: "Year",
      options: generateYearOptions(startYear, true),
      onSelect: (value: string) => handleSelect("year", value),
      value: year,
    },
  };

  useEffect(() => {
    if (year != "none") {
      handleSelect("reportingPeriod", "none");
    }
  }, [year]);

  useEffect(() => {
    if (reportingPeriod != "none") {
      handleSelect("year", "none");
    }
  }, [reportingPeriod]);

  const getTableList = ({
    originator,
    reportingPeriod,
    valuationDate,
    year,
  }: {
    originator: string;
    reportingPeriod: string;
    valuationDate: string;
    year: string;
  }) => {
    if (!isLoading) {
      dispatch(
        getList({
          model: originator.toLocaleLowerCase(),
          valuation_date: moment(valuationDate).format("YYYY-MM-DD"),
          reporting_period:
            reportingPeriod == "none"
              ? undefined
              : reportingPeriod.toLocaleLowerCase(),
          year: year == "none" ? undefined : year,
        })
      );

      updateInitialSelect();
      updateInitialDate();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      model: originator.toLocaleLowerCase(),
      valuation_date: moment(valuationDate).format("YYYY-MM-DD"),
      reporting_period:
        reportingPeriod == "none"
          ? undefined
          : reportingPeriod.toLocaleLowerCase(),
      year: year == "none" ? undefined : year,
    });

    downloadResourceFromURL(
      `Gross Monthly Returns.xlsx`,
      `${DOWNLOAD_GROSS_MONTHLY_RETURNS_REPORT}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    getTableList({ originator, reportingPeriod, valuationDate, year });
  };

  return (
    <PageContent
      title="Gross Monthly Returns"
      path="Accounting"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fieldsInForm}
            importButton={{
              label: isLoading ? "Processing..." : "Generate Report",
              onclick: handleClickImportBtn,
              disabled:
                isLoading ||
                !valuationDate ||
                (year == "none" && reportingPeriod == "none"),
            }}
            tableMode={tableMode}
          />
          {tableMode && (
            <TableControls
              columnsList={columnsGrossMonthlyReturns}
              list={list}
              isLoading={isLoading}
              onDownloadFile={onDownloadFile}
              dynamicPinFirstCol
              searchColumns={["contract_id"]}
              withFilters
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
