import { CustomDatePicker, Input, Select } from "components/common";
import s from "./FormUpdateData.module.scss";
import { useEffect, useState } from "react";
import { PayoffDemandDataType } from "store/types/PayoffDemandType";
import { updatePayoffDemandData } from "store/actions/PayoffDemandActions";
import { useAppDispatch } from "hooks/reduxHooks";
import { FormUpdateDataButtons } from "./FormUpdateDataButtons";
import { resetData } from "store/reducers/PayoffDemandSlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NOT_EMPTY_VALIDATION } from "const";
import {
  formatAmountToNumber,
  getInitialAmount,
  getValueChangeInputAmount,
} from "utils";
import moment from "moment";
import { FieldWrapper } from "components/common/FormElements/FieldWrapper";
import { useFormSelectArr } from "hooks/useFormSelectArr";

type Props = {
  data: PayoffDemandDataType;
  statuses: string[];
  isLoading: boolean;
  isUpdated: boolean;
};

interface FormValues {
  contract_id: string;
  servicer_account_number: string;
  address: string;
  investment_amount: string;
  payoff_requested_date: Date | null;
  payoff_valid_date: Date | null;
  appreciation_share: string;
  payoff_home_amount: string;
  payoff_home_value: string;
}

export const FormPayoffDemand = ({
  data,
  statuses,
  isLoading,
  isUpdated,
}: Props) => {
  const [fetchData, setFetchData] = useState<any>(null);

  const dispatch = useAppDispatch();

  const withSubmitModal = true;
  const idForm = "payoffDemand";

  const { valuesSelect, handleSelect, resetOneSelect, valuesSelectDirty } =
    useFormSelectArr({
      property_status: data.property_status,
    });

  const { property_status } = valuesSelect;

  const getSelectOptions = (array: string[]) => {
    return array?.map((i: string, idx: number) => ({
      key: `${idx}`,
      value: i,
      label: i,
    }));
  };

  const getTotalPayoff = () => {
    return (data.investment_amount || 0) + (data.appreciation_share || 0);
  };

  const getInitialData = (data: PayoffDemandDataType) => {
    return {
      contract_id: data.contract_id,
      servicer_account_number: data.servicer_account_number ?? "",
      address: data.address,
      investment_amount: getInitialAmount(data.investment_amount),
      payoff_requested_date: data.payoff_requested_date
        ? moment(data.payoff_requested_date).toDate()
        : null,
      payoff_valid_date: data.payoff_valid_date
        ? moment(data.payoff_valid_date).toDate()
        : null,
      appreciation_share: getInitialAmount(data.appreciation_share),
      payoff_home_amount: getInitialAmount(getTotalPayoff()),
      payoff_home_value: getInitialAmount(data.payoff_home_value),
    };
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    watch,
  } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: getInitialData(data),
  });

  const watch1 = watch("appreciation_share");

  useEffect(() => {
    setValue(
      "payoff_home_amount",
      getInitialAmount(
        (formatAmountToNumber(watch1) || 0) + (data.investment_amount || 0)
      )
    );
  }, [watch1]);

  useEffect(() => {
    if (data.contract_id) {
      reset(getInitialData(data));
      resetOneSelect("property_status");
    }
  }, [data]);

  const onCancel = () => {
    dispatch(resetData());
  };

  const onChangeInputAmount = (name: keyof FormValues, value: string) => {
    const newVal = getValueChangeInputAmount(value);
    setValue(name, newVal);
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const {
      investment_amount,
      payoff_requested_date,
      payoff_valid_date,
      appreciation_share,
      payoff_home_amount,
      payoff_home_value,
      ...rest
    } = formData;

    const fetchData = {
      ...rest,
      investment_amount: formatAmountToNumber(investment_amount),
      payoff_requested_date: payoff_requested_date
        ? moment(payoff_requested_date).format("MM/DD/YYYY")
        : "",
      payoff_valid_date: payoff_valid_date
        ? moment(payoff_valid_date).format("MM/DD/YYYY")
        : "",
      appreciation_share: formatAmountToNumber(appreciation_share),
      payoff_home_amount: formatAmountToNumber(payoff_home_amount),
      payoff_home_value: formatAmountToNumber(payoff_home_value),
      hec_id: data.hec_id,
      property_status,
    };

    withSubmitModal
      ? setFetchData(fetchData)
      : dispatch(updatePayoffDemandData(fetchData));
  };

  return (
    <form
      id={idForm}
      onSubmit={handleSubmit(onSubmit)}
      className={s.form}
      noValidate
    >
      <div className={s.item}>
        <span className={s.label}>Contract ID:</span>
        <Controller
          control={control}
          name="contract_id"
          render={({ field }) => <Input {...field} disabled />}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Service Account Number:</span>
        <Controller
          control={control}
          name="servicer_account_number"
          render={({ field }) => <Input {...field} disabled />}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Address:</span>
        <Controller
          control={control}
          name="address"
          render={({ field }) => <Input {...field} disabled />}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Option Investment Amount:</span>
        <Controller
          control={control}
          name="investment_amount"
          render={({ field }) => (
            <Input
              {...field}
              onChangeCustom={onChangeInputAmount}
              leftSymbol="$"
              amountFormat
              disabled
            />
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Payoff Start Date:</span>
        <Controller
          control={control}
          name="payoff_requested_date"
          rules={NOT_EMPTY_VALIDATION}
          render={({ field }) => (
            <FieldWrapper error={errors.payoff_requested_date}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Payoff Expiration Date:</span>
        <Controller
          control={control}
          name="payoff_valid_date"
          rules={NOT_EMPTY_VALIDATION}
          render={({ field }) => (
            <FieldWrapper error={errors.payoff_valid_date}>
              <CustomDatePicker
                {...field}
                startDate={field.value}
                setStartDate={field.onChange}
                placement="right"
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Appreciation Share:</span>
        <Controller
          control={control}
          name="appreciation_share"
          render={({ field }) => (
            <FieldWrapper error={errors.appreciation_share}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Total Payoff Amount:</span>
        <Controller
          control={control}
          name="payoff_home_amount"
          render={({ field }) => (
            <FieldWrapper error={errors.payoff_home_amount}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
                disabled
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Payoff Home Value:</span>
        <Controller
          control={control}
          name="payoff_home_value"
          render={({ field }) => (
            <FieldWrapper error={errors.payoff_home_value}>
              <Input
                {...field}
                onChangeCustom={onChangeInputAmount}
                leftSymbol="$"
                amountFormat
              />
            </FieldWrapper>
          )}
        />
      </div>
      <div className={s.item}>
        <span className={s.label}>Property Status:</span>
        <Select
          options={getSelectOptions(statuses)}
          onSelect={(value: string) => handleSelect("property_status", value)}
          placeholder="Select"
          value={property_status}
        />
      </div>
      <FormUpdateDataButtons
        disabled={!isDirty && !valuesSelectDirty}
        withSubmitModal={withSubmitModal}
        onCancel={onCancel}
        idForm={idForm}
        fetchData={fetchData}
        fetchDataAction={updatePayoffDemandData}
        isUpdated={isUpdated}
        isLoading={isLoading}
      />
    </form>
  );
};
