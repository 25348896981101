import {
  IAccess,
  IControlAllByAccess,
  IGroupedAccess,
} from "interfaces/permissions";
import s from "./PermissionsSettings.module.scss";
import { navigation } from "const/navigation";
import { SelectReact } from "components/common/FormElements/SelectReact/SelectReact";
import { useFormSelect } from "hooks/useFormSelect";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Checkbox, Icon } from "components/common";
import { capitalizeWords } from "utils";
import cn from "classnames";
import { useAppDispatch } from "hooks/reduxHooks";
import {
  getPermissions,
  updatePermissions,
} from "store/actions/PermissionsActions";
import { PermissionsDisabled, PermissionsExcluded } from "const/permissions";

type Props = {
  currentUser: string;
  setCurrentUser: Dispatch<SetStateAction<string>>;
  usersOptions: { value: string; label: string }[];
  permissions: {
    id: string;
    access: IAccess[];
  };
  isLoading: boolean;
};

export const PermissionsSettings = ({
  currentUser,
  setCurrentUser,
  usersOptions,
  permissions,
  isLoading,
}: Props) => {
  const { value, handleSelect } = useFormSelect(currentUser);

  const [grouped, setGrouped] = useState<IGroupedAccess[]>();

  const [currentFolder, setCurrentFolder] = useState("");

  const [values, setValues] = useState<{ [key: string]: boolean }>({});

  const [defaultValues, setDefaultValues] = useState<{
    [key: string]: boolean;
  }>({});

  const [updated, setUpdated] = useState(false);

  const [controlAll, setControlAll] =
    useState<Map<string, IControlAllByAccess>>();

  const dispatch = useAppDispatch();

  const resetControlAll = (grouped: IGroupedAccess[]) => {
    const resultMap = new Map<string, IControlAllByAccess>();

    grouped.forEach(({ pages, folder_name }) => {
      const total = pages.length;
      const listIds = pages.map(({ access_id }) => access_id);
      const checked = pages.filter(({ access }) => access).length;
      const allChecked = total === checked;

      resultMap.set(folder_name, { total, listIds, checked, allChecked });
    });

    setControlAll(resultMap); //all checkbox values
  };

  const resetAll = () => {
    setValues(defaultValues);
    resetControlAll(grouped as IGroupedAccess[]);
  };

  useEffect(() => {
    if (+permissions.id === +currentUser || updated) {
      if (updated) setUpdated(false);

      const groupedMap = permissions.access.reduce((acc, current) => {
        let folder = acc.get(current.folder_name);

        const getIcon = () => {
          for (const section of navigation) {
            for (const menuItem of section.nav) {
              if (menuItem.label.toLowerCase() === current.folder_name) {
                return menuItem.icon;
              }
            }
          }
        };

        if (!folder) {
          folder = {
            folder_name: current.folder_name,
            icon: getIcon() || "",
            pages: [],
          };
          acc.set(current.folder_name, folder);
        }

        folder.pages.push({
          functional_name: current.functional_name,
          access: current.access,
          access_id: current.access_id,
        });

        return acc;
      }, new Map<string, IGroupedAccess>());

      const grouped = Array.from(groupedMap.values());

      setGrouped(grouped); //layout
      setCurrentFolder(grouped[0].folder_name); //visible folder

      const values: { [key: string]: boolean } = {};

      permissions.access.forEach(({ access_id, access }) => {
        values[access_id] = access;
      });

      setValues(values); //form values
      setDefaultValues(values);

      resetControlAll(grouped);
    }
  }, [permissions.id, currentUser, updated]);

  const onSelect = (value: string) => {
    handleSelect(value);
    setCurrentUser(value);
  };

  const handleChangeAccess = (access_id: string, folder_name: string) => {
    setControlAll((controlAll) => {
      const updatedMap = new Map(controlAll);

      const folder = controlAll?.get(folder_name);

      const newChecked = folder
        ? values[access_id]
          ? folder.checked - 1
          : folder.checked + 1
        : undefined;

      newChecked !== undefined &&
        folder &&
        updatedMap.set(folder_name, {
          total: folder.total,
          listIds: folder.listIds,
          checked: newChecked,
          allChecked: folder.total === newChecked,
        });

      return updatedMap;
    });

    setValues((values) => {
      return {
        ...values,
        [access_id]: !values[access_id],
      };
    });
  };

  const handleChangeAll = (folder_name: string) => {
    const folder = controlAll?.get(folder_name);
    const listIds = folder?.listIds;
    const allChecked = Boolean(!folder?.allChecked);

    const updatedAccess = listIds?.reduce((acc, key) => {
      acc[key] = allChecked;
      return acc;
    }, {} as Record<string, boolean>);

    //@ts-ignore
    setValues((values) => {
      return {
        ...values,
        ...updatedAccess,
      };
    });

    setControlAll((controlAll) => {
      const updatedMap = new Map(controlAll);

      const folder = controlAll?.get(folder_name);

      const allChecked = !folder?.allChecked;

      folder &&
        updatedMap.set(folder_name, {
          total: folder.total,
          listIds: folder.listIds,
          checked: allChecked ? folder.total : 0,
          allChecked,
        });

      return updatedMap;
    });
  };

  const onSubmit = () => {
    const sendData = Object.entries(values).map(([key, value]) => ({
      access_id: +key,
      access: value,
    }));
    dispatch(updatePermissions({ list_value: sendData })).then(() => {
      dispatch(getPermissions(currentUser)).then(() => setUpdated(true));
    });
  };

  const onCancel = () => {
    setCurrentUser("");
  };

  const getDisabledCheckbox = (folder_name: string) =>
    PermissionsDisabled.includes(folder_name);

  const getExcluded = (folder_name: string) =>
    PermissionsExcluded.includes(folder_name);

  return (
    <div>
      <div className={s.header}>
        <h2>Set Permissions to Access</h2>
        {value && usersOptions && (
          <SelectReact
            name="user"
            options={usersOptions}
            value={
              usersOptions.find((item) => value.toString() === item.value) || ""
            }
            onSelect={onSelect}
            isSearchable
            width={250}
          />
        )}
      </div>
      <form>
        <div className={s.container}>
          <div className={s.folders}>
            <h3 className={s.label}>Function</h3>
            <ul className={s.folderList}>
              {grouped &&
                grouped.map(({ folder_name, pages }) => {
                  if (!getExcluded(folder_name)) {
                    return (
                      <li
                        key={folder_name}
                        className={cn(
                          s.folder,
                          s.listItem,
                          currentFolder === folder_name && s.active
                        )}
                      >
                        <Checkbox
                          checked={controlAll?.get(folder_name)?.allChecked}
                          onChange={() => handleChangeAll(folder_name)}
                          disabled={getDisabledCheckbox(folder_name)}
                        />
                        <div
                          className={s.folderClickArea}
                          onClick={() => setCurrentFolder(folder_name)}
                        >
                          <span className={s.folderName}>
                            {capitalizeWords(folder_name)}
                          </span>
                          <Icon icon="right" />
                        </div>
                        <ul className={s.accessList}>
                          {pages.map(({ functional_name, access_id }) => (
                            <li key={access_id} className={s.listItem}>
                              <Checkbox
                                label={functional_name}
                                checked={values[access_id]}
                                onChange={() =>
                                  handleChangeAccess(access_id, folder_name)
                                }
                                disabled={getDisabledCheckbox(folder_name)}
                              />
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  }
                })}
            </ul>
          </div>
          <div className={s.subfunctionsPseudoWrap}>
            <h3 className={s.label}>Sub Function</h3>
          </div>
          <Button
            title="Reset All Permissions"
            filled="grayBright"
            size="xs"
            onClick={resetAll}
            disabled={JSON.stringify(values) === JSON.stringify(defaultValues)}
          />
        </div>
        <div className={s.btnRow}>
          <Button
            filled="empty"
            title="Cancel"
            onClick={onCancel}
            size="large"
            uppercase
          />
          <Button
            size="large"
            title="Save"
            isLoading={isLoading}
            loader="Processing..."
            uppercase
            onClick={onSubmit}
            disabled={JSON.stringify(values) === JSON.stringify(defaultValues)}
          />
        </div>
      </form>
    </div>
  );
};
