import { PageContent } from "components/layout/PageContent/PageContent";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import {
  DEBT_LINE,
  DOWNLOAD_PLEDGE_LIST_CSV,
  ORIGINATOR_OPTIONS_BASE,
  PLEDGE_TO_COLLATERAL,
  columnsPledgeList,
  FICO_OPTIONS,
  LOTV_OPTIONS,
  RISK_ADJUSTMENT_OPTIONS,
  LIEN_POSITION_OPTIONS, MULTIPLE_OPTIONS,
} from "const";
import {
  cancelPledgeList,
  getPledgeList,
} from "store/actions/PledgeCollateralActions";
import { downloadResourceFromURL, encodeDataToUrl } from "utils";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock } from "components/modules";
import { Modal } from "components/common";
import { TableControlsWithSelectRows } from "components/modules/TableControls/TableControlsWithSelectRows";
import moment from "moment";

export const PledgeList = () => {
  const { valuesSelect, handleSelect, setValue } = useFormSelectArr({
    originator: "All",
    debtLine: "EWB 02-2023",
    pledgeTo: "Point Digital Finance, LLC",
    fico: "All",
    lotv: "All",
    risk: "All",
    multiple: "All",
    lien: "All",
  });

  const { originator, debtLine, pledgeTo, fico, lotv, risk, multiple, lien } =
    valuesSelect;

  const [confirmDownloadModal, setConfirmDownloadModal] = useState(false);

  const {
    pledge_list: list,
    pledge_list_investment_amount: investment_amount,
    pledge_list_investment_count: investment_count,
    isLoading,
  } = useAppSelector((state) => state.pledgeCollateral);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getTableList(valuesSelect);
  }, []);

  useEffect(() => {
    setValue({
      ...valuesSelect,
      pledgeTo:
        PLEDGE_TO_COLLATERAL.find((it) => it.key === debtLine)?.label || "",
    });
  }, [list]);

  const fields = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => onSelect("originator", value),
      value: originator,
      fieldName: "Originator",
    },
    select2: {
      options: DEBT_LINE,
      onSelect: (value: string) => onSelect("debtLine", value),
      value: debtLine,
      fieldName: "Debt Line",
    },
    select3: {
      options: PLEDGE_TO_COLLATERAL,
      value: pledgeTo,
      onSelect: (value: string) => {},
      fieldName: "Pledged",
      disabled: true,
    },
    select4: {
      options: FICO_OPTIONS,
      onSelect: (value: string) => onSelect("fico", value),
      value: fico,
      fieldName: "FICO",
    },
    select5: {
      options: LOTV_OPTIONS,
      onSelect: (value: string) => onSelect("lotv", value),
      value: lotv,
      fieldName: "LOTV",
    },
    select6: {
      options: RISK_ADJUSTMENT_OPTIONS,
      onSelect: (value: string) => onSelect("risk", value),
      value: risk,
      fieldName: "Risk Adjustment",
    },
    select7: {
      options: MULTIPLE_OPTIONS,
      onSelect: (value: string) => onSelect("multiple", value),
      value: multiple,
      fieldName: "Multiple",
    },
    select8: {
      options: LIEN_POSITION_OPTIONS,
      onSelect: (value: string) => onSelect("lien", value),
      value: lien,
      fieldName: "Lien Position",
    },
  };

  const getTableList = ({
    originator,
    debtLine,
    fico,
    lotv,
    risk,
    multiple,
    lien,
  }: {
    originator: string;
    debtLine: string;
    fico: string;
    lotv: string;
    risk: string;
    multiple: string;
    lien: string;
  }) => {
    if (!isLoading) {
      dispatch(
        getPledgeList({
          model: originator.toLocaleLowerCase(),
          lender:
            PLEDGE_TO_COLLATERAL.find((it) => it.key === debtLine)?.label || "",
          fico,
          lotv,
          risk,
          multiple,
          lien,
        })
      );
    }
  };

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      model: originator.toLocaleLowerCase(),
      lender:
        PLEDGE_TO_COLLATERAL.find((it) => it.key === debtLine)?.label || "",
      fico,
      lotv,
      risk,
      multiple,
      lien,
    });

    downloadResourceFromURL(
      `${debtLine}.xlsx`,
      `${DOWNLOAD_PLEDGE_LIST_CSV}?${params}`
    );
    setConfirmDownloadModal(false);
  };

  const onSelect = (field: string, value: string) => {
    handleSelect(field, value);
    const data = {
      ...valuesSelect,
      [field]: value,
    };
    getTableList({ ...data });
  };

  const submitSelectedDataAction = async (
    selectedRows: string[],
    dateValue: string
  ) => {
    await dispatch(
      cancelPledgeList({
        list_ids: selectedRows.join(","),
        unpledge_date: moment(dateValue).format("YYYY-MM-DD"),
      })
    );
    getTableList(valuesSelect);
  };

  const [isFiltersMultirow, setFiltersMultirow] = useState(false);

  return (
    <PageContent title="Pledge List" path="Pledge Collateral" search={true}>
      <PageFormLayout tableMode={true}>
        <>
          <TableControlsWithSelectRows
            columnsList={columnsPledgeList}
            list={list}
            isLoading={isLoading}
            infoRow
            investment_amount={investment_amount}
            investment_count={investment_count}
            defaultSortedColumn="effective_date"
            onDownloadFile={() => setConfirmDownloadModal(true)}
            onFiltersRegroup={(v) => setFiltersMultirow(v)}
            formInPanel={
              <FormBlock
                fields={fields}
                tableMode
                inControlPanel
                isFiltersMultirow={isFiltersMultirow}
                sizeFields={"180"}
              />
            }
            withFilters
            searchColumns={["investment_id"]}
            idRowSelector="investment_id"
            selectedActionBtnTitle="Update pledge collateral"
            shortFilterBtn
            confirmModalText="Are you sure you want to update pledge collateral?"
            confirmModalSubmitText="Update"
            confirmModalSubtitle="Please select the unpledge date: "
            submitSelectedDataAction={submitSelectedDataAction}
          />

          <Modal
            isOpen={confirmDownloadModal}
            onClose={() => setConfirmDownloadModal(false)}
            title="Please update pledge collateral list before downloading"
            submitText="Download"
            cancelText="Back to edit pledge list"
            onSubmit={onDownloadFile}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
