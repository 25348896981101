import { SelectReact } from "components/common/FormElements/SelectReact/SelectReact";
import s from "./FormBlock.module.scss";
import { Button } from "components/common";
import {useEffect, useState} from "react";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getSearchValues } from "store/actions/PayoffDemandActions";
import cn from "classnames";

type Props = {
  getDataAction: any;
  contracts?: "contract_ids" | "preliminary_contracts" | "lookup_contracts";
  resetUpdated?: () => void;
  isHalfWidth?: boolean;
  isLoading: boolean;
};

export const FormBlockSearchByContract = ({
  getDataAction,
  contracts = "contract_ids",
  resetUpdated,
  isHalfWidth,
  isLoading,
}: Props) => {
  const { searchData } = useAppSelector((state) => state.payoffDemand);

  const [searchValue, setSearchValue] = useState("");

  const dispatch = useAppDispatch();

  const { valuesSelect, handleSelect, valuesSelectDirty, resetSelect } =
    useFormSelectArr({
      contract_id: "",
    });

  const { contract_id } = valuesSelect;

  useEffect(() => {
    dispatch(getSearchValues({}));
  }, []);

  const getCurrentOptions = () => {
    const rowsCount = 100;
    const options = searchValue
        ? searchData[contracts]
            .filter(v => `${v}`.toLowerCase().includes(searchValue.toLowerCase()))
            .slice(0, rowsCount)
        : searchData[contracts]
            .slice(0, rowsCount)
    return valuesSelect && valuesSelect["contract_id"] ? [valuesSelect["contract_id"], ...options] : options;
  };

  const onSearch = () => {
    if (!isLoading) {
      resetUpdated && dispatch(resetUpdated());
      dispatch(getDataAction({ contract_id }));
      resetSelect();
    }
  };

  return (
    <div className={cn(s.wrapSearch, isHalfWidth && s.widthHalf)}>
      <span className={s.label}>Contract Id:</span>
      <div className={cn(s.searchRow, s.contract)}>
        <SelectReact
          name="contract_id"
          options={getCurrentOptions()}
          value={contract_id}
          onSelect={(value: string) => handleSelect("contract_id", value)}
          onInputChange={(value: string) => setSearchValue(value)}
          isSearchable
          isClearable
        />
        <Button
          onClick={onSearch}
          title="Search"
          wide="long"
          isLoading={isLoading}
          loader="..."
          disabled={!contract_id || !valuesSelectDirty}
        />
      </div>
    </div>
  );
};
