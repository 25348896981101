import { createSlice } from "@reduxjs/toolkit";
import { getList } from "store/actions/ForwardCashFlowsActions";
import { ForwardCashFlows } from "../types/ForwardCashFlows";

const initialState = {
  isLoading: false,
  error: null,
  list: {},
} as ForwardCashFlows;

export const ForwardCashFlowsSlice = createSlice({
  name: "forwardCashFlows",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetList: (state) => {
      state.list = {};
    },
  },
  extraReducers: {
    [getList.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.error = null;
    },
    [getList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getList.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.list = {};
      state.isLoading = false;
    },
  },
});

export const { setError, resetList } = ForwardCashFlowsSlice.actions;

export default ForwardCashFlowsSlice.reducer;
