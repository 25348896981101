import { createSlice } from '@reduxjs/toolkit'
import { getList } from '../actions/SmmResolutionRatesList'
import { SmmResolutionRatesListType } from 'store/types/SmmResolutionRatesList'

const initialState = {
    isLoading: false,
    error: null,
    list: [],
} as SmmResolutionRatesListType

export const SmmResolutionRatesListSlice = createSlice({
    name: 'smmResolutionRatesList',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload
        },
        resetList: (state) => {
            state.list = []
        },
    },
    extraReducers: {
        [getList.fulfilled.type]: (state, action) => {
            state.isLoading = false
            state.list = action.payload
            state.error = null
        },
        [getList.pending.type]: (state) => {
            state.isLoading = true
        },
        [getList.rejected.type]: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
    },
})

export const { setError, resetList } = SmmResolutionRatesListSlice.actions

export default SmmResolutionRatesListSlice.reducer
