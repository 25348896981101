import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControls } from "components/modules";
import {
  CONTRACTS_OPTIONS,
  DOWNLOAD_HPA_CONTRACT_REPORT,
  HPA_YEARS_FROM_OPTIONS,
  HPA_YEARS_TO_OPTIONS,
  POOL_OPTIONS,
  REPORT_TYPE_OPTIONS,
  columnsHPAContract,
  columnsHPAEffectiveLatest,
  columnsHPAQuarterly,
} from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { usePrevious } from "hooks/usePrevious";
import { useEffect, useState } from "react";
import { getList } from "store/actions/HPAContractActions";
import { getContractsIdsList } from "store/actions/ContractsActions";
import {
  downloadResourceFromURL,
  encodeDataToUrl,
  getYearsOptions,
} from "utils";
import { BasePaginator } from "../../modules/Paginator";

type Values = {
  pool: string;
  contracts: string;
  contract: string;
  reportType: string;
  yearFrom: string;
  yearTo: string;
  pageNumber: number;
  pageSize: number;
};

export const HPAContract = () => {

  const defaultYearFrom = "effective_year";
  const defaultYearTo = "all";
  const defaultPageSize = 100

  const { valuesSelect, handleSelect, updateInitialSelect } = useFormSelectArr({
    pool: "all",
    contracts: "all",
    contract: "",
    reportType: "quarterly",
    yearFrom: defaultYearFrom,
    yearTo: defaultYearTo,
    page: 0,
    page_size: defaultPageSize
  });

  const { pool, contracts, contract, yearFrom, yearTo, reportType } =
    valuesSelect;

  const [tableMode, setTableMode] = useState(false);

  // @ts-ignore
  const { list: {items, total}, isLoading, error } = useAppSelector((state) => state.hpaContract);
  const { contract_ids } = useAppSelector((state) => state.contractIds);

  const [searchValue, setSearchValue] = useState("");

  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedPageSize, setSelectedPageSize] = useState(defaultPageSize);

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    dispatch(getContractsIdsList({model: pool}));
  }, [pool]);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (contracts === "all") {
      handleSelect("contract", "");
    }
  }, [contracts]);

  useEffect(() => {
    if (reportType === "effective_latest") {
      handleSelect("yearFrom", "");
      handleSelect("yearTo", "");
    }

    if (reportType === "quarterly") {
      handleSelect("yearFrom", defaultYearFrom);
      handleSelect("yearTo", defaultYearTo);
    }
  }, [reportType]);

  const getCurrentOptions = () => {
    const rowsCount = 100;
    const options = searchValue
      ? contract_ids
          .filter((v) =>
            `${v}`.toLowerCase().includes(searchValue.toLowerCase())
          )
          .slice(0, rowsCount)
      : contract_ids.slice(0, rowsCount);
    return options;
  };

  const fieldsInForm = {
    select1: {
      fieldName: "Pool",
      options: POOL_OPTIONS,
      onSelect: (value: string) => handleSelect("pool", value),
      value: pool,
      oneInRow: true,
    },
    select2: {
      fieldName: "Contracts",
      options: CONTRACTS_OPTIONS,
      onSelect: (value: string) => handleSelect("contracts", value),
      value: contracts,
    },
    select3: {
      optionsString: getCurrentOptions(),
      onSelect: (value: string) => handleSelect("contract", value),
      value: contract,
      isSearchable: true,
      hidden: contracts === "all",
      onInputChange: (value: string) => setSearchValue(value),
    },
    select4: {
      fieldName: "Report Type",
      options: REPORT_TYPE_OPTIONS,
      onSelect: (value: string) => handleSelect("reportType", value),
      value: reportType,
      oneInRow: true,
    },
    select5: {
      fieldName: "Choose Date",
      options: [...HPA_YEARS_FROM_OPTIONS, ...getYearsOptions(1975)],
      onSelect: (value: string) => handleSelect("yearFrom", value),
      value: yearFrom,
      label: "Year From",
      disabled: reportType === "effective_latest",
    },
    select6: {
      options: [...HPA_YEARS_TO_OPTIONS, ...getYearsOptions(1975)],
      onSelect: (value: string) => handleSelect("yearTo", value),
      value: yearTo,
      label: "Year To",
      disabled: reportType === "effective_latest",
    },
  };

  const fieldsinPanel = {
    select1: {
      fieldName: "Pool",
      options: POOL_OPTIONS,
      onSelect: (value: string) => onSelect("pool", value),
      value: pool,
    },
    select2: {
      fieldName: "Report Type",
      options: REPORT_TYPE_OPTIONS,
      onSelect: (value: string) => onSelect("reportType", value),
      value: reportType,
    },
    select3: {
      options: [...HPA_YEARS_FROM_OPTIONS, ...getYearsOptions(1975)],
      onSelect: (value: string) => onSelect("yearFrom", value),
      value: yearFrom,
      label: "Year From",
      disabled: reportType === "effective_latest",
    },
    select4: {
      options: [...HPA_YEARS_TO_OPTIONS, ...getYearsOptions(1975)],
      onSelect: (value: string) => onSelect("yearTo", value),
      value: yearTo,
      label: "Year To",
      disabled: reportType === "effective_latest",
    },
  };

  const getTableList = ({
    pool,
    contracts,
    contract,
    yearFrom,
    yearTo,
    reportType,
    pageNumber,
    pageSize
  }: Values) => {
    if (!isLoading) {
      dispatch(
        getList({
          model: pool,
          contract_id: contract,
          report_type: reportType,
          from_year: yearFrom,
          to_year: yearTo,
          page_size: pageSize,
          page: pageNumber,
        })
      );

      updateInitialSelect();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      model: pool,
      contract_id: contract,
      report_type: reportType,
      from_year: yearFrom,
      to_year: yearTo,
    });

    downloadResourceFromURL(
      `HPA Contract List.xlsx`,
      `${DOWNLOAD_HPA_CONTRACT_REPORT}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    setSelectedPage(0)
    const data = {
      ...valuesSelect,
      pageNumber: 0,
      pageSize: selectedPageSize,
    };
    getTableList(data);
  };

  const onSelect = (field: string, value: string) => {
    handleSelect(field, value);
    setSelectedPage(0)
    const data = {
      ...valuesSelect,
      pageNumber: 0,
      pageSize: selectedPageSize,
      [field]: value,
    };
    getTableList({ ...data });
  };

  const onSelectPage = (pageNumber: number) => {
    setSelectedPage(pageNumber)
    const data = {
      ...valuesSelect,
      pageNumber: pageNumber,
      pageSize: selectedPageSize,
    };
    getTableList({ ...data });
  }

  const onSelectPageSize = (pageSize: number) => {
    setSelectedPageSize(pageSize)
    setSelectedPage(0)
    const data = {
      ...valuesSelect,
      pageNumber: 0,
      pageSize: pageSize,
    };
    getTableList({ ...data });
  }

  const getColumns = () => {
    if (reportType === "quarterly")
      return [...columnsHPAContract, ...columnsHPAQuarterly];

    if (reportType === "effective_latest")
      return [...columnsHPAContract, ...columnsHPAEffectiveLatest];
  };

  return (
    <PageContent
      title="HPA Contract"
      path="Valuation"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fieldsInForm}
            importButton={{
              label: isLoading ? "Processing..." : "Generate",
              onclick: handleClickImportBtn,
              disabled: isLoading || (contracts === "individuals" && !contract),
            }}
            tableMode={tableMode}
          />
          {tableMode && (
            <TableControls
              columnsList={getColumns() || []}
              list={items || []}
              isLoading={isLoading}
              onDownloadFile={onDownloadFile}
              dynamicPinFirstCol
              formInPanel={
                <FormBlock
                  fields={fieldsinPanel}
                  tableMode
                  inControlPanel
                  sizeFields={"85"}
                />
              }
              searchColumns={["contract_id"]}
              withFilters
            />
          )}
          {
            tableMode && (<BasePaginator pageNumber={selectedPage} pageSize={selectedPageSize} total={total || 0} disabled={isLoading}
                                         onSelectPage={onSelectPage} onSelectPageSize={onSelectPageSize}/>)
          }
        </>
      </PageFormLayout>
    </PageContent>
  );
};
